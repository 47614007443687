import React, { useEffect } from "react";
import { useHistory } from "react-router-dom";

const GoBack: React.FC = () => {
  const history = useHistory();

  useEffect(() => history.goBack(), []);

  return <></>;
};

export default GoBack;
