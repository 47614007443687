import { findItemAndIndexById } from "@upandgo/react-quiz";
import React, { useContext } from "react";
import { Route, Redirect, RouteProps } from "react-router-dom";
import GLOBAL_PARAMS from "../content/globalParams";
import { StoreContext } from "../context";
import ROUTES from "../routes";

interface Props extends RouteProps {
  component: React.ComponentType<any>;
}

const BlockedRoute: React.FC<Props> = (props) => {
  const { store } = useContext(StoreContext);
  const { component: Component, /* fallbackPath, validatingFunction */ ...rest } = props;

  const canAccessContent = () => {
    if (GLOBAL_PARAMS.onStartContent) {
      const onStartObjective = findItemAndIndexById(GLOBAL_PARAMS.onStartContent, store.objectiveList);
      if (onStartObjective.item.completionStatus === "not attempted") return GLOBAL_PARAMS.onStartContent;
    }
    return "";
  };
  const boxId = canAccessContent();
  const fallBackPath = `${ROUTES.ACTIVITY_LIST.path}/${boxId}`;
  return (
    <Route
      {...rest}
      render={(props) => {
        if (!boxId || props.location.pathname === fallBackPath) {
          return <Component {...props} />;
        } else return <Redirect to={{ pathname: fallBackPath, state: { from: props.location } }} />;
      }}
    />
  );
};

export default BlockedRoute;
