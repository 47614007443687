import { ACTIVITY_PLAYER_WIDTH, styled } from "../../theme";
import { StandardButton } from "../StandardButton/StandardButton.style";

export const SkipBoxContainer = styled.div`
  width: 100%;
  height: 100%;
  box-sizing: border-box;
  display: flex;
  flex-direction: row;
  align-items: stretch;
  justify-content: center;
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
  position: fixed;
  top: 0;
`;

export const SkipBoxModalContainer = styled.div`
  box-sizing: border-box;
  width: ${ACTIVITY_PLAYER_WIDTH};
  aspect-ratio: 16/9;
  position: relative;
  z-index: 7;
  align-self: center;
  background-color: #fff;
  box-shadow: 10px 10px 28px -4px rgba(0, 0, 0, 0.8);
  overflow: hidden;
  ${({ theme }) => theme.breakpoints.down("sm")} {
    width: 100%;
    align-self: initial;
    overflow-y: scroll;
    height: inherit;
  }
`;
export const SkipModalWrapper = styled.div`
  box-sizing: border-box;
  width: 90%;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: space-evenly;
  align-items: center;
  margin-left: auto;
  margin-right: auto;
`;
export const ButtonRow = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-around;
  width: 100%;
`;

export const SkipButton = styled(StandardButton)`
  border-radius: 3.25em;
`;

export const CloseButtonContainer = styled.div`
  top: 0.1em;
  right: 0.1em;
  position: absolute;
  z-index: 10;
  margin: 1%;
  ${({ theme }) => theme.breakpoints.down("sm")} {
  }
`;
export const SkipModalIcon = styled.span`
  width: 6em;
  aspect-ratio: 1/1;
  background-image: url(${({ theme }) => theme.icons.skipModalIcon ?? "none"});
  background-size: 100%;
  background-repeat: no-repeat;
  background-position: center;
  display: block;
`;

export const SkipModalText = styled.p`
  color: ${({ theme }) => theme.color.mainTitle};
  font-weight: bolder;
  width: 70%;
  font-size: 2em;
  text-align: center;
`;
export const ButtonContainer = styled.div``;
export const TimeSavingText = styled.p`
  text-align: center;
  font-style: italic;
`;
