import React, { useContext, useEffect, useRef } from "react";
import { BaseActivityProps } from "../Activity/Activity.component";
import {
  Container,
  StorylineIframe,
  StorylineIframeContainer,
  StorylineTitleContainer,
} from "./ActivityScormStoryline.style";
import { ActivityScormStoryline } from "../../types";
import { observer } from "mobx-react";
import { StoreContext } from "../../context";
import Title from "../Title/Title.component";
import { useState } from "react";
import { useMediaQuery } from "@material-ui/core";
import { theme } from "../../theme";

interface Props extends BaseActivityProps {
  activity: ActivityScormStoryline;
}

//REMEMBER TO REPLACE SCORM DRIVERS

const ActivityScormStorylineContainer: React.FC<Props> = (props) => {
  const { core, store } = useContext(StoreContext);

  const ref = useRef<HTMLDivElement>(null);
  const refIframe = useRef<HTMLIFrameElement>(null);
  const shouldDisplayMobile = useMediaQuery(theme.breakpoints.down("sm")) && props.activity.mobileUrl !== undefined;
  const isTracked = props.activity.isNeededForCompletion || store.currentBox?.lockedByThisActivity;

  const [titleContainerHeight, setTitleContainerHeight] = useState(0);
  const debugMode = process.env.REACT_APP_DEBUG === "true" ? true : false;

  const handler = (event: {
    data: {
      action: string;
      variable: string;
      value: string;
    };
  }) => {
    debugMode && console.log(event);
    if (
      event.data.action === "set" &&
      event.data.variable === "cmi.completion_status" &&
      event.data.value === "completed"
    ) {
      props.activity.isNeededForCompletion &&
        props.activity.tracking?.completeCondition === "complete" &&
        void core.setInteractionCompletion(props.activity.id);
    }
  };

  useEffect(() => {
    if (titleContainerHeight === 0) {
      setTitleContainerHeight(ref.current ? ref.current.offsetHeight : 0);
    }
  }, [ref]);

  useEffect(() => {
    if (isTracked && props.activity.tracking) {
      if (props.activity.tracking.completeCondition === "open") {
        void core.setInteractionCompletion(props.activity.id);
      }
    }
    const iframe = refIframe.current?.contentWindow;

    if (iframe) {
      iframe.addEventListener("message", handler, false);
      return () => {
        iframe.removeEventListener("message", handler);
      };
    }
  }, []); // empty array => run only once

  return (
    <Container className="activity-storyline-container">
      {props.activity.title && (
        <StorylineTitleContainer ref={ref} className="storyline-title-container">
          <Title level={2} title={props.activity.title} />
        </StorylineTitleContainer>
      )}
      <StorylineIframeContainer titleHeight={titleContainerHeight} className="storyline-iframe-container">
        {shouldDisplayMobile && (
          <StorylineIframe
            className="storyline-iframe-mobile"
            title={props.activity.title}
            allowFullScreen={true}
            src={props.activity.mobileUrl}
            ref={refIframe}
            tabIndex={0}
          />
        )}
        {!shouldDisplayMobile && (
          <StorylineIframe
            className="storyline-iframe-desktop"
            title={props.activity.title}
            allowFullScreen={true}
            src={props.activity.desktopUrl}
            ref={refIframe}
            tabIndex={0}
          />
        )}
      </StorylineIframeContainer>
    </Container>
  );
};

export default observer(ActivityScormStorylineContainer);
