import { Keyword } from "../types";

const universalKeyword = "debug";

const keywordList: Keyword = {
  "box2-0-locker": [
    "janvier",
    "fevrier",
    "mars",
    "avril",
    "mai",
    "juin",
    "juillet",
    "aout",
    "septembre",
    "octobre",
    "novembre",
    "decembre",
  ],
};

export { keywordList, universalKeyword };
