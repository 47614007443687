import { createWrapper } from "@upandgo/scorm-wrapper";

export interface Params {
  applicationId: string;
  scormFilters?: Parameters<typeof createWrapper>[0]["filteredScormValues"];
  localforageName: string;
  analyticsTrackingID?: string;
  matomoTracking?: {
    urlBase: string;
    siteId: number;
  };
  customScoreMax?: number;
  moduleSuccessOfObjectiveList?: {
    successCondition: "all" | "firstOne";
    objectivesSuccessIdList: string[];
  };
  shouldInitializeScore?: boolean;
  shouldStoreInteractionAndObjectiveInSuspendData?: boolean;
  convertProgressMeasureIntoScoreScaled?: boolean;
  onStartContent?: string;
  objectivesCompletionBySkilltester?: boolean;
}

const GLOBAL_PARAMS: Params = {
  applicationId: "2023_11_22_SDH_diversite_cromology",
  localforageName: "@upandgo_scorm-wrapper",
  scormFilters: "STORYLINE_completed_incomplete",
  shouldStoreInteractionAndObjectiveInSuspendData: true,
};

export default GLOBAL_PARAMS;
