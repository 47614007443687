import { Content } from "../types";
import { LANGUAGES } from "../constants";

const asset = (url: string) => process.env.PUBLIC_URL + url;
const TRACKING_CONDITION = "complete";

const content: Content = {
  title: "Politique contre le harcèlement///et la discrimination",
  language: LANGUAGES.FR,
  backgroundMedia: {
    type: "image",
    url: asset("/assets/content/backgrounds/background_sdd.png"),
    mobileUrl: asset("/assets/content/backgrounds/background_sdd_mobile.png"),
  },
  footerLeft: [],
  footerRight: [
    { id: "upandgo", image: { type: "image", url: asset("/assets/branding/logo_upandgo.png"), alt: "upandgo" } },
  ],
  footerBackground: "#fff",
  logo: asset("/assets/branding/Cromology_logo.png"),
  boxList: [
    {
      id: "aya-diversite",
      type: "content",
      title: "Aya",

      desktopDisplayOrder: 1,
      estimatedTime: 0,
      activityList: [
        {
          id: "box1-story",
          background: "#FFFFFF",
          type: "webStoryline",
          isNeededForCompletion: true,
          tracking: {
            completeCondition: TRACKING_CONDITION,
          },
          desktopUrl: asset("/assets/content/SDH_diversite/aya-diversite/Standard_diversite_Aya_diversite/story.html"),
        },
      ],
      boxConnectionList: ["down"],
    },
    {
      id: "amar-handicap",
      type: "content",
      desktopDisplayOrder: 4,
      estimatedTime: 0,
      lockedByCompletionOfBoxList: ["aya-diversite"],
      title: "Amar",
      activityList: [
        {
          id: "box4-story",
          background: "#FFFFFF",
          type: "webStoryline",
          isNeededForCompletion: true,
          tracking: {
            completeCondition: TRACKING_CONDITION,
          },
          desktopUrl: asset("/assets/content/SDH_diversite/amar-handicap/Standard_diversite_Amar_handicap/story.html"),
        },
      ],
      boxConnectionList: ["right"],
    },
    {
      id: "lucie-genre",
      type: "content",
      title: "Lucie",
      desktopDisplayOrder: 5,
      estimatedTime: 0,
      lockedByCompletionOfBoxList: ["amar-handicap"],
      activityList: [
        {
          id: "box5-story",
          background: "#FFFFFF",
          type: "webStoryline",
          isNeededForCompletion: true,
          tracking: {
            completeCondition: TRACKING_CONDITION,
          },
          desktopUrl: asset("/assets/content/SDH_diversite/lucie-genre/Standard_diversite_Lucie_genre/story.html"),
        },
      ],
      boxConnectionList: ["up"],
    },
    {
      id: "olivier-generation",
      type: "content",
      desktopDisplayOrder: 2,
      lockedByCompletionOfBoxList: ["lucie-genre"],
      estimatedTime: 0,
      title: "Olivier",
      activityList: [
        {
          id: "box2-story",
          background: "#FFFFFF",
          type: "webStoryline",
          isNeededForCompletion: true,
          tracking: {
            completeCondition: TRACKING_CONDITION,
          },
          desktopUrl: asset(
            "/assets/content/SDH_diversite/olivier-generation/Standard_diversite_Olivier_generation/story.html",
          ),
        },
      ],
      boxConnectionList: ["right"],
    },
    /*  {
      id: "jade-lgbt",
      type: "content",
      desktopDisplayOrder: 3,
      //lockedByCompletionOfBoxList:["olivier-generation"],
      estimatedTime: 0,
      title: "Jade",
      activityList: [
        {
          id: "box3-story",
          background: "#FFFFFF",
          type: "webStoryline",
          isNeededForCompletion: true,
          tracking: {
            completeCondition: TRACKING_CONDITION,
          },
          desktopUrl: asset("/assets/content/SDH_diversite/jade-lgbt/Standard_diversite_Jade_LGBT/story.html"),
        },
      ],
      boxConnectionList: ["down"],
    }, */
    {
      id: "diego-sexisme",
      type: "content",
      desktopDisplayOrder: 6,
      estimatedTime: 0,
      lockedByCompletionOfBoxList: ["olivier-generation"],
      boxConnectionList: ["down"],
      title: "Diego",
      activityList: [
        {
          id: "box6-story",
          background: "#FFFFFF",
          type: "webStoryline",
          isNeededForCompletion: true,
          tracking: {
            completeCondition: TRACKING_CONDITION,
          },
          desktopUrl: asset("/assets/content/SDH_diversite/diego-sexisme/Standard_diversite_Diego_sexisme/story.html"),
        },
      ],
    },
    {
      id: "camille-heritage",
      type: "content",
      desktopDisplayOrder: 9,
      estimatedTime: 0,
      lockedByCompletionOfBoxList: ["diego-sexisme"],
      boxConnectionList: ["left"],
      title: "Camille",
      activityList: [
        {
          id: "box9-story",
          background: "#FFFFFF",
          type: "webStoryline",
          isNeededForCompletion: true,
          tracking: {
            completeCondition: TRACKING_CONDITION,
          },
          desktopUrl: asset(
            "/assets/content/SDH_diversite/camille-heritage/Standard_diversite_Camille_culture_heritage/story.html",
          ),
        },
      ],
    },
    {
      id: "pauline-harcelement",
      type: "content",
      desktopDisplayOrder: 8,
      estimatedTime: 0,
      lockedByCompletionOfBoxList: ["camille-heritage"],
      title: "Pauline",
      activityList: [
        {
          id: "box8-story",
          background: "#FFFFFF",
          type: "webStoryline",
          isNeededForCompletion: true,
          tracking: {
            completeCondition: TRACKING_CONDITION,
          },
          desktopUrl: asset(
            "/assets/content/SDH_diversite/pauline-harcelement/Standard_diversite_Pauline_harcelement_moral/story.html",
          ),
        },
      ],
    },
    {
      id: "debug",
      type: "content",
      desktopDisplayOrder: 10,
      hiddenFromMenu: true,
      estimatedTime: 0,
      activityList: [],
    },
    {
      id: "box7",
      type: "empty",
      desktopDisplayOrder: 7,
      icon: asset("/assets/content/box-icons/icone-call.svg"),
    },
    {
      id: "box3",
      type: "empty",
      desktopDisplayOrder: 3,
      icon: asset("/assets/content/box-icons/icone-balance.svg"),
      boxConnectionList: ["down"],
    },
  ],
};

export default content;
