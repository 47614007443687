import { styled } from "../../theme";
import { keyframes } from "styled-components";
import { lightenColor } from "../../utils/utils";

export const Container = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  height: 100%;
`;

export const PlaylistVideoTitleContainer = styled.div`
  min-height: 10%;
  ${({ theme }) => theme.breakpoints.down("sm")} {
    min-height: 2em;
  }
`;

export const VideoContainer = styled.div<{ titleHeight?: number }>`
  max-height: calc(100% - ${({ titleHeight }) => titleHeight}px);
  box-sizing: border-box;
  display: flex;
  justify-content: center;
  ${({ theme }) => theme.breakpoints.down("sm")} {
    height: initial;
  }
  video:focus {
    outline: none;
  }
`;

const RotateAnimation = keyframes`
 0% { transform: rotateZ(0);}
100% { transform: rotateZ(100deg);}`;

export const RotateScreenIcon = styled.div`
  height: 10em;
  width: 10em;
  background-image: url(${({ theme }) => theme.icons.rotateScreenIcon});
  background-size: cover;
  transform: rotateZ(100deg);
  animation: ${RotateAnimation} 1s infinite;
  animation-direction: alternate;
`;
export const RotateScreenContainer = styled.div`
  min-height: 90vh;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 100%;
`;
export const RotateScreenMessage = styled.p`
  font-weight: bold;
  font-size: 1.6rem;
  text-align: center;
`;
export const PlaylistNavBar = styled.ul<{ open: boolean }>`
  transform: translate(${({ open }) => (open ? "0% " : "82%")});
  transition: all 0.5s ease-in;
  position: absolute;
  top: 0;
  right: 0;
  height: 100%;
  margin: 0;
  padding: 0;
  display: flex;
  flex-direction: column;
  background-color: rgba(0 0 0 / 75%);
  width: 20%;
  list-style: none;
  box-sizing: border-box;
  z-index: 9;
  padding: 4em 0.5em 4em 1em;
  li {
    opacity: ${({ open }) => (open ? "1" : "0")};
    transition: all 0.5s ease-in;
  }
  ${({ theme }) => theme.breakpoints.down("md")} {
    transform: translate(${({ open }) => (open ? "0% " : "76%")});
  }
`;

export const PlaylistItemContainer = styled.li`
  list-style: none;
  margin-bottom: 2em;
  :hover {
    cursor: pointer;
  }
  &.active {
    button {
      border-left: solid 0.2em ${({ theme }) => theme.color.mainTitle};
      font-weight: bold;
      color: #fff;
    }
  }
`;
export const PlaylistItemButton = styled.button`
  font-size: 1.6rem;
  padding-left: 0.7em;
  display: flex;
  color: #929292;
  vertical-align: top;
  min-height: 3em;
  text-align: left;
  vertical-align: top;
  background-color: unset;
  border: none;
  border-left: solid 0.2em #929292;
  width: 100%;
  :hover {
    font-weight: bold;
    color: #fff;
    cursor: pointer;
    text-decoration: underline;
    border-left: solid 0.2em ${({ theme }) => theme.color.mainTitle};
  }
  :focus {
    border-left: solid 0.2em ${({ theme }) => theme.color.mainTitle};
    outline: none;
  }
`;
export const NavbarCloseButton = styled.button`
  position: absolute;
  bottom: 0;
  left: 1em;
  border-style: solid;
  height: 3em;
  width: 3em;
  border: none;
  background-color: unset;
  padding: 0;
  :hover {
    cursor: pointer;
  }
  :focus {
    border: none;
    outline: none;
  }
`;
export const NavbarCloseIconContainer = styled.span<{ reverseDirection: boolean }>`
  transition: all 0.5s ease-in;
  transform: ${(props) => (props.reverseDirection ? "scaleX(1)" : "scaleX(-1)")};
  display: block;
  width: 2em;
  height: 2em;
  background-image: url(${({ theme }) => theme.icons.navigationArrow});
  background-repeat: no-repeat;
  background-size: contain;
  background-position: center;
`;
export const SideBarStyle = styled.div`
  width: 100%;
  height: 100%;
  .simplebar-content {
    width: 100%;
    height: 100%;
  }
  .simplebar-track {
    right: 0.2em;
  }
  .simplebar-scrollbar.simplebar-visible:before {
    /* When hovered, remove all transitions from drag handle */
    opacity: 1;
    transition: opacity 0s linear;
  }

  .simplebar-scroll-content {
  }
  .simplebar-track.simplebar-vertical {
    width: 0.5em;
    background-color: #929292;
    border-radius: 1em;
  }
  .simplebar-scrollbar::before {
    background-color: ${({ theme }) => theme.color.scrollbar};
  }
  .track {
  }
  ${({ theme }) => theme.breakpoints.down("sm")} {
    width: 90%;
  }
`;
