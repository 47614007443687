import { styled } from "../../theme";

export const Container = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
`;
export const ResourceListTitleContainer = styled.div`
  margin-top: 1em;
  margin-bottom: 3em;
  text-transform: uppercase;

  ${({ theme }) => theme.breakpoints.down("sm")} {
    padding: 0.5em;
    margin-top: 1em;
  }
`;

export const ResourceListContainer = styled.div`
  width: 50%;
  font-size: 1.8rem;
  ${({ theme }) => theme.breakpoints.down("sm")} {
    width: 90%;
}
`;

export const ItemContainer = styled.li`
  width: 90%;
  box-sizing: border-box;
  display: flex;
  justify-content: center;
  ${({ theme }) => theme.breakpoints.down("sm")} {
    min-height: 3.8em;
    width: 90%;
  }
`;
export const SideBarStyle = styled.div`
  width: 65%;
  justify-content: center;
  .simplebar-content {
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
  }
  .simplebar-track {
    right: 1em;
  }
  .simplebar-scrollbar.simplebar-visible:before {
    /* When hovered, remove all transitions from drag handle */
    opacity: 1;
    transition: opacity 0s linear;
  }
  .simplebar-scroll-content {
  }
  .simplebar-scrollbar::before {
    background-color: ${({ theme }) => theme.color.scrollbar};
  }
  .track {
  }
  ${({ theme }) => theme.breakpoints.down("sm")} {
    width: 90%;
  }
`;

export const ResourceListIconContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 7em;
  height: 7em;
  margin-top: 2em;
  box-sizing: border-box;
  ${({ theme }) => theme.breakpoints.down("md")} {
    width: 4em;
    height: 4em;
  }
  ${({ theme }) => theme.breakpoints.down("sm")} {
    display: none;
  }
`;
export const ResourceIcon = styled.span<{ bg: string }>`
  width: 100%;
  background-image: ${({ bg }) => `url("${bg}")`};
  background-size: contain;
  background-repeat: no-repeat;
  background-position: center;
  display: block;
  height: 5em;
  ${({ theme }) => theme.breakpoints.down("md")} {
    height: 3em;
  }
`;
