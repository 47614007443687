import { styled } from "../../theme";
import { keyframes } from "styled-components";

export const Container = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  height: 100%;
`;

export const VideoTitleContainer = styled.div`
  min-height: 10%;
  ${({ theme }) => theme.breakpoints.down("sm")} {
    min-height: 2em;
    margin: 10%;
    > .title_level_2 {
      text-align: center;
    }
  }
`;

export const VideoContainer = styled.div<{ titleHeight?: number }>`
  max-height: calc(100% - ${({ titleHeight }) => titleHeight}px);
  box-sizing: border-box;
  display: flex;
  justify-content: center;
  height: 100%;
  width: 100%;
  ${({ theme }) => theme.breakpoints.down("sm")} {
    height: initial;
    width: 90%;
  }
  video:focus {
    outline: none;
  }
`;

const RotateAnimation = keyframes`
 0% { transform: rotateZ(0);}
100% { transform: rotateZ(100deg);}`;

export const RotateScreenIcon = styled.div`
  height: 10em;
  width: 10em;
  background-image: url(${({ theme }) => theme.icons.rotateScreenIcon});
  background-size: cover;
  transform: rotateZ(100deg);
  animation: ${RotateAnimation} 1s infinite;
  animation-direction: alternate;
`;
export const RotateScreenContainer = styled.div`
  min-height: 90vh;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 100%;
`;
export const RotateScreenMessage = styled.p`
  font-weight: bold;
  font-size: 2.7rem;
  text-align: center;
`;
