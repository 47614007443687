import { styled } from "../../theme";
import { MediaImage } from "../../types";

export const FooterStyle = styled.footer<{ bg?: MediaImage | string; hidden?: boolean }>`
  width: 100%;
  display: ${({ hidden }) => (hidden ? "none" : "flex")};
  height: calc(100vh * 0.1);
  background: ${({ bg }) => (bg ? (typeof bg === "string" ? bg : `url("${bg.url}")`) : "none")};
  background-size: cover;
  background-position: center;
  box-sizing: border-box;
  justify-content: center;
  align-items: center;
  position: relative;
  ${({ theme }) => theme.breakpoints.down("sm")} {
    min-height: 7vh;
    height: initial;
  }
`;
export const FooterWrapper = styled.div`
  width: calc(100vw * 0.82);
  box-sizing: border-box;
  display: flex;
  gap: 1em;
  align-items: center;
  justify-content: space-between;
  height: 100%;
  ${({ theme }) => theme.breakpoints.down("sm")} {
    flex-direction: column;
    width: calc(100vw * 0.9);
  }
`;

export const FooterContainerStyle = styled.ul`
  box-sizing: border-box;
  padding-inline-start: 0;
  flex: 1 0 50%;
  box-sizing: border-box;
  display: flex;
  justify-content: space-between;
  flex-direction: row;
  list-style-type: none;
  margin: 0;
  align-items: center;
  height: 100%;
  ${({ theme }) => theme.breakpoints.down("sm")} {
    width: 100%;
    height: 100%;
  }
`;

export const FooterLeftContainerStyle = styled(FooterContainerStyle)`
  justify-content: start;
  gap: 2em;

  ${({ theme }) => theme.breakpoints.down("sm")} {
    flex-direction: column;
    gap: 1em;
  }
`;
export const FooterRightContainerStyle = styled(FooterContainerStyle)`
  flex-direction: row-reverse;

  ${({ theme }) => theme.breakpoints.down("sm")} {
    flex-direction: column-reverse;
    gap: 1em;
  }
`;

export const FooterLeftItemStyle = styled.li`
  font-weight: bold;
`;

export const FooterRightItemStyle = styled.li`
  color: white;
  max-height: calc(${({ theme }) => theme.footer.height} - 1em);
  box-sizing: border-box;
  img {
    max-height: calc(${({ theme }) => theme.footer.height} - 1em);
  }
  ${({ theme }) => theme.breakpoints.down("sm")} {
    padding-bottom: 1em;
    padding-right: 0;
  }
`;

export const FooterLeftItemModalStyle = styled.button`
  text-decoration: none;
  text-align: center;
  display: block;
  background: none;
  border: none;
  font: inherit;
  cursor: pointer;
  color: ${({ theme }) => theme.color.text};
  &:focus:not(:focus-visible) {
    border: none;
    outline: none;
    box-shadow: inherit;
  }
  &:focus-visible {
    border: none;
    outline: none;
    box-shadow: 0px 0px 0px 0.5em ${({ theme }) => theme.color.focus};
  }
  &:hover {
    text-decoration: underline;
    box-sizing: border-box;
  }
`;

export const FooterLeftItemLinkStyle = styled.a`
  text-decoration: none;
  text-align: center;
  display: block;
  cursor: pointer;
  color: ${({ theme }) => theme.color.text};
  &:hover {
    text-decoration: underline;
    box-sizing: border-box;
  }
  &:focus:not(:focus-visible) {
    border: none;
    outline: none;
    box-shadow: inherit;
  }
  &:focus-visible {
    border: none;
    outline: none;
    box-shadow: 0px 0px 0px 0.5em ${({ theme }) => theme.color.focus};
  }
`;

export const FooterRightItemLinkStyle = styled.a`
  text-decoration: none;
  display: block;
  cursor: pointer;
  &:visited {
    color: white;
  }
  &:hover {
    font-weight: bold;
    box-sizing: border-box;
  }
  &:focus:not(:focus-visible) {
    border: none;
    outline: none;
    box-shadow: inherit;
  }
  &:focus-visible {
    border: none;
    outline: none;
    box-shadow: 0px 0px 0px 0.5em ${({ theme }) => theme.color.focus};
  }
`;

export const ContainerInModal = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  position: relative;
`;

export const ModalCloseButtonContainer = styled.div`
  top: 0.1em;
  right: 0.1em;
  z-index: 10;
  position: absolute;
  ${({ theme }) => theme.breakpoints.down("sm")} {
  }
`;
