import { styled } from "../../theme";

export const SubMenuStyle = styled.div`
  width: 100%;
`;

export const SubMenuWrapper = styled.div``;

export const SubMenuSectionStyle = styled.div``;

export const SubMenuContainerStyle = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  gap: 1em;
  box-sizing: border-box;
  background: ${({ theme }) => theme.color.submenu.background};
  background-position: center;
  background-size: cover;
  position: relative;
  padding: 1em;
  box-shadow: 10px 10px 28px -4px rgba(0, 0, 0, 0.8);
  ${({ theme }) => theme.breakpoints.down("sm")} {
    border-radius: 20px;
    width: 100%;
    justify-content: flex-start;
    /* overflow: scroll; */
  }
`;
export const SubMenuTitleContainerStyle = styled.div`
  text-align: center;
  width: 95%;
  margin-left: auto;
  margin-right: auto;
  box-sizing: border-box;
  h2 {
    color: ${({ theme }) => theme.color.submenu.titleColor};
    font-size: 3.6rem;
    ${({ theme }) => theme.breakpoints.down("sm")} {
      font-size: 3.6rem;
  }
  }

  ${({ theme }) => theme.breakpoints.down("sm")} {
    margin-top: unset;
    padding-top: 1.5em;
  }
`;
export const SubMenuObjectiveContainerStyle = styled.div`
  box-sizing: border-box;
  width: 95%;
  margin-left: auto;
  margin-right: auto;
  ${({ theme }) => theme.breakpoints.down("sm")} {
    margin-bottom: unset;
    padding-top: 1em;
    padding-bottom: 1em;
  }
`;
export const SubMenuObjectiveTextStyle = styled.p`
  margin-top: 0;
  margin-bottom: 0;
  box-sizing: border-box;
  width: 100%;
  text-align: center;
  font-size: 1.8rem;
  color: ${({ theme }) => theme.color.text};
  ${({ theme }) => theme.breakpoints.down("md")} {
    font-size: 1.6rem;
  }
`;
export const SubMenuBoxItemContainerStyle = styled.nav`
  box-sizing: border-box;
  width: 100%;
  box-sizing: border-box;
  ${({ theme }) => theme.breakpoints.down("sm")} {
    display: flex;
    justify-content: center;
  }
`;
export const CloseButtonContainer = styled.div`
  top: 0.1em;
  right: 0.1em;
  position: absolute;
  z-index: 10;
  margin: 1%;
`;

export const CtaContainer = styled.div`
  width: 90%;
  margin: 0 auto;
`;
