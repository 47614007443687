import { styled, SKILLTESTER_MAIN_COLOR, SKILLTESTER_SECONDARY_COLOR } from "../../theme";

const BG_COLOR = SKILLTESTER_MAIN_COLOR;
const SECONDARY_COLOR = SKILLTESTER_SECONDARY_COLOR;

export const Container = styled.div`
  user-select: none;
  width: 100%;
  display: flex;
  gap: 1em;
  flex-direction: column;
  align-items: center;
  box-sizing: border-box;

  .answer-checkbox {
    padding: 1.1em 0.6em;
    font-family: ${({ theme }) => theme.font.mainFont.name};
    font-weight: normal;
    font-size: 2rem;
    justify-content: center !important;
    text-align: center !important;
    &:focus:not(:focus-visible) {
      border: none;
      outline: none;
      box-shadow: inherit;
    }
    &:focus-visible {
      border: none;
      outline: none;
      box-shadow: 0px 0px 0px 0.5em ${({ theme }) => theme.color.focus};
    }
  }

  .button-start-quiz {
    background-color: ${({ theme }) => theme.color.button};
    border: none;
    border-radius: 2em;
    margin: 1em;
    text-transform: uppercase;
    font-family: ${({ theme }) => theme.font.styledFont.name};
  }

  .question-column {
    max-width: 100% !important;
  }
  .question-column.context {
    display: block;
  }

  .question-column.question {
    width: 100%;
  }
  .question-context-description {
    display: none;
  }
  .question-context-title {
    text-align: center;
    margin-bottom: 0;
    margin-top: 2em;
  }

  .question-title {
    font-size: 2.5rem;
    margin-top: 0;
    margin-bottom: 0.5em;
    font-family: ${({ theme }) => theme.font.styledFont.name};
    color: ${({ theme }) => theme.color.mainTitle};
  }
  .skilltester-container {
    height: 100%;
    width: 65%;
  }

  .question-content {
    flex-direction: column;
    align-content: center;
  }
  .question-internal-container {
    height: 100%;
  }
  .question-instructions {
    margin-top: 0 !important;
    font-size: 1.6rem;
    font-family: ${({ theme }) => theme.font.mainFont.name};
    color: ${({ theme }) => theme.color.text};
  }

  .question-answers-container {
    flex-direction: column;
    justify-content: flex-start;
  }

  .question-button-validate {
    font-family: ${({ theme }) => theme.font.styledFont.name};
    background-color: ${({ theme }) => theme.color.mainTitle};
    border-radius: 25px;
    height: 2em;
    text-transform: uppercase;
  }
  .answer-container {
    width: 75%;
  }

  ${({ theme }) => theme.breakpoints.down("md")} {
    .question-title {
      margin-top: 0;
      font-family: ${({ theme }) => theme.font.styledFont.name};
      color: ${({ theme }) => theme.color.mainTitle};
    }
    .answer-checkbox {
      justify-content: center;
      padding: 0.8em 0.6em;
    }
  }
  ${({ theme }) => theme.breakpoints.down("sm")} {
    .skilltester-intro-main-container {
      height: 100%;
      width: 100%;
    }

    .button-start-quiz {
      font-size: 1.6rem;
    }
    .skilltester-container {
      height: 100%;
      width: 100%;
    }
    .question-context-title {
      margin-top: 1.8em;
      font-size: 1.8rem;
    }
    .question-title {
      font-size: 2rem;
    }
    .question-internal-container {
      overflow: scroll;
      max-height: 38em;
    }
    .question-instructions {
      font-size: 1.6rem;
    }
    .question-answers-container {
      flex-direction: column;
      justify-content: flex-start;
    }
    .question-button-validate {
      font-size: 1.6rem !important;
    }
    .answer-container {
      width: 95%;
      margin: 0.5em 0;
    }
    .answer-checkbox {
      font-size: 1.6rem;
      justify-content: flex-start;
      padding: 0.8em 0.6em;
    }
  }
`;

export const SkillTesterTitleContainer = styled.div``;

export const SkillTesterObjectiveBlock = styled.div`
  width: 100%;
  min-height: 45%;
  padding: 1em 0;
  box-sizing: border-box;
  color: #fff;
  background-color: ${BG_COLOR};
  display: flex;
  justify-content: center;
  font-size: 1.2em;
  ${({ theme }) => theme.breakpoints.down("md")} {
    padding: 1.3em 0;
    font-size: 1em;
  }
`;
export const ObjectiveWrapper = styled.div`
  width: 90%;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: space-around;
  ${({ theme }) => theme.breakpoints.down("md")} {
    width: 80%;
  }
  ${({ theme }) => theme.breakpoints.down("sm")} {
    width: 90%;
  }
`;

export const QuizIntroTitleContainer = styled.div`
  color: ${({ theme }) => theme.color.header};
  margin-bottom: 1.2em;
  ${({ theme }) => theme.breakpoints.down("sm")} {
    margin-top: 2em;
  }
  ${({ theme }) => theme.breakpoints.down("md")} {
    h1 {
      font-size: 2.5rem;
    }
  }
`;
export const ObjectiveContainer = styled.div`
  width: 60%;
  margin-left: auto;
  margin-right: auto;
`;
export const ObjectiveText = styled.p`
  margin-top: 0.5em;
  margin-bottom: 0.5em;
  font-weight: bold;
`;
export const ObjectiveList = styled.ul`
  padding: 0;
  margin-top: 0;
  margin-bottom: 0.5em;
`;
export const ObjectiveItemContainer = styled.li`
  align-content: center;
  list-style: inside;
  margin-top: 0.5em;
  margin-bottom: 0.5em;
  font-weight: bold;
`;
export const ObjectiveBulletPoint = styled.span`
  width: 1em;
  height: 1em;
  background-image: url(${({ theme }) => theme.icons.skillTesterBulletPoint});
  display: block;
  background-position: center center;
  align-self: center;
  margin-right: 1em;
  background-repeat: no-repeat;
`;
export const ObjectiveItem = styled.p`
  max-width: 95%;
  margin-top: 0.5em;
  margin-bottom: 0.5em;
  font-weight: bold;
  ${({ theme }) => theme.breakpoints.down("sm")} {
    max-width: 80%;
  }
`;

export const SkillTesterContextBlock = styled.div`
  width: 100%;
  padding: 0;
  box-sizing: border-box;
  color: ${({ theme }) => theme.color.text};
  display: flex;
  justify-content: center;
`;

export const ContextWrapper = styled.div`
  width: 65%;
  ${({ theme }) => theme.breakpoints.down("md")} {
    width: 80%;
  }
  ${({ theme }) => theme.breakpoints.down("sm")} {
    width: 90%;
  }
`;
export const ContextIconContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-right: auto;
  margin-left: auto;
  width: 5em;
  height: 5em;
  box-sizing: border-box;
  margin-bottom: 2em;
  ${({ theme }) => theme.breakpoints.down("sm")} {
    display: none;
  }
  ${({ theme }) => theme.breakpoints.down("md")} {
    width: 4em;
    height: 4em;
    margin-bottom: 1em;
  }
`;
export const ContextIcon = styled.span<{ bg: string }>`
  width: 100%;
  height: 100%;
  background-image: ${({ bg }) => `url("${bg}")`};
  background-size: 100%;
  background-repeat: no-repeat;
  background-position: center;
  display: block;
`;
export const ContextDescription = styled.p`
  text-align: center;
  font-size: 1.2em;
  ${({ theme }) => theme.breakpoints.down("md")} {
    font-size: 1em;
  }
`;

export const SkillTesterButtonBlock = styled.div`
  width: 100%;
  padding: 0;
  box-sizing: border-box;
  color: ${({ theme }) => theme.color.text};
  display: flex;
  justify-content: center;
`;
export const ButtonListWrapper = styled.div`
  width: 65%;
  display: flex;
  justify-content: center;
  ${({ theme }) => theme.breakpoints.down("md")} {
    width: 80%;
  }
  ${({ theme }) => theme.breakpoints.down("sm")} {
    flex-direction: column;
    width: 90%;
    justify-content: flex-start;
  }
`;

export const ButtonBlock = styled.div`
  width: 50%;
  button {
    border-radius: 2em;
    padding: 0.5em 1em;
    display: block;
    border: none;
    margin-left: auto;
    margin-right: auto;
    ${({ theme }) => theme.breakpoints.down("sm")} {
      margin-left: 0;
    }
  }
  .button-complete {
    background-color: ${SECONDARY_COLOR};
  }
  ${({ theme }) => theme.breakpoints.down("sm")} {
    display: flex;
    flex-direction: column;
    width: 100%;
    margin-bottom: 1em;

    button {
      margin: auto;
    }
  }
`;
export const DurationBlock = styled.p`
  text-align: center;
  font-style: italic;
  margin-top: 0.5em;
  margin-bottom: 0;
`;

export const QuizIntroRulesText = styled.p`
  font-family: ${({ theme }) => theme.font.mainFont.name};
  color: ${({ theme }) => theme.color.text};
  text-transform: none !important;
  font-weight: normal;
  font-size: 3.45rem;
  margin: 0;
  text-align: center;
  line-height: 1.2;
  font-stretch: condensed;
  ${({ theme }) => theme.breakpoints.down("sm")} {
    font-size: 2.45rem;
  }
`;
export const QuizIntroObjectiveText = styled.p`
  font-family: ${({ theme }) => theme.font.mainFont.name};
  color: ${({ theme }) => theme.color.lightText};
  text-transform: none !important;
  font-weight: normal;
  font-size: 2.5rem;
  margin-top: 1em;
  text-align: center;
  line-height: 1.2;
  font-stretch: condensed;
  ${({ theme }) => theme.breakpoints.down("sm")} {
    font-size: 2rem;
  }
`;
