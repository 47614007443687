import { LANGUAGES } from "../constants";
import { InterfaceText } from "../types";

const interfaceText: InterfaceText = {
  language: LANGUAGES.FR,
  durationUnit: "min",
  home: "Accueil",
  previous: "Précédent",
  next: "Suivant",
  close: "Fermer",
  submit: "Valider",
  score: "Votre score :",
  downloadCertificate: "Téléchargez votre certificat",
  inputLastName: "Nom",
  inputFirstName: "Prénom",
  backToHome: "Retour à l'accueil",
  tryAgain: "Refaire le quiz",
  validate: "Valider",
  rotateScreen: "Veuillez retournez votre périphérique",
  thresoldScore: "Score validant : 80",
  seeDetail: "Voir vos réponses",
  getCertificate: "Obtenez votre certificat",
  singleResourceDownload: "Vous êtes sur le point de télécharger un truc",
  countDownText: "Début du téléchargement dans",
  singleResourceDownloadAlternative: "Si le téléchargement n'a pas débuter, utiliser ce lien :",
  skillTester: {
    contextDescription:
      "Ce parcours est proposé en « adaptive learning ». Si vous disposez d’un bon niveau de connaissances, vous pouvez optimiser la durée de votre formation grâce au test de positionnement.",
    adaptiveButton: "Parcours « Adaptive »",
    completeCourseButton: "Parcours complet",
  },
  knowledgeMapping: {
    skillTesterStatus: "Votre test de positionnement est désormais terminé !",
    keypointDetails: "",
    skippedTimeTitle: (skippedMinutes: number) =>
      `Grâce à vos résultats, votre gain de temps s’établit à ${skippedMinutes} minute${
        skippedMinutes > 1 ? "s" : ""
      }. La durée de votre parcours sera de :`,
    noSkippedTimeTitle: "Vos résultats  ne vous permettent pas de réduire la durée de votre parcours.",
    start: "Commencer le module",
    resume: "Voir le module",
    legend: {
      partial: "En cours d'acquisition",
      correct: "Acquis",
      incorrect: "Non acquis",
    },
  },
  accessibility: {
    accessibilityButton: "Mode accessible",
    accessibilityDescrition: "",
    boxItemStatus: {
      completed: "Atelier complété",
      locked: "Atelier bloqué",
      passed: "Atelier validé",
      failed: "Atelier échoué",
      activity_locked: "",
      untracked: "",
      pending: "",
    },
    offscreenSkipNav: {
      activateAccessibleMode: "Activer le mode accessible",
      toTheMainContent: "Vers le contenu principale",
      toTheFooterLinks: "Vers les liens en pied de page",
    },
  },
  cookieCompliance: {
    title: "Notre site utilise des cookies de performances",
    text: "Ces cookies nous permettent de déterminer le nombre de visites et les sources du trafic, afin de mesurer et d’améliorer les performances de notre site Web. Ils nous aident également à identifier les pages les plus / moins visitées et d’évaluer comment les visiteurs naviguent sur le site Web. Toutes les informations collectées par ces cookies sont agrégées et donc anonymisées. Si vous n'acceptez pas ces cookies, nous ne serons pas informés de votre visite sur notre site.",
    acceptButton: "j'accepte",
    declineButton: "je refuse",
  },
};

export default interfaceText;
