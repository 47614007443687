import { ACTIVITY_PLAYER_WIDTH, styled } from "../../theme";
import { MediaImage } from "../../types";

interface ActivityListContainerProps {
  bg?: MediaImage | string;
}

export const ActivityListContainer = styled.div<ActivityListContainerProps>`
  display: flex;
  margin: 2em 0;

  ${({ theme }) => theme.breakpoints.down("xs")} {
    position: fixed;
    bottom: 0;
    margin: 0;
    height: 95svh;
    overflow-y: auto;
  }
`;

export const ActivityContainer = styled.div`
  box-sizing: border-box;
  width: ${ACTIVITY_PLAYER_WIDTH};
  aspect-ratio: 16/9;
  background-color: #f4f4f455; // TO BE REMOVED
  position: relative;

  ${({ theme }) => theme.breakpoints.down("sm")} {
    border-radius: 20px 20px 0 0;
    width: 100vw;
    aspect-ratio: unset;
  }
`;

export const CloseButtonContainer = styled.div`
  position: absolute;
  right: 10px;
  top: 10px;
  z-index: 10;
`;

export const ControlsColumn = styled.div`
  flex-grow: 1;
  display: flex;
  justify-content: center;
  align-items: center;

  ${({ theme }) => theme.breakpoints.down("sm")} {
    display: none;
  }
`;
