import { Theme, accessTheme } from "./theme";
import { createGlobalStyle } from "styled-components";
import { lighten } from "@material-ui/core";

export const IndexStyle = createGlobalStyle<{ theme: Theme }>`

@font-face {
  font-family: ${({ theme }) => theme.font.styledFont.name};
  src: url( ${({ theme }) => theme.font.styledFont.url});
  font-weight: normal;
  font-style: normal;
}
@font-face {
  font-family: ${({ theme }) => theme.font.mainFont.name};
  src: url( ${({ theme }) => theme.font.mainFont.url});
  font-weight: normal;
  font-style: normal;
}

html {
  font-size: 62.5%;
}

body {
  font-size: 1.6rem;
  margin: 0;
  font-family: ${({ theme }) => [theme.font.mainFont.name, "MainFont", "Roboto"].join(", ")} ;
  font-weight: normal;
  font-style: normal;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}
.footer-modal {
  font-family: ${({ theme }) => theme.font.mainFont.name};
}
.footer-modal h2{
  color: ${({ theme }) => theme.color.mainTitle};
  font-size: 4rem;
  margin-bottom: 0;
}
.footer-modal p{
  font-size: 2rem;
  text-align: center;
}
.footer-modal a{
  font-size: 2rem;
  color: ${({ theme }) => theme.color.text};
}
.cookie-compliance-button-container{
  align-self: center;
}
.item-debug{
  display:none;
}

`;
