import { styled } from "../../theme";
import { lightenColor } from "../../utils/utils";

interface StandardButtonProps {
  color?: string;
  textColor?: string;
  size?: "small" | "large";
}
export const StandardButton = styled.button<StandardButtonProps>`
  border: solid 1px;
  border-color: ${(props) => props.color || props.theme.color.button};
  background-color: ${(props) => props.color || props.theme.color.button};
  color: ${({ textColor }) => textColor || "#ffffff"};
  padding: 0.5em 1em 0.5em 1em;
  border-radius: 0.25em;
  font-weight: bold;
  font-size: 2rem;
  transition: all 0.2s;
  cursor: pointer;
  text-decoration: none;
  box-sizing: border-box;
  :hover {
    transform: scale(1.05);
    border-color: ${(props) => lightenColor(props.color || props.theme.color.button, 5)};
    background-color: ${(props) => lightenColor(props.color || props.theme.color.button, 5)};
    box-shadow: 0px 0px 7px 3px rgba(0, 0, 0, 0.2);
  }
  :focus:not(:focus-visible) {
    border: none;
    outline: none;
    box-shadow: inherit;
  }
  :focus-visible {
    border: none;
    outline: none;
    box-shadow: 0px 0px 0px 0.5em ${({ theme }) => theme.color.focus};
  }
`;
