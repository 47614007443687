import React, { useContext } from "react";
import { MediaImage } from "../../types";
import { StoreContext } from "../../context";
import {
  HeaderContainer,
  HeaderWrapper,
  HeaderLogoContainerStyle,
  HeaderTitleContainerStyle,
  HeaderLanguageSelectorContainer,
  HeaderRightContainer,
  HeaderLeftContainer,
  HeaderCenterContainer,
  HeaderBackground,
} from "./Header.style";
import Title from "../Title/Title.component";
import LanguageSelector from "../LanguageSelector/LanguageSelector";
import { SUPPORTED_LANGUAGE } from "../../constants";
import AccessibilityButton from "../AccessibilityButton/AccessibilityButton";
import { Debug } from "..";
import { isMobile } from "../../utils/utils";

interface Props {
  logo: MediaImage;
  title: string;
  hidden?: boolean;
  background: string | MediaImage;
}

const Header: React.FC<Props> = (props) => {
  const { store } = useContext(StoreContext);
  const getColor = () => {
    if (typeof props.background === "string") {
      return props.background;
    } else {
      if (props.background.alt) {
        return props.background.alt;
      } else {
        return "#FFFFFF";
      }
    }
  };
  return (
    <HeaderContainer role="banner" className="header-container" hidden={props.hidden}>
      <HeaderWrapper className="header-wrapper">
        <HeaderLeftContainer bgUrl={props.logo.url} className="header-left-container">
          <HeaderLogoContainerStyle
            title={store.interfaceText.home}
            className="header-logo-container"
            href="#"
            // style={{ background: `url(${props.logo.url})` }}
          >
            {/* <img src={props.logo.url} alt={props.logo.alt} /> */}
          </HeaderLogoContainerStyle>
        </HeaderLeftContainer>
        <HeaderCenterContainer className="header-center-container">
          {isMobile() && <Debug />}
          <HeaderBackground className={"background-mobile"} bg={props.background}></HeaderBackground>
        </HeaderCenterContainer>
        <HeaderRightContainer className="header-right-container" bg={getColor()}>
          <HeaderLanguageSelectorContainer className="mobile header-language-selector-container">
            {SUPPORTED_LANGUAGE.length > 1 && <LanguageSelector languageList={SUPPORTED_LANGUAGE} />}
          </HeaderLanguageSelectorContainer>
          <HeaderTitleContainerStyle className="header-title-container">
            <Title level={1} title={props.title} />
          </HeaderTitleContainerStyle>
        </HeaderRightContainer>
      </HeaderWrapper>
      <HeaderLanguageSelectorContainer className="header-language-selector-container">
        {SUPPORTED_LANGUAGE.length > 1 && <LanguageSelector languageList={SUPPORTED_LANGUAGE} />}
        {/*  <AccessibilityButton /> */}
      </HeaderLanguageSelectorContainer>
    </HeaderContainer>
  );
};

export default Header;
