import baseStyled, { ThemedStyledInterface, keyframes } from "styled-components";
import { colors, createTheme, Theme as MuiTheme } from "@material-ui/core";

import Verdana from "./fonts/verdana.ttf";
import VerdanaBold from "./fonts/verdanab.ttf";

const muiTheme = createTheme();

const headerHeight = 17;
const footerHeight = 10;
const squareHeight = 100 - footerHeight - headerHeight;
export const TARGETED_HEADER_HEIGHT = `${headerHeight}vh`;
export const TARGETED_FOOTER_HEIGHT = `${footerHeight}vh`;
export const SQUARE_WIDTH = `clamp(500px, ${squareHeight}vh, 100vw)`;
export const ACTIVITY_PLAYER_WIDTH = "clamp(60vw, calc(85vh * (16 / 9)), 90vw)";

export const SKILLTESTER_MAIN_COLOR = "#e5ff00";
export const SKILLTESTER_SECONDARY_COLOR = "#8ab8b9";

export type Theme = {
  font: {
    mainFont: { name: string; url: string };
    styledFont: { name: string; url: string };
  };
  color: {
    homeBackground: string;
    boxItem: string;
    boxItemTitle: string;
    mainTitle: string;
    submenu: {
      background: string;
      titleColor: string;
    };
    focus: string;
    button: string;
    enhance: string;
    header: string;
    text: string;
    lightText: string;
    quizValid: string;
    quizInvalid: string;
    bagdeActivityLocked: string;
    badgeComplete: string;
    bagdeLocked: string;
    badgePassed: string;
    badgeFailed: string;
    boxConnector: string;
    scrollbar: string;
    overlayColor: string;
    keypointFailed: string;
    keypointPassed: string;
    keypointPartial: string;
    boxObject: {
      [box: string]: {
        background: string;
        titleColor: string;
      };
    };
  };
  breakpoints: MuiTheme["breakpoints"];
  icons: {
    navigationArrow: string;
    closeCross: string;
    boxCompleted: string;
    boxActivityLocked: string;
    boxLocked: string;
    boxPassed: string;
    boxFailed: string;
    resourcePdfFile: string;
    resourceHyperLink: string;
    resourceDefaultIcon: string;
    rotateScreenIcon: string;
    skillTesterBulletPoint: string;
    knowledgeMappingButtonStart: string;
    knowledgeMappingButtonBack: string;
    skipModalIcon?: string;
  };
  footer: {
    height: string;
    mobileHeight: string;
  };
};

export const theme: Theme = {
  font: {
    mainFont: { name: "Verdana", url: Verdana as string },
    styledFont: { name: "Verdana-Bold", url: VerdanaBold as string },
  },
  color: {
    boxItem: "#d74d4d",
    boxItemTitle: "#fff",
    homeBackground: "#d7f5f5",
    focus: "#e5ff00",
    mainTitle: "#343635",
    submenu: {
      background: "green",
      titleColor: "#fff",
    },
    button: "#016db6",
    enhance: "#0D9353",
    header: "#FFFFFF",
    text: "#343635",
    lightText: "#E3E3E3",
    quizValid: "#0D9353",
    quizInvalid: "#DD3E3E",
    bagdeActivityLocked: "#ddc53e",
    badgeComplete: "	#4cc748",
    bagdeLocked: "#343635",
    badgePassed: "#fba910",
    badgeFailed: "#2b0600",
    boxConnector: "#016db6",
    scrollbar: "#b51f04",
    overlayColor: "rgba(59, 59, 59,0.6)",
    keypointFailed: "#DD3E3E",
    keypointPassed: "#0D9353",
    keypointPartial: "#fba910",
    boxObject: {
      "aya-diversite": {
        background: "#f07d22",
        titleColor: "#ffffff",
      },
      "amar-handicap": {
        background: "#da71ac",
        titleColor: "#ffffff",
      },
      "lucie-genre": {
        background: "#4d99cb",
        titleColor: "#ffffff",
      },
      "olivier-generation": {
        background: "#ef324e",
        titleColor: "#ffffff",
      },
      "jade-lgbt": {
        background: "#47bba8",
        titleColor: "#ffffff",
      },
      "diego-sexisme": {
        background: "#df83b6",
        titleColor: "#ffffff",
      },
      "camille-heritage": {
        background: "#f5a463",
        titleColor: "#ffffff",
      },
      "pauline-harcelement": {
        background: "#5c4d9e",
        titleColor: "#ffffff",
      },
      box7: {
        background: "#c4c7cc",
        titleColor: "#fff",
      },
      box3: {
        background: "#c4c7cc",
        titleColor: "#fff",
      },
    },
  },
  breakpoints: muiTheme.breakpoints,
  icons: {
    navigationArrow: `${process.env.PUBLIC_URL}/assets/icons/arrowLeft.svg`,
    closeCross: `${process.env.PUBLIC_URL}/assets/icons/closeCross.svg`,
    boxCompleted: `${process.env.PUBLIC_URL}/assets/icons/boxCompleted.svg`,
    boxActivityLocked: `${process.env.PUBLIC_URL}/assets/icons/boxActivityLocked.svg`,
    boxLocked: `${process.env.PUBLIC_URL}/assets/icons/boxLocked.svg`,
    boxPassed: `${process.env.PUBLIC_URL}/assets/icons/boxPassed.svg`,
    boxFailed: `${process.env.PUBLIC_URL}/assets/icons/boxFailed.svg`,
    resourcePdfFile: `${process.env.PUBLIC_URL}/assets/icons/resourcePdfFile.svg`,
    resourceHyperLink: `${process.env.PUBLIC_URL}/assets/icons/resourceHyperLink.svg`,
    resourceDefaultIcon: `${process.env.PUBLIC_URL}/assets/icons/resourceDefaultIcon.svg`,
    rotateScreenIcon: `${process.env.PUBLIC_URL}/assets/icons/rotateScreenIcon.png`,
    skillTesterBulletPoint: `${process.env.PUBLIC_URL}/assets/icons/resourceDefaultIcon.svg`,
    knowledgeMappingButtonStart: `${process.env.PUBLIC_URL}/assets/icons/arrowLeft.svg`,
    knowledgeMappingButtonBack: `${process.env.PUBLIC_URL}/assets/icons/resourceDefaultIcon.svg`,
    skipModalIcon: `${process.env.PUBLIC_URL}/assets/icons/resourceDefaultIcon.svg`,
  },
  footer: {
    height: "4em",
    mobileHeight: "8em",
  },
};

export const accessTheme: Theme = theme;
export const styled = baseStyled as ThemedStyledInterface<Theme>;
export type styledAnimation = typeof keyframes;
export type StyleSheet = { [key: string]: React.CSSProperties };
