import { styled } from "../../theme";
import { MediaImage } from "../../types";
import { lighten, darken } from "@material-ui/core";

export const Background = styled.div<{ bg: string | MediaImage, image?:MediaImage}>`
  min-width: 100%;
  min-height: 100%;
  height: 100%;
  width: 100%;
  z-index: -2;
  background-color: ${({ theme }) => theme.color.homeBackground};
  &::after {
    content: "";
    position: absolute;
    bottom: 0;
    left: 0;
    height: 90%;
    width:90%;
    //z-index: -2;
    ${({ bg }) => (typeof bg === "string" ? `background-color: ${bg}` : `background-image: url(${bg.url})`)};
    background-size: contain;
    background-position: left, bottom;
    background-repeat: no-repeat;
    ${({ theme }) => theme.breakpoints.down("sm")} {
    background-image: unset;
    background-color:unset;
  }
  }
`;

export const VideoBackgroundContainer = styled.div`
  width: 100%;
  min-height: 100%;
  height: 100%;
  position: relative;
  box-sizing: border-box;
  overflow: hidden;
`;

export const CarouselBackgroundContainer = styled.div`
  width: 100%;
  min-height: 73vh;
  height: 100%;
  position: relative;
  box-sizing: border-box;
  overflow: hidden;
`;

type VideoBackgroundProps = {
  computedWidth: number;
  computedHeight: number;
  containerWidth: number;
  containerHeight: number;
};

export const VideoBackground = styled.video<VideoBackgroundProps>`
  position: absolute;
  z-index: -2;
  top: ${({ computedHeight, containerHeight }) => `-${(computedHeight - containerHeight) / 2}px`};
  left: ${({ computedWidth, containerWidth }) => `-${(computedWidth - containerWidth) / 2}px`};
  width: ${({ computedWidth }) => `${computedWidth}px`};
  height: ${({ computedHeight }) => `${computedHeight}px`};
`;

export const BackgroundChildrenContainer = styled.div<{ bg: string | MediaImage }>`
  min-width: 100%;
  position: relative;
  min-height: 100%;
  height: 100%;
  z-index: 10;
 
`;
