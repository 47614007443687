import React from "react";
import { HiddenCheckboxStyle, AnswerButtonLabelStyle } from "./AnswerButton.style";

interface Props {
  answerText: string;
  id: number;
  onChange: (status: boolean) => any;
  checked: boolean;
}

const AnswerButton: React.FC<Props> = (props) => {
  const onChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    props.onChange(event.target.checked);
  };

  return (
    <React.Fragment>
      <AnswerButtonLabelStyle className="label-answer" htmlFor={`answer_${props.id}`}>
        {props.answerText}
      </AnswerButtonLabelStyle>
      <HiddenCheckboxStyle
        checked={props.checked}
        type="checkbox"
        id={`answer_${props.id}`}
        onChange={onChange}
        className="answer-button"
      ></HiddenCheckboxStyle>
    </React.Fragment>
  );
};
export default AnswerButton;
