import { styled } from "../../theme";
import { lightenColor } from "../../utils/utils";
import { MediaImage } from "../../types";

export const FakeQuestionContainer = styled.div`
  max-width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  min-height: 100%;
  height: 100%;
  justify-content: center;
  ${({ theme }) => theme.breakpoints.down("sm")} {
    min-height: 100%;
    height: initial;
  }
`;

export const FakeQuestionTitleContainer = styled.div``;
export const FakeQuestionTextContainer = styled.div`
  width: 100%;
  min-height: 60%;
  height: 60%;
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  ${({ theme }) => theme.breakpoints.down("sm")} {
    min-height: 45vh;
    height: initial;
  }
`;

export const FakeQuestionText = styled.div`
  max-width: 80%;
  box-sizing: border-box;
  font-size: 1.6rem;
  font-weight: bold;
`;
export const FakeQuestionAnswerListContainer = styled.ul<{ answerListHeight: number }>`
  box-sizing: border-box;
  padding: 0;
  position: absolute;
  margin: 0;
  top: ${({ answerListHeight }) => `${answerListHeight / -2}px`};
  width: 100%;
  display: flex;
  justify-content: space-evenly;
  flex-wrap: wrap;
  list-style: none;
  ${({ theme }) => theme.breakpoints.down("sm")} {
    flex-direction: column;
    position: relative;
    top: inherit;
    align-items: center;
    justify-content: flex-start;
  }
`;
export const FakeQuestionAnswerItemContainer = styled.li<{ color?: string; answersLenght: number }>`
  box-sizing: border-box;
  color: ${({ color, theme }) => (color === undefined ? theme.color.text : color)};
  border-style: solid;
  border-width: 0.3em;
  font-weight: bold;
  border-color: ${({ color, theme }) => (color === undefined ? theme.color.mainTitle : color)};
  width: calc(90% / ${({ answersLenght }) => answersLenght});
  border-radius: 0.6em;
  display: flex;
  justify-content: space-around;
  background-color: #fafafa;
  position: relative;
  align-items: center;
  padding: 0.5em;
  min-height: 5em;
  transition: all 0.2s ease-in;
  &:hover {
    cursor: pointer;
    border-color: ${({ color, theme }) => lightenColor(color === undefined ? theme.color.mainTitle : color, 5)};
    background-color: ${() => lightenColor("#fafafa", 5)};
    box-shadow: 0px 0px 7px 3px rgba(0, 0, 0, 0.2);
  }
  &.checked {
    cursor: pointer;
    border-color: ${({ color, theme }) => lightenColor(color === undefined ? theme.color.mainTitle : color, 5)};
    background-color: ${({ color, theme }) => lightenColor(color === undefined ? theme.color.mainTitle : color, 5)};
    box-shadow: 0px 0px 7px 3px rgba(0, 0, 0, 0.2);
    color: #fafafa;
  }

  ${({ theme }) => theme.breakpoints.down("sm")} {
    width: 80%;
    margin-bottom: 1em;
  }
`;
export const FakeQuestionAnswerContainer = styled.div<{ bg?: MediaImage | string }>`
  background: ${({ bg }) => (bg ? (typeof bg === "string" ? bg : `url("${bg.url}")`) : "none")};
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center;
  position: relative;
  box-sizing: border-box;
  width: 100%;
  min-height: 40%;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: flex-end;
  padding-bottom: 5%;

  ${({ theme }) => theme.breakpoints.down("sm")} {
    flex-direction: column;
    min-height: 50vh;
    align-items: center;
    justify-content: flex-start;
    padding-top: 1em;
    padding-bottom: 1em;
  }
`;
