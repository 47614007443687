import React, { useContext, useEffect, useRef } from "react";
import { BaseActivityProps } from "../Activity/Activity.component";
import {
  Container,
  StorylineIframe,
  StorylineIframeContainer,
  StorylineTitleContainer,
} from "./ActivityEmbeddedScorm.style";
import { ActivityEmbeddedScorm } from "../../types";
import { observer } from "mobx-react";
import { StoreContext } from "../../context";
import Title from "../Title/Title.component";
import { useState } from "react";
import { isMobile, isPortrait } from "../../utils/utils";

interface Props extends BaseActivityProps {
  activity: ActivityEmbeddedScorm;
}

declare global {
  interface Window {
    DIGIBOX_API?: Record<string, any>;
  }
}
const ActivityEmbeddedScormContainer: React.FC<Props> = (props) => {
  const { core, store } = useContext(StoreContext);
  const [titleContainerHeight, setTitleContainerHeight] = useState(0);
  const ref = useRef<HTMLDivElement>(null);
  const refIframe = useRef<HTMLIFrameElement>(null);

  const shouldDisplayMobile = isMobile() && isPortrait() && props.activity.mobileUrl !== undefined;
  const shouldDisplayAccessible = props.activity.accessibleUrl !== undefined && store.accessibilityMode;
  const isTracked = props.activity.isNeededForCompletion || store.currentBox?.lockedByThisActivity;

  window.DIGIBOX_API = {
    SetBookmark: (str: string) => {
      core.setInteractionChunk(props.activity.id, str, undefined);
    },
    GetBookmark: () => {
      return core.getInteractionChunk(props.activity.id)?.location || "";
    },
    SetDataChunk: (str: string) => {
      core.setInteractionChunk(props.activity.id, undefined, str);
    },
    GetDataChunk: (): string => {
      let persistedRawChunk = "";
      if (core.getInteractionChunk(props.activity.id) && core.getInteractionChunk(props.activity.id)?.suspend_data) {
        persistedRawChunk = (core.getInteractionChunk(props.activity.id)?.suspend_data as string) || "";
      }
      return persistedRawChunk;
    },
    SetFailed: () => {
      core.setInteractionResult(props.activity.id, "incorrect");
    },
    SetPassed: () => {
      props.onEnd && props.onEnd();
      core.setInteractionResult(props.activity.id, "correct");
    },
    SetCompleted() {
      props.onEnd && props.onEnd();
      core.setInteractionResult(props.activity.id, "neutral");
    },
    SetScore(intScore: number) {
      void core.setScoreRaw(intScore);
      void core.setScoreScaled(intScore);
    },
  };

  const addCustomStyle = () => {
    const htmldoc = refIframe.current?.contentDocument;
    if (htmldoc) {
      const foobarElement = htmldoc.createElement("style");
      foobarElement.textContent = ".course-navigation__sidebar--nav-open{margin-left:0}";
      const firstChild = htmldoc.body.firstChild;
      if (firstChild) {
        htmldoc.body.insertBefore(foobarElement, firstChild);
      } else {
        htmldoc.body.appendChild(foobarElement);
      }
    }
  };

  useEffect(() => {
    if (titleContainerHeight === 0) {
      setTitleContainerHeight(ref.current ? ref.current.offsetHeight : 0);
    }
  }, [ref]);

  useEffect(() => {
    if (isTracked && props.activity.tracking) {
      if (props.activity.tracking.completeCondition === "open") {
        void core.setInteractionCompletion(props.activity.id);
      }
    }
    const iframe = refIframe.current?.contentWindow;

    if (iframe) {
      iframe.addEventListener("load", addCustomStyle);

      return () => {
        iframe.removeEventListener("load", addCustomStyle);
      };
    }
  }, []); // empty array => run only once

  return (
    <Container className="activity-storyline-container">
      {props.activity.title && (
        <StorylineTitleContainer ref={ref} className="storyline-title-container">
          <Title level={2} title={props.activity.title} />
        </StorylineTitleContainer>
      )}
      <StorylineIframeContainer titleHeight={titleContainerHeight} className="storyline-iframe-container">
        {shouldDisplayAccessible && (
          <StorylineIframe
            className="storyline-iframe-accessible"
            title={props.activity.title}
            allowFullScreen={true}
            src={props.activity.accessibleUrl}
            ref={refIframe}
            tabIndex={0}
          />
        )}
        {shouldDisplayMobile && !shouldDisplayAccessible && (
          <StorylineIframe
            className="storyline-iframe-mobile"
            title={props.activity.title}
            allowFullScreen={true}
            src={props.activity.mobileUrl}
            ref={refIframe}
            tabIndex={0}
          />
        )}
        {!shouldDisplayMobile && !shouldDisplayAccessible && (
          <StorylineIframe
            className="storyline-iframe-desktop"
            title={props.activity.title}
            allowFullScreen={true}
            src={props.activity.desktopUrl}
            ref={refIframe}
            tabIndex={0}
          />
        )}
      </StorylineIframeContainer>
    </Container>
  );
};

export default observer(ActivityEmbeddedScormContainer);
