/* eslint-disable @typescript-eslint/no-unsafe-assignment */
import { Store } from "./store";
import { Box, Activity, ContentBox } from "../types";
import { GLOBAL_PARAMS } from "../content";
import { createWrapper } from "@upandgo/scorm-wrapper";
import { action, autorun, set, toJS } from "mobx";
import { findItemAndIndexById, RequiredBy, updateQuizWithResult } from "../utils/utils";
import axios from "axios";
import dayjs from "dayjs";
import customParseFormat from "dayjs/plugin/customParseFormat";

import { AnswerResult, QuestionAggregate } from "@upandgo/react-quiz";
import { LANGUAGES } from "../constants";

class Core {
  private store: Store;
  private scorm: ReturnType<typeof createWrapper>;
  private getNavigatorCompatibleLanguage: () => string;
  private supportedLanguage: LANGUAGES[];

  constructor(
    store: Store,
    wrapperCreationFunc: typeof createWrapper,
    getNavigatorCompatibleLanguage: () => string,
    supportedLanguage: LANGUAGES[],
  ) {
    this.store = store;
    this.scorm = wrapperCreationFunc({
      baseStorageType:
        process.env.NODE_ENV === "development"
          ? "local"
          : (process.env.REACT_APP_SCORM_STORAGE as "2004" | "local" | "1.2") || "local",
      moduleId: GLOBAL_PARAMS["applicationId"],
      filteredScormValues: GLOBAL_PARAMS["scormFilters"],
      debug: {
        /*provide context for datadog debug tracking*/
        context: { env: "example-preprod", service: "example-digibox-client-name" },
        /*determine logger level to display*/
        minLogLevel: "all",
        /*Activate datadog tracking for exploitation debug (BE CAREFUL AND WARN BEFORE USING IN CUSTOMER CONTEXT) */
        sendLogs: false,
      },
    });
    this.getNavigatorCompatibleLanguage = getNavigatorCompatibleLanguage;
    this.supportedLanguage = supportedLanguage;
  }

  // Init
  readonly bootstrap = async (): Promise<void> => {
    this.setUserLanguage();
    void this.scorm.init({ startSession: true, isDebug: process.env.NODE_ENV === "development" });
    await this.insertSCORMStateInStore();
    for (let i = 0, n = this.store.content.boxList.length; i < n; i++) {
      autorun(this.computeObjectiveStatus(this.store.content.boxList[i]), { requiresObservable: true });
    }
    autorun(this.computeGlobalSuccessStatus, { requiresObservable: true });
    if (GLOBAL_PARAMS["shouldStoreInteractionAndObjectiveInSuspendData"])
      autorun(this.storeInteractionAndObjectiveListInSuspendData);
    if (!GLOBAL_PARAMS["shouldStoreInteractionAndObjectiveInSuspendData"])
      autorun(this.storeInteractionChunkAndSkillTesterResultInSuspendData);
    if (GLOBAL_PARAMS["shouldInitializeScore"]) {
      const score = await this.scorm.getBaseData("scoreRaw");
      if (!score) await this.scorm.safelySetScore(0);
    }
    autorun(this.computedGlobalCompleteStatus);
    autorun(this.computeProgressMeasure);

    void this.scorm.setBaseData("exit", "suspend");
  };

  @action
  setUserLanguage = (forcedLanguage?: LANGUAGES) => {
    const language = forcedLanguage || this.getNavigatorCompatibleLanguage();

    if (this.supportedLanguage.includes(language as any)) {
      this.store.lang = language as LANGUAGES;
    }

    document.getElementById("html")?.setAttribute("lang", this.store.lang);
  };

  close = (): void => {
    void this.scorm.close();
  };

  sendSCORMStatsToServer = async (props: { url: string; identity: any }) => {
    await axios.post(props.url, {
      applicationId: GLOBAL_PARAMS["applicationId"],
      user: props.identity,
      scormState: {
        successStatus: this.store.successStatus,
      },
    });
  };

  storeInteractionAndObjectiveListInSuspendData = async () => {
    await this.scorm.setBaseData(
      "suspendData",
      JSON.stringify({
        objectiveList: this.store.objectiveList,
        interactionList: this.store.interactionList,
        interactionChunk: this.store.interactionChunk,
        skillTesterResults: this.store.skillTesterResults,
        accessibilityMode: this.store.accessibilityMode,
      }),
    );
  };
  storeInteractionChunkAndSkillTesterResultInSuspendData = async () => {
    await this.scorm.setBaseData(
      "suspendData",
      JSON.stringify({
        interactionChunk: this.store.interactionChunk,
        skillTesterResults: this.store.skillTesterResults,
        accessibilityMode: this.store.accessibilityMode,
      }),
    );
  };
  @action
  switchAccessibilityMode = () => {
    this.store.accessibilityMode = !this.store.accessibilityMode;
  };

  resetStore = () => {
    const indxDb = window.indexedDB;
    const req = indxDb.open(GLOBAL_PARAMS.localforageName);
    req.onsuccess = () => {
      const db = req.result;
      const trans = db.transaction(GLOBAL_PARAMS.applicationId, "readwrite");
      trans.objectStore(GLOBAL_PARAMS.applicationId).clear();
    };
  };

  @action
  insertSCORMStateInStore = async () => {
    const suspendDataString = await this.scorm.getBaseData("suspendData");
    const isSuspendDataAlreadyPresent =
      typeof suspendDataString === "string" && suspendDataString !== undefined && suspendDataString.length > 0;
    this.store.isFirstTime = !isSuspendDataAlreadyPresent;
    this.store.successStatus = ((await this.scorm.getBaseData("successStatus")) as any) || "unknown";
    this.store.completionStatus = ((await this.scorm.getBaseData("completeStatus")) as any) || "not attempted";
    if (GLOBAL_PARAMS["shouldStoreInteractionAndObjectiveInSuspendData"]) {
      if (isSuspendDataAlreadyPresent) {
        const suspendData = JSON.parse(suspendDataString);
        if (suspendData) {
          if (Object.keys(suspendData).includes("interactionList")) {
            this.store.interactionList = suspendData.interactionList;
          }
          if (Object.keys(suspendData).includes("interactionChunk")) {
            this.store.interactionChunk = suspendData.interactionChunk;
          }
          if (Object.keys(suspendData).includes("objectiveList")) {
            this.store.objectiveList = suspendData.objectiveList;
          }
          if (Object.keys(suspendData).includes("skillTesterResults")) {
            this.store.skillTesterResults = suspendData.skillTesterResults;
          }
          if (Object.keys(suspendData).includes("accessibilityMode")) {
            this.store.accessibilityMode = suspendData.accessibilityMode;
          }
        }
      }
    } else {
      if (isSuspendDataAlreadyPresent) {
        const suspendData = JSON.parse(suspendDataString);
        if (suspendData) {
          if (Object.keys(suspendData).includes("interactionChunk")) {
            this.store.interactionChunk = suspendData.interactionChunk;
          }
        }
      }
      const interactionsCount =
        process.env.NODE_ENV === "development"
          ? Number(process.env.REACT_APP_INTERACTIONS_COUNT)
          : await this.scorm.getBaseData("interactionsCount");

      for (let i = 0, n = interactionsCount || 1; i < n; i++) {
        const [id, result] = await Promise.all([
          this.scorm.getInteractionData("id", i),
          this.scorm.getInteractionData("result", i),
        ]);
        if (id) {
          this.store.interactionList.push({
            id: id,
            result: result as "correct" | "incorrect" | "unanticipated" | "neutral",
          });
        }
      }
    }
  };

  @action
  setInteractionResult = (idActivity: string, result: "correct" | "incorrect" | "neutral"): void => {
    const findResult = findItemAndIndexById(idActivity, this.store.interactionList);
    if (findResult) {
      const previousResult = this.store.interactionList[findResult.index].result;
      if (previousResult === "correct") return;
      this.store.interactionList[findResult.index] = { ...this.store.interactionList[findResult.index], result };
    } else {
      this.store.interactionList.push({ id: idActivity, result });
    }
    if (!GLOBAL_PARAMS["shouldStoreInteractionAndObjectiveInSuspendData"]) {
      void this.scorm.setInteractionData(
        "id",
        findResult ? findResult.index : this.store.interactionList.length - 1,
        idActivity,
      );
      void this.scorm.setInteractionData(
        "result",
        findResult ? findResult.index : this.store.interactionList.length - 1,
        result,
      );
    }
  };
  @action
  setInteractionChunk = (idActivity: string, location?: string, suspend_data?: string): void => {
    const findChunk = findItemAndIndexById(idActivity, this.store.interactionChunk);
    if (findChunk && location) {
      this.store.interactionChunk[findChunk.index] = { ...this.store.interactionChunk[findChunk.index], location };
    } else {
      location && this.store.interactionChunk.push({ id: idActivity, location });
    }
    if (findChunk && suspend_data) {
      this.store.interactionChunk[findChunk.index] = { ...this.store.interactionChunk[findChunk.index], suspend_data };
    } else {
      suspend_data && this.store.interactionChunk.push({ id: idActivity, suspend_data });
    }
  };
  getInteractionChunk = (idActivity: string): { id: string; location?: string; suspend_data?: string } | undefined => {
    const findChunk = findItemAndIndexById(idActivity, this.store.interactionChunk);
    return (findChunk && this.store.interactionChunk[findChunk.index]) || undefined;
  };

  @action
  setInteractionCompletion = async (idActivity: string) => {
    const findResult = findItemAndIndexById(idActivity, this.store.interactionList);
    const activityNeededForCompletionList = this.getAllActivities(this.store.content.boxList).filter(
      (act) => act.isNeededForCompletion,
    );
    if (!findResult) {
      this.store.interactionList.push({ id: idActivity, result: "neutral" });
    }
    if (!GLOBAL_PARAMS["shouldStoreInteractionAndObjectiveInSuspendData"]) {
      await this.scorm.setInteractionData(
        "id",
        findResult ? findResult.index : this.store.interactionList.length - 1,
        idActivity,
      );
      await this.scorm.setInteractionData(
        "result",
        findResult ? findResult.index : this.store.interactionList.length - 1,
        findResult ? findResult.item.result : "neutral",
      );
    }

    if (this.store.completionStatus !== "completed") {
      const filteredInteractions = this.store.interactionList.filter((inter) => inter.result !== "incorrect");
      const lockerInteractionCount = this.getLockerActivityList(this.store.content.boxList).length;
      const completionStatus =
        filteredInteractions.length - lockerInteractionCount < activityNeededForCompletionList.length
          ? "incomplete"
          : "completed";
      await this.scorm.safelySetCompleteStatus(completionStatus);
      this.store.completionStatus = completionStatus;
    }
  };

  computeProgressMeasure = () => {
    const activitiesNeededForCompletion = this.getAllActivities(this.store.content.boxList).filter(
      (act) => act.isNeededForCompletion,
    );
    const lockerInteraction = this.getLockerActivityList(this.store.content.boxList);
    const filteredInteractionCount = this.store.interactionList.reduce((aq, inter) => {
      if (lockerInteraction.findIndex((lock) => lock.id === inter.id) === -1 && inter.result !== "incorrect") {
        aq++;
      }
      return aq;
    }, 0);
    const progressMeasure =
      activitiesNeededForCompletion.length === 0 ? 0 : filteredInteractionCount / activitiesNeededForCompletion.length;
    const roundedProgressMeasure = Math.round(progressMeasure * 100) / 100;

    void this.scorm.setBaseData("progressMeasure", roundedProgressMeasure);
    if (GLOBAL_PARAMS["convertProgressMeasureIntoScoreScaled"]) {
      void this.setScoreScaled(roundedProgressMeasure);
      void this.setScoreRaw(roundedProgressMeasure * 100);
    }
  };

  computedGlobalCompleteStatus = async () => {
    const activityNeededForCompletionList = this.getAllActivities(this.store.content.boxList).filter(
      (act) => act.isNeededForCompletion,
    );
    if (this.store.completionStatus !== "completed") {
      const lockerInteractionCount = this.getLockerActivityList(this.store.content.boxList).length;

      const filteredInteractionCount = this.store.interactionList.reduce((aq, inter) => {
        if (inter.result !== "incorrect") {
          aq++;
        }
        return aq;
      }, 0);
      const completionStatus =
        filteredInteractionCount - lockerInteractionCount < activityNeededForCompletionList.length
          ? "incomplete"
          : "completed";
      await this.scorm.safelySetCompleteStatus(completionStatus);
      this.store.completionStatus = completionStatus;
    }
  };

  computeGlobalSuccessStatus = async () => {
    if (GLOBAL_PARAMS["moduleSuccessOfObjectiveList"]) {
      let moduleSuccessStatus: "passed" | "failed" | "unknown" = "passed";
      if (GLOBAL_PARAMS["moduleSuccessOfObjectiveList"]["successCondition"] === "all") {
        const objectivesSuccessIdList = GLOBAL_PARAMS["moduleSuccessOfObjectiveList"]["objectivesSuccessIdList"];
        for (let i = 0, n = objectivesSuccessIdList.length; i < n; i++) {
          const findResult = findItemAndIndexById(objectivesSuccessIdList[i], this.store.objectiveList);

          if (!findResult) {
            moduleSuccessStatus = "unknown";
            break;
          }
          if (findResult && findResult.item.successStatus === "unknown") {
            moduleSuccessStatus = "unknown";
            break;
          }
          if (findResult && findResult.item.successStatus === "failed") {
            moduleSuccessStatus = "failed";
            break;
          }
        }
      }
      if (GLOBAL_PARAMS["moduleSuccessOfObjectiveList"]["successCondition"] === "firstOne") {
        const objectivesSuccessIdList = GLOBAL_PARAMS["moduleSuccessOfObjectiveList"]["objectivesSuccessIdList"];
        const result = objectivesSuccessIdList.find(
          (objective) => findItemAndIndexById(objective, this.store.objectiveList)?.item.successStatus === "passed",
        );
        if (!result) moduleSuccessStatus = "unknown";
      }

      await this.scorm.safelySetSuccessStatus(moduleSuccessStatus);
      this.store.successStatus = moduleSuccessStatus;
    }
  };

  computeObjectiveSuccess = (box: RequiredBy<ContentBox, "objective">): "passed" | "failed" | "unknown" => {
    let boxSuccessStatus: "passed" | "failed" | "unknown" = "passed";
    if (
      box.objective.successOnSucessOfInteractionList.length !==
      this.store.interactionList.filter((inter) => box.objective.successOnSucessOfInteractionList.includes(inter.id))
        .length
    )
      return "unknown";
    for (let i = 0, n = box.objective.successOnSucessOfInteractionList.length; i < n; i++) {
      const isInteractionSucceeded = this.store.interactionList.some(
        (int) => int.id === box.objective.successOnSucessOfInteractionList[i] && int.result === "correct",
      );

      if (!isInteractionSucceeded) {
        boxSuccessStatus = "failed";
        break;
      }
    }
    return boxSuccessStatus;
  };

  computeObjectiveCompletion = (box: ContentBox): "completed" | "incomplete" | "not attempted" => {
    let activitiesCompleted = 0;
    const activityNeededForCompletionList = box.activityList.filter((act) => act.isNeededForCompletion);
    const findResult = findItemAndIndexById(box.id, this.store.objectiveList);
    if (!findResult) return "not attempted";
    //This case is for skillTester objective completion option
    if (GLOBAL_PARAMS.objectivesCompletionBySkilltester && this.isValidateBySkillTester(box)) {
      return "completed";
    }
    //This case is for quiz or other interaction based on "correct"/ "incorrect" status
    if (findResult?.item.successStatus !== "unknown") {
      return "completed";
    }
    //This case is for skillTester skip modal
    if (findResult.item.completionStatus === "completed" && this.isValidateBySkillTester(box)) return "completed";
    //This case is the native interaction behaviour
    for (let i = 0, n = activityNeededForCompletionList.length; i < n; i++) {
      const interaction = this.store.interactionList.find((int) => int.id === activityNeededForCompletionList[i].id);
      if (interaction && interaction.result !== "incorrect") activitiesCompleted++;
      if (interaction && interaction.result === "incorrect") return "incomplete";
    }
    //Untracked boxes are automaticaly set to complete
    if (activityNeededForCompletionList.length === 0) return "completed";
    //
    if (activitiesCompleted === 0) return "not attempted";
    //Interaction completion is triggered by a simple count
    if (activitiesCompleted === activityNeededForCompletionList.length) return "completed";

    return "incomplete";
  };

  @action
  setObjectiveScoreRaw = (idObjective: string, scoreRaw: number): void => {
    const objective = findItemAndIndexById(idObjective, this.store.objectiveList);
    if (objective) {
      this.store.objectiveList[objective.index].scoreRaw = scoreRaw;
    }
  };

  @action
  setObjectiveScoreMin = (idObjective: string, scoreMin: number): void => {
    const objective = findItemAndIndexById(idObjective, this.store.objectiveList);
    if (objective) {
      this.store.objectiveList[objective.index].scoreMin = scoreMin;
    }
  };
  @action
  forceObjectiveCompletionStatus = (
    boxId: string,
    completionStatus: "completed" | "incomplete" | "not attempted" | "unknown",
  ): void => {
    const objective = findItemAndIndexById(boxId, this.store.objectiveList);
    if (objective) {
      this.store.objectiveList[objective.index].completionStatus = completionStatus;
    }
  };

  computeObjectiveStatus = (box: Box) => async () => {
    if (box.type === "menu") {
      for (let i = 0, n = box.boxList.length; i < n; i++) {
        void this.computeObjectiveStatus(box.boxList[i])();
      }
    } else if (box.type === "content") {
      const boxSuccessStatus = box.objective
        ? this.computeObjectiveSuccess({ ...box, objective: box.objective })
        : "unknown";
      const boxCompletionStatus = this.computeObjectiveCompletion(box);
      const findResult = findItemAndIndexById(box.id, this.store.objectiveList);
      // Check if box need an update, return if not
      if (
        findResult &&
        findResult.item.successStatus === boxSuccessStatus &&
        findResult.item.completionStatus === boxCompletionStatus
      )
        return;

      if (findResult) {
        // use set function from mobx because as the modified fields don't exist yet they are not tracked for change.
        set(this.store.objectiveList[findResult.index], {
          id: findResult.item.id,
          completionStatus: boxCompletionStatus,
          successStatus: boxSuccessStatus,
          scoreRaw: this.store.objectiveList[findResult.index].scoreRaw,
          scoreMin: this.store.objectiveList[findResult.index].scoreMin,
        });
      } else {
        this.store.objectiveList.push({
          id: box.id,
          successStatus: boxSuccessStatus,
          completionStatus: boxCompletionStatus,
          scoreRaw: box.type === "content" && box.keypoint ? 0 : undefined,
          scoreMin: box.type === "content" && box.keypoint ? 0 : undefined,
        });
      }
      const boxIndex = findResult ? findResult.index : this.store.objectiveList.length - 1;
      if (!GLOBAL_PARAMS["shouldStoreInteractionAndObjectiveInSuspendData"]) {
        await this.scorm.setObjectiveData("id", boxIndex, box.id);
        await this.scorm.setObjectiveData("successStatus", boxIndex, boxSuccessStatus);
        await this.scorm.setObjectiveData("completionStatus", boxIndex, boxCompletionStatus);
      }
    }
  };

  @action
  setCurrentBox = (box: Box | undefined): void => {
    this.store.currentBox = box;
  };

  @action
  setSkillTesterResults = (quizId: string, failedQuestionId: string): void => {
    if (!this.store.skillTesterResults[quizId]) {
      this.store.skillTesterResults[quizId] = {
        failedQuestionIdList: [failedQuestionId],
      };
    } else {
      this.store.skillTesterResults[quizId].failedQuestionIdList.push(failedQuestionId);
    }
  };

  @action
  resetSkillTesterResults = (quizId: string) => {
    if (this.store.skillTesterResults[quizId]) {
      this.store.skillTesterResults[quizId] = { failedQuestionIdList: [] };
    }
  };

  getBox = (id: string, boxList: Box[]): Box | undefined => {
    let result: Box | undefined = undefined;
    for (let i = 0, n = boxList.length; i < n; i++) {
      const box = boxList[i];
      if (box.id === id) {
        return box;
      }
      if (box.type === "menu") {
        result = this.getBox(id, box.boxList);
        if (result) break;
      }
    }
    return result;
  };

  getBoxParent = (id: string, boxList: Box[]): Box | undefined => {
    let result: Box | undefined = undefined;
    for (let i = 0, n = boxList.length; i < n; i++) {
      const box = boxList[i];
      if (box.type === "menu") {
        const foundBox = box.boxList.find((itemBox) => itemBox.id === id);
        if (foundBox) return box;
        else {
          result = this.getBoxParent(id, box.boxList);
          if (result) break;
        }
      }
    }
    return result;
  };

  getActivityList = (id: string, box: Box): Activity | undefined => {
    if (box.type !== "content") return undefined;
    return box.activityList.find((act) => act.id === id);
  };

  getAllActivities = (boxList: Box[]): Activity[] => {
    const activityList: Activity[] = [];
    for (let i = 0, n = boxList.length; i < n; i++) {
      const box = boxList[i];
      if (box.type === "content") {
        activityList.push(...box.activityList);
      } else if (box.type === "menu") {
        activityList.push(...this.getAllActivities(box.boxList));
      }
    }
    return activityList;
  };

  getLockerActivityList = (boxList: Box[]): Activity[] => {
    const activityLockerList: Activity[] = [];
    for (let i = 0, n = boxList.length; i < n; i++) {
      const box = boxList[i];
      if (box.lockedByThisActivity) {
        activityLockerList.push(...box.lockedByThisActivity);
      }
      if (box.type === "menu") {
        activityLockerList.push(...this.getLockerActivityList(box.boxList));
      }
    }
    return activityLockerList;
  };

  isBoxlockedByThisActivity = (id: string): boolean => {
    const box = this.getBox(id, this.store.content.boxList);
    if (!box?.lockedByThisActivity) return false;
    return !this.store.interactionList.some((obj) =>
      box.lockedByThisActivity?.map((activity) => activity.id).includes(obj.id),
    );
  };

  isBoxPassed = (id: string) => this.store.objectiveList.some((obj) => obj.id === id && obj.successStatus === "passed");

  isBoxFailed = (id: string) => this.store.objectiveList.some((obj) => obj.id === id && obj.successStatus === "failed");

  isValidateBySkillTester = (box: Box): boolean => {
    const findResult = findItemAndIndexById(box.id, this.store.objectiveList);
    if (box.type === "menu") {
      for (let i = 0, n = box.boxList.length; i < n; i++) {
        const subBox = box.boxList[i];
        if (subBox.type === "empty") continue;
        const isSubBoxValidateBySkillTester = this.isValidateBySkillTester(subBox);
        if (!isSubBoxValidateBySkillTester) return false;
      }
      return true;
    }
    if (box.type === "content" && box.keypoint?.threshold) {
      if (findResult && box.keypoint.threshold <= (findResult.item.scoreMin || 0)) {
        return true;
      }
    }
    return false;
  };

  isBoxComplete = (id: string): boolean => {
    const box = this.getBox(id, this.store.content.boxList);
    if (!box) return false;
    if (box.type === "menu") {
      for (let i = 0, n = box.boxList.length; i < n; i++) {
        const subBox = box.boxList[i];
        if (subBox.type === "empty") continue;
        if (subBox.type === "content" && !subBox.activityList.some((activity) => activity.isNeededForCompletion))
          continue;

        console.log(id);
        const isSubBoxComplete = this.isBoxComplete(subBox.id);
        if (!isSubBoxComplete) return false;
      }
      return true;
    }
    return this.store.objectiveList.some((obj) => obj.id === id && obj.completionStatus === "completed");
  };

  isBoxNeededForCompletion = (id: string): boolean => {
    const box = this.getBox(id, this.store.content.boxList);
    if (!box) return false;
    if (box.type === "content") {
      return box.activityList.some((activity) => activity.isNeededForCompletion);
    }
    if (box.type === "empty") return false;

    if (box.type === "menu") {
      for (let i = 0, n = box.boxList.length; i < n; i++) {
        const subBox = box.boxList[i];
        if (subBox.type === "content") return subBox.activityList.some((activity) => activity.isNeededForCompletion);
        if (subBox.type === "empty") continue;
        if (subBox.type === "menu") return this.isBoxNeededForCompletion(subBox.id);
      }
    }
    return false;
  };
  isBoxLocked = (id: string) => {
    const box = this.getBox(id, this.store.content.boxList);
    let isBoxListLocked = false;
    let isDateLocked = false;
    if (!box) return false;
    const listOfBoxWhichCompletionUnlockBox = box.lockedByCompletionOfBoxList;
    const dateWhichUnlockBox = box.lockedByDate;
    if (listOfBoxWhichCompletionUnlockBox) {
      for (let i = 0, n = listOfBoxWhichCompletionUnlockBox.length; i < n; i++) {
        const currObj = this.store.objectiveList.some(
          (obj) => obj.id === listOfBoxWhichCompletionUnlockBox[i] && obj.completionStatus === "completed",
        );
        if (!currObj) isBoxListLocked = true;
      }
    }
    if (dateWhichUnlockBox) {
      dayjs.extend(customParseFormat);
      const unlockDate = dayjs(dateWhichUnlockBox, "DD-MM-YYYY");
      const now = dayjs();
      if (!(now.diff(unlockDate) >= 0)) isDateLocked = true;
    }

    //TODO: Finish isBoxLocked to account the success
    const boxListSuccessNeededToUnlockBox = box.lockedByCompletionOfBoxList;

    return isBoxListLocked || isDateLocked;
  };

  setQuizAnswerResult = async ({
    quiz,
    answer,
    threshold,
    setAsGlobalScore,
    setAsSkillTester = false,
  }: {
    quiz: QuestionAggregate;
    answer: AnswerResult;
    threshold: number;
    setAsGlobalScore?: boolean;
    setAsSkillTester?: boolean;
  }) => {
    if (GLOBAL_PARAMS["convertProgressMeasureIntoScoreScaled"]) {
      window.alert(
        "Warning : convertProgressMeasureIntoScoreScaled is set on true.Score scaled value won't be record correctly. Please review your parameters to avoid corrupt tracking data",
      );
    }
    if (!this.store.quizResults[quiz.id]) {
      this.store.quizResults[quiz.id] = {
        answerList: [answer],
        successStatus: "unknown",
        numberOfRightAnswers: 0,
        quiz,
      };
    } else {
      this.store.quizResults[quiz.id].answerList.push(answer);
    }

    const { updatedQuiz, isAnswerValid } = updateQuizWithResult(this.store.quizResults[quiz.id].quiz, answer);
    if (!isAnswerValid && setAsSkillTester) this.setSkillTesterResults(quiz.id, answer.idQuestion);
    const numberOfRightAnswers = isAnswerValid
      ? this.store.quizResults[quiz.id].numberOfRightAnswers + 1
      : this.store.quizResults[quiz.id].numberOfRightAnswers;

    this.store.quizResults[quiz.id].quiz = updatedQuiz;
    this.store.quizResults[quiz.id].numberOfRightAnswers = numberOfRightAnswers;
    this.store.quizResults[quiz.id].successStatus = numberOfRightAnswers >= threshold ? "passed" : "failed";
    if (setAsGlobalScore) {
      const scoreMax = GLOBAL_PARAMS["customScoreMax"] || 100;
      await this.scorm.setBaseData("scoreMin", 0);
      await this.scorm.setBaseData("scoreMax", scoreMax);
      await this.setScoreRaw((numberOfRightAnswers / quiz.questionList.length) * scoreMax);
      await this.setScoreScaled(numberOfRightAnswers / quiz.questionList.length);
    }
  };
  setScoreRaw = async (scoreRaw: number) => {
    return this.scorm.safelySetScore(Math.round(scoreRaw));
  };
  setScoreScaled = async (scoreScaled: number) => {
    const roundedScaledScore = Math.round(scoreScaled * 100) / 100;

    return this.scorm.safelySetScoreScaled(roundedScaledScore);
  };

  resetQuizAnswerResult = () => {
    this.store.quizResults = {};
  };
}
export { Core };
