import React from "react";
import {
  ResourceIconContainer,
  ResourceInfoContainer,
  ResourceName,
  ResourceDescription,
  ResourceIcon,
} from "./StandardResourceItem.style";
import { Resource } from "../../types";
import { ResourceItemContainer } from "./StandardResourceItem.style";

interface Props {
  resource: Resource;
  actionClick?: () => void;
}
const StandardResourceItem = React.forwardRef<HTMLAnchorElement, Props>((props, ref) => {
  const handleClick = () => {
    if (props.actionClick) props.actionClick();
  };
  return (
    <ResourceItemContainer
      ref={ref}
      href={props.resource.url}
      target="_blank"
      download={ref ? true : false}
      className="resource-item-container"
      onClick={handleClick}
    >
      <ResourceIconContainer className="resource-icon-container">
        <ResourceIcon resourceType={props.resource.type} />
      </ResourceIconContainer>
      <ResourceInfoContainer className="resource-info-container">
        <ResourceName className="resource-name">{props.resource.name}</ResourceName>
        {props.resource.description && (
          <ResourceDescription className="resource-description">{props.resource.description}</ResourceDescription>
        )}
      </ResourceInfoContainer>
    </ResourceItemContainer>
  );
});
StandardResourceItem.displayName = "StandardResourceItem";
export default StandardResourceItem;
