import { styled, SKILLTESTER_MAIN_COLOR } from "../../theme";
import { StandardButton } from "../StandardButton/StandardButton.style";

const KNOWLEDGE_BG = SKILLTESTER_MAIN_COLOR;

interface ColumnContainerProps {
  reverse?: boolean;
}

export const Container = styled.div`
  padding: 0 0 1em 0;
  margin: auto 0;
  max-width: 100%;
  display: flex;
  flex-direction: column;
  gap: 1em;
  box-sizing: border-box;
  ${({ theme }) => theme.breakpoints.down("sm")} {
    max-width: 100%;
    flex-direction: column;
  }
`;
export const InformationBlock = styled.div<{ size: number }>`
  background-color: ${KNOWLEDGE_BG};
  box-sizing: border-box;

  width: ${({ size }) => size}%;
  padding: 1em 1rem;
  display: flex;
  flex-direction: column;
  /* min-height: 40%; */
  ${({ theme }) => theme.breakpoints.down("sm")} {
    width: 100%;
  }
`;
export const InformationWrapper = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-evenly;
  height: 100%;
`;

export const StatusContainer = styled.div`
  color: #fff;
  h2 {
    max-width: 90%;
    margin-left: auto;
    margin-right: auto;
    font-size: 4.6rem;
    text-align: center;
    ${({ theme }) => theme.breakpoints.down("md")} {
      font-size: 3rem;
    }
  }
`;
export const DetailText = styled.p`
  color: #fff;
  text-align: center;
  font-size: 2rem;
  margin: 0;
  ${({ theme }) => theme.breakpoints.down("md")} {
    font-size: 1.5rem;
  }
`;
export const SkippedTimeBlock = styled.div`
  color: #fff;
  ${({ theme }) => theme.breakpoints.down("sm")} {
    margin-bottom: 2em;
  }
`;
export const SkippedTimeWrapper = styled.div``;

export const SkippedTimeText = styled.p`
  text-align: center;
  margin-top: 0.5em;
  margin-bottom: 0.5em;
`;
export const SkippedTimeInMinute = styled.p`
  text-align: center;
  span {
    font-weight: bold;
    font-size: 1.5em;
    color: #fff;
  }
`;
export const SkippedTimeProgressBar = styled.div`
  max-width: 10%;
  margin-right: auto;
  margin-left: auto;
  .CircularProgressbar .CircularProgressbar-path {
    /* stroke: ${({ theme }) => theme.color.mainTitle}; */
    stroke: #fff;
    stroke-linecap: unset;
  }
  .CircularProgressbar .CircularProgressbar-text {
    stroke: none;
    fill: #fff;
    font-weight: bold;
    font-size: 0.9em;
  }
  .CircularProgressbar-trail {
    stroke: none;
  }
  .CircularProgressbar-path {
    stroke-linecap: unset;
  }

  ${({ theme }) => theme.breakpoints.down("sm")} {
    max-width: 25%;
  }
`;

export const KeypointBlock = styled.div<{ size: number }>`
  width: ${({ size }) => size}%;
  box-sizing: border-box;
  min-height: 40%;
  display: flex;
  flex-direction: column;
  gap: 3rem;
  ${({ theme }) => theme.breakpoints.down("sm")} {
    width: 100%;
  }
`;

export const KeypointBottomBlock = styled.div`
  display: flex;
  flex-direction: column;
`;
export const LegendWrapper = styled.ul`
  width: 100%;
  box-sizing: border-box;
  background-color: #fff;
  border-radius: 2em;
  min-height: 4em;
  margin: 0;
  display: flex;
  flex-direction: column;
  justify-content: center;
  list-style: none;
`;
export const LegendItem = styled.li`
  display: flex;
  font-size: 0.9em;
  margin-top: 0.2em;
  margin-bottom: 0.2em;
  &.legend-item-incorrect {
    color: ${({ theme }) => theme.color.keypointFailed};
  }
  &.legend-item-correct {
    color: ${({ theme }) => theme.color.keypointPassed};
  }
  &.legend-item-partial {
    color: ${({ theme }) => theme.color.keypointPartial};
  }
`;
export const LegendBulletPoint = styled.span`
  width: 1em;
  height: 1em;
  border-radius: 0.15em;
  display: block;
  background-position: center center;
  align-self: center;
  margin-right: 1em;
  background-repeat: no-repeat;
  background-size: 1.5rem 1.5rem;
  background-image: linear-gradient(
    45deg,
    #ffffff26 25%,
    #0000 25%,
    #0000 50%,
    #ffffff26 50%,
    #ffffff26 75%,
    #0000 75%,
    #0000
  );
  &.bulletpoint-incorrect {
    background-color: ${({ theme }) => theme.color.keypointFailed};
  }
  &.bulletpoint-correct {
    background-color: ${({ theme }) => theme.color.keypointPassed};
  }
  &.bulletpoint-partial {
    background-color: ${({ theme }) => theme.color.keypointPartial};
  }
`;

export const KnowledgeMappingWrapper = styled.div`
  display: flex;
  box-sizing: border-box;
`;

export const KnowledgeMappingTitleContainer = styled.div`
  /* padding: 1rem 0; */
  width: 100%;
  color: ${({ theme }) => theme.color.mainTitle};
  h2 {
    text-align: center;

    ${({ theme }) => theme.breakpoints.up("lg")} {
      font-size: 3rem;
    }
  }
`;
export const ButtonContainer = styled.div`
  text-align: center;
`;
export const StartButton = styled(StandardButton)`
  border-radius: 3.25em;
  padding: 0.5em 1em;
`;

export const ButtonTitleContainerStyle = styled.div`
  width: 100%;
  display: flex;
  align-items: center;
  flex-direction: column;
  justify-content: center;
  box-sizing: border-box;
  text-align: center;
  flex-grow: 2;
  min-height: 50%;
  margin: 0.5em 0;
  ${({ theme }) => theme.breakpoints.down("md")} {
    font-size: 0.5em;
  }
  ${({ theme }) => theme.breakpoints.down("sm")} {
    max-height: 100%;
    font-size: 0.5em;
  }
`;

export const Icon = styled.span<{ onStartIcon: boolean }>`
  width: 2em;
  height: 2em;
  margin-top: 0.5em;
  margin-bottom: 1em;
  background-image: ${({ theme, onStartIcon }) =>
    onStartIcon
      ? `url("${theme.icons.knowledgeMappingButtonStart}")`
      : `url("${theme.icons.knowledgeMappingButtonBack}")`};
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center;
  display: block;
`;
