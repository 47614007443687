import { LANGUAGES } from "../constants";
import { InterfaceText } from "../types";

const interfaceText: InterfaceText = {
  language: LANGUAGES.FR,
  durationUnit: "min",
  home: "Accueil",
  previous: "Précédent",
  next: "Suivant",
  close: "Fermer",
  submit: "Valider",
  score: "Votre score :",
  downloadCertificate: "Téléchargez votre certificat",
  inputLastName: "Nom",
  inputFirstName: "Prénom",
  backToHome: "Retour à l'accueil",
  tryAgain: "Refaire le quiz",
  validate: "Valider",
  rotateScreen: "Veuillez retournez votre périphérique",
  thresoldScore: "Score validant : 80",
  seeDetail: "Voir vos réponses",
  getCertificate: "Obtenez votre certificat",
  singleResourceDownload: "Vous êtes sur le point de télécharger un truc",
  countDownText: "Début du téléchargement dans",
  singleResourceDownloadAlternative: "Si le téléchargement n'a pas débuter, utiliser ce lien :",
  skillTester: {
    contextDescription:
      "Ce parcours est proposé en « adaptive learning ». Si vous disposez d’un bon niveau de connaissances, vous pouvez optimiser la durée de votre formation grâce au test de positionnement.",
    adaptiveButton: "Parcours « Adaptive »",
    completeCourseButton: "Parcours complet",
  },
  knowledgeMapping: {
    skillTesterStatus: "Votre test de positionnement est désormais terminé !",
    keypointDetails: "",
    skippedTimeTitle: (skippedMinutes: number) =>
      `Grâce à vos résultats, votre gain de temps s’établit à ${skippedMinutes} minute${
        skippedMinutes > 1 ? "s" : ""
      }. La durée de votre parcours sera de :`,
    noSkippedTimeTitle: "Vos résultats  ne vous permettent pas de réduire la durée de votre parcours.",
    start: "Commencer le module",
    resume: "Voir le module",
    legend: {
      partial: "En cours d'acquisition",
      correct: "Acquis",
      incorrect: "Non acquis",
    },
  },
  subMenu: {
    allActivitiesCompleted: "Tous les ateliers sont terminés. Fermer ce menu pour continuer votre parcours.",
  },
  activityCompleted:
    "L'atelier est désormais terminé. Vous pouvez revenir au menu principal pour continuer votre parcours.",
  accessibility: {
    accessibilityButton: "Accessible mode",
    accessibilityDescrition: "",
    boxItemStatus: {
      completed: "Workshop completed",
      locked: "Locked by previous workshop",
      passed: "Workshop passed",
      failed: "Workshop failed",
      activity_locked: "",
      untracked: "",
      pending: "",
    },
    offscreenSkipNav: {
      activateAccessibleMode: "Mode accessible",
      toTheMainContent: "to the main content",
      toTheFooterLinks: "to the footer links",
    },
  },
};

export default interfaceText;
