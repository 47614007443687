import React, { useContext, useEffect, useRef, useState } from "react";
import { BaseActivityProps } from "../Activity/Activity.component";
import { ActivityPlaylistVideo } from "../../types";
import { observer } from "mobx-react";
import { StoreContext } from "../../context";
import {
  Container,
  NavbarCloseButton,
  NavbarCloseIconContainer,
  PlaylistItemButton,
  PlaylistItemContainer,
  PlaylistNavBar,
  PlaylistVideoTitleContainer,
  RotateScreenContainer,
  RotateScreenIcon,
  RotateScreenMessage,
  SideBarStyle,
  VideoContainer,
} from "./ActivityPlaylistVideo.style";
import { MediaContainer, StandardButton, Title } from "..";
import SimpleBar from "simplebar-react";
import "simplebar/dist/simplebar.min.css";

interface Props extends BaseActivityProps {
  activity: ActivityPlaylistVideo;
}

const ActivityPlaylistVideoContainer: React.FC<Props> = (props) => {
  const ref = useRef<HTMLDivElement>(null);
  const { core, store } = useContext(StoreContext);
  const texts = store.interfaceText;
  const [titleContainerHeight, setTitleContainerHeight] = useState(0);
  const [isPortrait, setIsPortrait] = useState(window.innerWidth < window.innerHeight);
  const [activeVideoIndex, setActiveVideoIndex] = useState(0);
  const [drawerOpen, setDrawerOpen] = useState(true);
  const nextVideoIndex = props.activity.mediaList[activeVideoIndex + 1] ? activeVideoIndex + 1 : 0;
  const isTracked = props.activity.isNeededForCompletion || store.currentBox?.lockedByThisActivity;

  const onValidate = () => {
    if (props.goNext) props.goNext();
  };
  useEffect(() => {
    if (titleContainerHeight === 0) {
      setTitleContainerHeight(ref.current ? ref.current.offsetHeight : 0);
    }
  }, [ref]);

  function handleResize() {
    const portrait = window.innerWidth < window.innerHeight;
    setIsPortrait(portrait);
  }
  function toggleDrawer() {
    setDrawerOpen(!drawerOpen);
  }

  function displayVideo(index: number) {
    setActiveVideoIndex(index);
  }
  const setActivityCompletionAccordingToCompletionCondition = async () => {
    if (isTracked && props.activity.tracking) {
      if (props.activity.tracking.completeCondition === "open") {
        await core.setInteractionCompletion(props.activity.id);
      }
    }
    if (props.nextBehaviour === "close" && props.onEnd) {
      props.onEnd();
    }
  };
  useEffect(() => void setActivityCompletionAccordingToCompletionCondition(), []);
  useEffect(() => {
    window.addEventListener("resize", handleResize);
    return () => window.removeEventListener("resize", handleResize);
  }, []);

  const playlistNavbarContent = props.activity.mediaList.map((video, index) => {
    const isActive = activeVideoIndex === index;
    return (
      <React.Fragment key={index}>
        <PlaylistItemContainer className={`playlist-item-container item_${index} ${isActive ? "active" : ""}`}>
          <PlaylistItemButton
            id={`video-${index}`}
            className={"playlist-item-button"}
            onClick={() => displayVideo(index)}
          >
            <span>{video.name}</span>
          </PlaylistItemButton>
        </PlaylistItemContainer>
      </React.Fragment>
    );
  });
  return (
    <Container className="main-playlist-video-container">
      {props.activity.title && (
        <PlaylistVideoTitleContainer ref={ref} className="video-title-container">
         <Title level={2} title={props.activity.title} />
        </PlaylistVideoTitleContainer>
      )}
      <PlaylistNavBar open={drawerOpen} className="playlist-video-navbar">
        <>
          <NavbarCloseButton
            className="playlist-video-navbar-close-button"
            onClick={() => {
              toggleDrawer();
            }}
          >
            <NavbarCloseIconContainer className="playlist-close-icon-container" reverseDirection={!drawerOpen} />
          </NavbarCloseButton>
          <SideBarStyle className="sidebar-wrapper">
            <SimpleBar autoHide={false} style={{ height: "100%", width: "100%" }}>
              {playlistNavbarContent}
            </SimpleBar>
          </SideBarStyle>
        </>
      </PlaylistNavBar>

      {!isPortrait && (
        <VideoContainer titleHeight={titleContainerHeight} className="video-container">
          <MediaContainer
            media={props.activity.mediaList[activeVideoIndex].media}
            onEnded={() => displayVideo(nextVideoIndex)}
          />
        </VideoContainer>
      )}
      {isPortrait && (
        <RotateScreenContainer>
          <RotateScreenIcon />
          <RotateScreenMessage>{texts.rotateScreen}</RotateScreenMessage>
        </RotateScreenContainer>
      )}

      {props.goNext && (
        <StandardButton onClick={onValidate}>
          {props.nextBehaviour === "close" ? texts.close : texts.next}
        </StandardButton>
      )}
    </Container>
  );
};

export default observer(ActivityPlaylistVideoContainer);
