import React, { useState } from "react";
import FocusTrap from "focus-trap-react";
import {
  FooterStyle,
  FooterLeftContainerStyle,
  FooterLeftItemStyle,
  FooterLeftItemLinkStyle,
  FooterRightContainerStyle,
  FooterRightItemLinkStyle,
  FooterRightItemStyle,
  ContainerInModal,
  FooterLeftItemModalStyle,
  ModalCloseButtonContainer,
  FooterWrapper,
} from "./Footer.style";
import { FooterLeftItem, FooterRightItem, MediaImage } from "../../types";
import ReactModal from "react-modal";

import { theme } from "../../theme";
import Debug from "../DebugComponent/Debug.component";
import StandardCloseButton from "../StandardCloseButton/StandardCloseButton.component";
import { isMobile } from "../../utils/utils";

interface Props {
  leftItemList: FooterLeftItem[];
  rightItemList: FooterRightItem[];
  footerBackground: MediaImage | string;
  hidden?: boolean;
}

const Footer: React.FC<Props> = (props) => {
  const [modalContent, setModalContent] = useState<string | undefined>();
  const onClickContentItem = (content: string) => () => {
    setModalContent(content);
  };
  return (
    <FooterStyle id="footer" bg={props.footerBackground} hidden={props.hidden}>
      <FooterWrapper>
        {!isMobile() && <Debug />}
        <LeftItemList onClickContentItem={onClickContentItem} itemList={props.leftItemList} />
        <RightItemList itemList={props.rightItemList} />
      </FooterWrapper>
      <ReactModal
        style={{
          overlay: {
            backgroundColor: theme.color.overlayColor,
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            zIndex: 10,
          },
          content: {
            position: "unset",
            maxWidth: "70vw",
            maxHeight: "90vh",
          },
        }}
        isOpen={modalContent !== undefined}
        onRequestClose={() => setModalContent(undefined)}
        appElement={document.getElementById("root") || undefined}
      >
        <FocusTrap>
          <ContainerInModal>
            <ModalCloseButtonContainer>
              <StandardCloseButton onClick={() => setModalContent(undefined)} />
            </ModalCloseButtonContainer>
            <div
              style={{ paddingRight: "3em", paddingLeft: "3em" }}
              dangerouslySetInnerHTML={{ __html: modalContent || "" }}
            />
          </ContainerInModal>
        </FocusTrap>
      </ReactModal>
    </FooterStyle>
  );
};

const LeftItemList = (props: { itemList: FooterLeftItem[]; onClickContentItem: (content: string) => () => any }) => {
  if (props.itemList.length === 0) return null;
  return (
    <FooterLeftContainerStyle className="footer-left-container">
      {props.itemList.map((item) => (
        <FooterLeftItemStyle key={item.id} className="footer-left-item">
          {item.url && (
            <FooterLeftItemLinkStyle
              className="footer-left-link"
              href={item.url}
              target="_blank"
              rel="noopener noreferrer"
            >
              {item.text}
            </FooterLeftItemLinkStyle>
          )}
          {item.content && (
            <FooterLeftItemModalStyle
              className="footer-left-modal-link"
              onClick={props.onClickContentItem(item.content)}
            >
              {item.text}
            </FooterLeftItemModalStyle>
          )}
        </FooterLeftItemStyle>
      ))}
    </FooterLeftContainerStyle>
  );
};
const RightItemList = (props: { itemList: FooterRightItem[] }) => {
  if (props.itemList.length === 0) return null;

  return (
    <FooterRightContainerStyle className="footer-right-container">
      {[...props.itemList].reverse().map((item) => (
        <FooterRightItemStyle key={item.id} className="footer-right-item">
          {item.url && (
            <FooterRightItemLinkStyle
              className="footer-left-link"
              href={item.url}
              target="_blank"
              rel="noopener noreferrer"
              aria-label={item.accessibleLabel}
            >
              <img src={item.image.url} alt={item.image.alt} />
            </FooterRightItemLinkStyle>
          )}
          {!item.url && <img src={item.image.url} alt={item.image.alt} />}
        </FooterRightItemStyle>
      ))}
    </FooterRightContainerStyle>
  );
};

export default Footer;
