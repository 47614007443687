import FocusTrap from "focus-trap-react";
import React, { useContext } from "react";
import { StoreContext } from "../../context";
import { Box } from "../../types";
import StandardCloseButton from "../StandardCloseButton/StandardCloseButton.component";
import {
  ButtonRow,
  SkipBoxContainer,
  SkipBoxModalContainer,
  SkipModalIcon,
  SkipModalText,
  SkipModalWrapper,
  CloseButtonContainer,
  ButtonContainer,
  TimeSavingText,
  SkipButton,
} from "./SkipBoxCompletion.style";

interface Props {
  box: Box;
  close: () => any;
  onAction: () => any;
}

const SkipBoxCompletion: React.FC<Props> = (props) => {
  const { core, store } = useContext(StoreContext);
  const texts = store.interfaceText;
  const skipBox = (boxId: string) => {
    core.forceObjectiveCompletionStatus(boxId, "completed");
    props.close();
  };

  return (
    <FocusTrap>
      <SkipBoxContainer>
        <SkipBoxModalContainer>
          <CloseButtonContainer>
            <StandardCloseButton onClick={props.close} />
          </CloseButtonContainer>
          <SkipModalWrapper>
            <SkipModalIcon />
            <SkipModalText
              dangerouslySetInnerHTML={{ __html: texts.skillTester?.skipModal?.mainText || "" }}
            ></SkipModalText>
            <ButtonRow>
              <ButtonContainer>
                <SkipButton
                  onClick={() => {
                    props.onAction();
                  }}
                >
                  {texts.skillTester?.skipModal?.goToContentButton}
                </SkipButton>
              </ButtonContainer>
              <ButtonContainer>
                <SkipButton onClick={() => skipBox(props.box.id)}>
                  {texts.skillTester?.skipModal?.skipContentButton}
                </SkipButton>
                {props.box.type === "content" && props.box.estimatedTime && (
                  <TimeSavingText>{`${texts.skillTester?.skipModal?.timeSaving || "Time saving"}: ${
                    props.box.estimatedTime
                  } ${texts.durationUnit}`}</TimeSavingText>
                )}
              </ButtonContainer>
            </ButtonRow>
          </SkipModalWrapper>
        </SkipBoxModalContainer>
      </SkipBoxContainer>
    </FocusTrap>
  );
};
export default SkipBoxCompletion;
