import React from "react";
import { BreadcrumbContainer, BreadcrumbItem } from "./QuizBreadcrumb.style";

interface Props {
  questionsLenght: number;
  currentQuestionIndex: number;
}

const QuizBreadcrumb: React.FC<Props> = (props) => {
  const generateBreadcrumbList = () => {
    const itemList = [];
    for (let i = 1; i <= props.questionsLenght; i++) {
      itemList.push(renderBreadcrumbItem(i, props.currentQuestionIndex));
    }
    return itemList;
  };

  const renderBreadcrumbItem = (questionIndex: number, currentQuestionIndex: number) => {
    let itemStatus = "";
    if (questionIndex === currentQuestionIndex + 1) itemStatus = "current";
    if (questionIndex < currentQuestionIndex + 1) itemStatus = "passed";

    return <BreadcrumbItem className={`breadcrumb-item ${itemStatus}`}>&bull;</BreadcrumbItem>;
  };

  return <BreadcrumbContainer className={"breadcrumb-container"}>{generateBreadcrumbList()}</BreadcrumbContainer>;
};
export default QuizBreadcrumb;
