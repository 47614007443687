import React, { useEffect, useRef, useState, useContext } from "react";
import { BaseActivityProps } from "../Activity/Activity.component";
import Title from "../Title/Title.component";
import { ActivityFakeQuestion } from "../../types";
import { StandardButton } from "..";
import AnswerButton from "./AnswerButton.component";
import { observer } from "mobx-react";
import {
  FakeQuestionContainer,
  FakeQuestionTextContainer,
  FakeQuestionText,
  FakeQuestionAnswerListContainer,
  FakeQuestionAnswerItemContainer,
  FakeQuestionAnswerContainer,
  FakeQuestionTitleContainer,
} from "./ActivityFakeQuestion.style";
import { StoreContext } from "../../context";
interface Props extends BaseActivityProps {
  activity: ActivityFakeQuestion;
}

const ActivityFakeQuestionComponent: React.FC<Props> = (props) => {
  const ref = useRef<HTMLUListElement>(null);
  const { core, store } = useContext(StoreContext);
  const texts = store.interfaceText;
  const [answerListHeight, setanswerListPostion] = useState(0);
  const [checkedAnswerIndex, setCheckedAnswerIndex] = useState(-1);
  const isTracked = props.activity.isNeededForCompletion || store.currentBox?.lockedByThisActivity;

  const setActivityCompletionAccordingToCompletionCondition = () => {
    if (isTracked && props.activity.tracking) {
      if (props.activity.tracking.completeCondition === "open") {
        void core.setInteractionCompletion(props.activity.id);
      }
    }
  };

  useEffect(() => setActivityCompletionAccordingToCompletionCondition(), []);

  useEffect(() => {
    if (answerListHeight === 0) {
      setanswerListPostion(ref.current ? ref.current.offsetHeight : 0);
    }
  }, [ref]);

  const onValidate = () => {
    if (props.goNext) props.goNext();
  };
  const onChangeAnswer = (index: number) => (checked: boolean) => {
    setCheckedAnswerIndex(checked ? index : -1);
  };
  return (
    <FakeQuestionContainer className="fake-question-container" id={`fake-question_${props.activity.id}`}>
      {props.activity.title && (
        <FakeQuestionTitleContainer className="fake-question-title-container">
          <Title level={2} title={props.activity.title} />
        </FakeQuestionTitleContainer>
      )}
      <FakeQuestionTextContainer className="fake-question-text-container">
        <FakeQuestionText dangerouslySetInnerHTML={{ __html: props.activity.question }}></FakeQuestionText>
      </FakeQuestionTextContainer>
      <FakeQuestionAnswerContainer bg={props.activity.answerBlockBackground} className="fake-answer-container">
        <FakeQuestionAnswerListContainer
          ref={ref}
          answerListHeight={answerListHeight}
          className="fake-answer-list-container"
        >
          {props.activity.answerList.map((answer, index) => (
            <FakeQuestionAnswerItemContainer
              color={answer.color}
              answersLenght={props.activity.answerList.length}
              key={index}
              className={`answer-item ${checkedAnswerIndex === index ? "checked" : ""}`}
            >
              <AnswerButton
                checked={checkedAnswerIndex === index}
                onChange={onChangeAnswer(index)}
                id={index}
                answerText={answer.text}
              />
            </FakeQuestionAnswerItemContainer>
          ))}
        </FakeQuestionAnswerListContainer>
        <StandardButton onClick={onValidate}>{texts.submit}</StandardButton>
      </FakeQuestionAnswerContainer>
    </FakeQuestionContainer>
  );
};

export default observer(ActivityFakeQuestionComponent);
