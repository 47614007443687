import { createTheme } from "@material-ui/core";
import { styled } from "../../theme";
import { Link } from "react-router-dom";

export const BoxItemStyle = styled(Link).withConfig<{ boxId: string }>({
  shouldForwardProp: (props) => props !== "boxId",
})`
  position: relative;
  appearance: none;
  width: 100%;
  aspect-ratio: 1;
  gap: 1em;
  box-sizing: border-box;
  display: flex;
  border: inherit;
  flex-direction: column;
  justify-content: center;
  text-align: center;
  border-radius: 1em;
  padding: 0.9em;
  background: ${({ theme, boxId }) =>
    (boxId && theme.color.boxObject[`${boxId}`] && theme.color.boxObject[`${boxId}`].background) ?? "#fff"};
  background-size: cover;
  background-position: center;
  text-transform: none;
  &.disabled {
    cursor: inherit;
    pointer-events: none;
    padding: 1.5em;
  }
  &:not(.disabled)::before {
    z-index: 500;
    border-radius: 1em;
    content: "";
    width: 100%;
    height: 100%;
    position: absolute;
    top: 0;
    left: 0;
    opacity: 0;
    transition: opacity 0.2s ease-in-out;
    background-color: rgba(255, 255, 255, 0.2);
  }
  &:not([disabled]):hover::before {
    display: block;
    opacity: 1;
    box-shadow: 0px 0px 7px 3px rgba(0, 0, 0, 0.2);
  }
  &:focus:not(:focus-visible) {
    border: none;
    outline: none;
    box-shadow: inherit;
  }
  &:focus-visible {
    border: none;
    outline: none;
    box-shadow: 0px 0px 0px 0.5em ${({ theme }) => theme.color.focus};
  }
  ${({ theme }) => theme.breakpoints.down("xs")} {
    flex-direction: row;
  }
`;

export const BoxItemTitleContainerStyle = styled.div`
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  box-sizing: border-box;

  ${({ theme }) => theme.breakpoints.down("sm")} {
    max-height: 100%;
  }
`;

export const BoxItemIconContainerStyle = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 100%;
  flex: 0 1 40%;
  box-sizing: border-box;
  ${({ theme }) => theme.breakpoints.down("xs")} {
    display: none;
  }

  // Maybe not the best approach for boxes with icons only. Open to proposal . ABEDOS - 2023/08/03
  &[data-alone="true"] {
    flex: 0 0 60%;
  }
`;

export const BoxItemIconStyle = styled.span<{ bg: string; fullsize: boolean }>`
  width: 100%;
  height: 100%;
  background-image: ${({ bg }) => `url("${bg}")`};
  background-size: ${({ fullsize }) => `${fullsize ? "contain" : "contain"}`};
  background-repeat: no-repeat;
  background-position: center;
  display: block;
`;
export const BoxItemConnector = styled.span`
  background-color: ${({ theme }) => theme.color.boxConnector};
  position: absolute;
  z-index: -1;
  &.direction_left,
  &.direction_right {
    top: 45%;
    width: 30%;
    min-height: 10%;
  }
  &.direction_left {
    left: -20%;
  }
  &.direction_right {
    right: -20%;
  }
  &.direction_up,
  &.direction_down {
    left: 45%;
    width: 10%;
    min-height: 30%;
  }
  &.direction_up {
    top: -20%;
  }
  &.direction_down {
    bottom: -20%;
  }

  ${({ theme }) => theme.breakpoints.down("sm")} {
    display: none;
  }
`;
