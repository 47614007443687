import React, { useContext } from "react";
import { ActivityKnowledgeMapping, ContentBox } from "../../types";
import {
  Container,
  KnowledgeMappingTitleContainer,
  InformationBlock,
  InformationWrapper,
  SkippedTimeBlock,
  SkippedTimeText,
  SkippedTimeWrapper,
  KeypointBlock,
  StatusContainer,
  DetailText,
  SkippedTimeProgressBar,
  ButtonContainer,
  ButtonTitleContainerStyle,
  KeypointBottomBlock,
  StartButton,
  LegendWrapper,
  LegendItem,
  LegendBulletPoint,
} from "./ActivityKnowledgeMapping.style";
import { BaseActivityProps } from "../Activity/Activity.component";
import { Title } from "..";
import { StoreContext } from "../../context";
import { observer } from "mobx-react";
import { findItemAndIndexById, getKeypointBoxList, getRawSkillTesterObjectiveList } from "../../utils/utils";
import KeypointItem from "./KeypointItem.component";
import GLOBAL_PARAMS from "../../content/globalParams";
import { CircularProgressbar } from "react-circular-progressbar";
import "react-circular-progressbar/dist/styles.css";
import { Grid } from "@material-ui/core";
interface Props extends BaseActivityProps {
  activity: ActivityKnowledgeMapping;
}

const ActivityKnowledgeMappingComponent: React.FC<Props> = (props) => {
  const { store } = useContext(StoreContext);
  const texts = store.interfaceText.knowledgeMapping;

  const keypointList = getKeypointBoxList(store.content.boxList);
  const totalModuleDuration = store.totalModuleDuration;
  const userSkippedTime = store.userSkippedTime;
  const userRemaintingTime = totalModuleDuration - store.userSkippedTime;
  const sortedKeypointList = keypointList;
  const wideDisplay = {
    informationBlockWidth: 100,
    keypointBlockWidth: 100,
    squareShape: false,
  };
  const squareDisplay = {
    informationBlockWidth: 100,
    keypointBlockWidth: 100,
    squareShape: false,
  };
  const displayMode = keypointList.length > 8 ? wideDisplay : squareDisplay;
  const onStartContext = store.currentBox?.id === GLOBAL_PARAMS.onStartContent;

  const isSkillTested = (idBox: string): boolean => {
    const rawObjectiveList = getRawSkillTesterObjectiveList(props.activity.relatedActivityId);
    const result = rawObjectiveList.find((objective) => objective === idBox);
    return result !== undefined;
  };
  const computeUserSkippedTimeInPercent = (totalModuleDuration: number, userSkippedTime: number): number => {
    let skippedValue = 0;
    if (totalModuleDuration) {
      skippedValue = Math.round((userSkippedTime / totalModuleDuration) * 100);
    }
    return skippedValue;
  };

  const getKeypointInitialStatus = (
    box: ContentBox,
  ): { status: "passed" | "failed" | "partial" | "not-implemented"; progressValue: number; locked: boolean } => {
    const skillTesterResult = findItemAndIndexById(box.id, store.objectiveList);

    if (!isSkillTested(box.id)) {
      return {
        status: "not-implemented",
        progressValue: 0,
        locked: false,
      };
    }
    if (skillTesterResult && box.keypoint?.threshold) {
      if (skillTesterResult.item.scoreMin && skillTesterResult.item.scoreMin >= box.keypoint?.threshold) {
        return {
          status: "passed",
          progressValue: 100,
          locked: false,
        };
      }
      if (skillTesterResult.item.scoreMin && skillTesterResult.item.scoreMin < box.keypoint.threshold) {
        return {
          status: "partial",
          progressValue: 66,
          locked: true,
        };
      }
      if (skillTesterResult.item.scoreMin && !skillTesterResult.item.scoreMin) {
        return {
          status: "failed",
          progressValue: 33,
          locked: true,
        };
      }
    }
    return {
      status: "failed",
      progressValue: 30,
      locked: true,
    };
  };
  return (
    <Container className="activity-knowledge-container">
      <InformationBlock size={displayMode.informationBlockWidth}>
        <InformationWrapper className="information-wrapper">
          <StatusContainer className="information-status">
            <Title level={2} title={texts ? texts.skillTesterStatus : ""} />
          </StatusContainer>
          {texts?.keypointDetails ? (
            <DetailText className="information-detail">{texts.keypointDetails}</DetailText>
          ) : null}
          <SkippedTimeBlock className="skipped-time-block">
            <SkippedTimeWrapper>
              <SkippedTimeText>
                {userSkippedTime > 0 ? texts?.skippedTimeTitle(userSkippedTime) : texts?.noSkippedTimeTitle}
              </SkippedTimeText>
              <SkippedTimeProgressBar>
                <CircularProgressbar
                  strokeWidth={15}
                  value={100 - computeUserSkippedTimeInPercent(totalModuleDuration, userSkippedTime)}
                  text={`${userRemaintingTime} min`}
                />
              </SkippedTimeProgressBar>
            </SkippedTimeWrapper>
            {/*  {userSkippedTime > 0 && (
              <SkippedTimeInMinute>
                Or <span>{computeUserSkippedTimeInPercent(totalModuleDuration, userSkippedTime)}</span> % saved
              </SkippedTimeInMinute>
            )} */}
          </SkippedTimeBlock>
        </InformationWrapper>
      </InformationBlock>
      <KeypointBlock size={displayMode.keypointBlockWidth}>
        {props.activity.title && (
          <KnowledgeMappingTitleContainer className="knowledge-map-title-container">
            <Title level={2} title={props.activity.title} />
          </KnowledgeMappingTitleContainer>
        )}
        <Grid container component={"ul"}>
          {sortedKeypointList.map((item) => {
            return (
              <KeypointItem
                key={item.id}
                box={item}
                initialStatus={getKeypointInitialStatus(item)}
                squareShape={displayMode.squareShape}
              />
            );
          })}
        </Grid>
        <KeypointBottomBlock>
          <LegendWrapper>
            <LegendItem className="legend-item-incorrect">
              <LegendBulletPoint className="bulletpoint-incorrect" />
              {texts?.legend.incorrect}
            </LegendItem>
            <LegendItem className="legend-item-partial">
              <LegendBulletPoint className="bulletpoint-partial" />
              {texts?.legend.partial}
            </LegendItem>
            <LegendItem className="legend-item-correct">
              <LegendBulletPoint className="bulletpoint-correct" />
              {texts?.legend.correct}
            </LegendItem>
          </LegendWrapper>
          {
            <ButtonContainer>
              <StartButton onClick={props.goNext ? props.goNext : props.onClickClose}>
                {texts && (
                  <ButtonTitleContainerStyle>
                    {/* <Icon onStartIcon={onStartContext} /> */}
                    <Title fontColor={"white"} level={2} title={onStartContext ? texts?.start : texts?.resume} />
                  </ButtonTitleContainerStyle>
                )}
              </StartButton>
            </ButtonContainer>
          }
        </KeypointBottomBlock>
      </KeypointBlock>
    </Container>
  );
};

export default observer(ActivityKnowledgeMappingComponent);
