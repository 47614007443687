import { styled } from "../../theme";

export const Container = styled.div`
  max-width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  height: 100%;
  margin: auto;
`;

export const ImageContainer = styled.div<{ usableHeight?: number }>`
  max-height: calc(100% - ${({ usableHeight }) => usableHeight}px);
  box-sizing: border-box;
  display: flex;
  justify-content: center;
`;
export const ImageButtonContainer = styled.div`
  min-height: 10%;
  padding-top: 0.5em;
  padding-bottom: 0.5em;
`;

export const ImageTitleContainer = styled.div`
  min-height: 10%;
  ${({ theme }) => theme.breakpoints.down("sm")} {
    min-height: 3em;
  }
`;
