import frInterface from "./fr_interface";
import DUMMY_INTERFACE from "./dummy_interface";
/* import enInterface from "./en"; */
import { InterfaceText } from "../types";
import { LANGUAGES } from "../constants";

const translatedInterface: { [key in LANGUAGES]: InterfaceText } = {
  [LANGUAGES.FR]: process.env.REACT_APP_DUMMY_MODE === "true" ? DUMMY_INTERFACE : frInterface,
  /* [LANGUAGES.EN]: enInterface, */
} as const;

export default translatedInterface;
