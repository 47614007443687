import { useEffect } from "react";
import { useMatomo } from "@datapunt/matomo-tracker-react";

interface Props {
  documentTitle?: string;
  pageUrl?: string;
}

const useMatomoTracking = (props: Props) => {
  const { trackPageView } = useMatomo();
  useEffect(() => {
    trackPageView({
      documentTitle: props.documentTitle,
      href: props.pageUrl,
    });
  }, []);

  return;
};

export default useMatomoTracking;
