import React, { useContext, useEffect } from "react";
import { StandardButton, MediaContainer } from "..";
import { ActivityMultimedia } from "../../types";
import { ColumnContainer, Column, MultimediaTitleContainer, Container } from "./ActivityMultimedia.style";
import { BaseActivityProps } from "../Activity/Activity.component";
import { observer } from "mobx-react";
import { StoreContext } from "../../context";
import Title from "../Title/Title.component";
import { isMobile } from "../../utils/utils";

interface Props extends BaseActivityProps {
  activity: ActivityMultimedia;
}

const ActivityMultimediaComponent: React.FC<Props> = (props) => {
  const { core, store } = useContext(StoreContext);
  const texts = store.interfaceText;
  const isTracked = props.activity.isNeededForCompletion || store.currentBox?.lockedByThisActivity;
  const onValidate = () => {
    if (props.goNext) props.goNext();
  };

  const setActivityCompletionAccordingToCompletionCondition = async () => {
    if (isTracked && props.activity.tracking) {
      if (props.activity.tracking.completeCondition === "open") {
        await core.setInteractionCompletion(props.activity.id);
      }
    }
    if (props.nextBehaviour === "close" && props.onEnd) {
      props.onEnd();
    }
  };

  useEffect(() => {
    void setActivityCompletionAccordingToCompletionCondition();
  }, []);

  return (
    <Container className="multimedia-container">
      {props.activity.title && (
        <MultimediaTitleContainer className="multimedia-title-container">
          <Title level={2} title={props.activity.title} />
        </MultimediaTitleContainer>
      )}
      <ColumnContainer reverse={props.activity.type === "multimedia2"}>
        <Column>
          <div
            id={`multimedia_${props.activity.id}`}
            className={`${props.activity.type} rich-text-container`}
            dangerouslySetInnerHTML={{ __html: props.activity.text }}
          />
          {props.goNext && !isMobile() && (
            <StandardButton onClick={onValidate}>
              {props.activity.forceButtonText && <span>{props.activity.forceButtonText}</span>}
              {!props.activity.forceButtonText && (
                <span>{props.nextBehaviour === "close" ? texts.close : texts.next}</span>
              )}
            </StandardButton>
          )}
        </Column>
        <Column className="multimedia-media-column">
          <MediaContainer media={props.activity.media} />
        </Column>
        {props.goNext && isMobile() && (
          <Column className="navigation-column">
            <StandardButton onClick={onValidate}>
              {props.activity.forceButtonText && <span>{props.activity.forceButtonText}</span>}
              {!props.activity.forceButtonText && (
                <span>{props.nextBehaviour === "close" ? texts.close : texts.next}</span>
              )}
            </StandardButton>
          </Column>
        )}
      </ColumnContainer>
    </Container>
  );
};

export default observer(ActivityMultimediaComponent);
