import React from "react";
import { MainMenuStyle, MainMenuWrapper, ImgContainer } from "./MainMenu.style";
import { Box } from "../../types";
import BoxList from "../BoxList/BoxList.component";

interface Props {
  boxList: Box[];
}

const MainMenu = React.forwardRef<HTMLDivElement, Props>((props, ref) => {
  return (
    <MainMenuStyle className={"main-menu"}>
      <ImgContainer></ImgContainer>
      <MainMenuWrapper ref={ref} role="navigation" id={"main-menu-wrapper"}>
        <div style={{ padding: "0.5em" }}>
          <BoxList boxList={props.boxList} />
        </div>
      </MainMenuWrapper>
    </MainMenuStyle>
  );
});

MainMenu.displayName = "main-menu";

export default MainMenu;
