import { styled } from "../../theme";

export const StandardCloseButtonStyle = styled.button`
  border: none;
  background-color: ${({ theme }) => theme.color.boxItem};
  background-image: url(${({ theme }) => theme.icons.closeCross});
  background-position: center;
  background-size: 50%;
  background-repeat: no-repeat;
  width: 2em;
  height: 2em;
  border-radius: 2em;
  transition: all 0.2s;
  cursor: pointer;
  box-sizing: border-box;
  :hover,
  :focus {
    transform: rotate(180deg);
  }
  :focus:not(:focus-visible) {
    border: none;
    outline: none;
    box-shadow: inherit;
  }
  :focus-visible {
    border: none;
    outline: none;
    box-shadow: 0px 0px 0px 0.5em ${({ theme }) => theme.color.focus};
  }
  ${({ theme }) => theme.breakpoints.down("sm")} {
  }
`;
