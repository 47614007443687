import React, { useContext, useEffect, useRef, useState } from "react";
import { BaseActivityProps } from "../Activity/Activity.component";
import Title from "../Title/Title.component";
import { ActivityImage } from "../../types";
import { MediaContainer, StandardButton } from "..";
import { Container, ImageButtonContainer, ImageContainer, ImageTitleContainer } from "./ActivityImage.style";
import { observer } from "mobx-react";
import { StoreContext } from "../../context";
import { store } from "../../state";

interface Props extends BaseActivityProps {
  activity: ActivityImage;
}

const ActivityImageComponent: React.FC<Props> = (props) => {
  const ref = useRef<HTMLDivElement>(null);
  const buttonRef = useRef<HTMLDivElement>(null);
  const { core } = useContext(StoreContext);
  const texts = store.interfaceText;
  const [titleContainerHeight, setTitleContainerHeight] = useState(0);
  const [buttonContainerHeight, setButtonContainerHeight] = useState(0);
  const isTracked = props.activity.isNeededForCompletion || store.currentBox?.lockedByThisActivity;
  
  const setActivityCompletionAccordingToCompletionCondition = () => {
    if (isTracked && props.activity.tracking) {
      if (props.activity.tracking.completeCondition === "open") {
        void core.setInteractionCompletion(props.activity.id);
      }
    }
  };

  useEffect(() => setActivityCompletionAccordingToCompletionCondition(), []);

  const onValidate = () => {
    if (props.goNext) props.goNext();
  };
  useEffect(() => {
    if (titleContainerHeight === 0) {
      setTitleContainerHeight(ref.current ? ref.current.offsetHeight : 0);
    }
  }, [ref]);
  useEffect(() => {
    if (buttonContainerHeight === 0) {
      setButtonContainerHeight(buttonRef.current ? buttonRef.current.offsetHeight : 0);
    }
  });

  return (
    <Container className="activity-image_container">
      {props.activity.title && (
        <ImageTitleContainer ref={ref} className="image-title-container">
          <Title level={2} title={props.activity.title} />
        </ImageTitleContainer>
      )}
      <ImageContainer usableHeight={titleContainerHeight + buttonContainerHeight} className="image-container">
        <MediaContainer media={props.activity.image} />
      </ImageContainer>
      {props.goNext && (
        <ImageButtonContainer ref={buttonRef}>
          <StandardButton onClick={onValidate}>
            {props.nextBehaviour === "close" ? texts.close : texts.next}
          </StandardButton>
        </ImageButtonContainer>
      )}
    </Container>
  );
};

export default observer(ActivityImageComponent);
