import React, { useContext, useEffect, useState } from "react";
import { BaseActivityProps } from "../Activity/Activity.component";
import Title from "../Title/Title.component";
import { StandardButton } from "..";
import { ActivityQuiz } from "../../types";
import QuizView, { AnswerResult, Question } from "@upandgo/react-quiz";
import {
  Container,
  QuizTitleContainer,
  QuizIntroIcon,
  QuizIntroIconContainer,
  QuizIntroContainer,
  QuizIntroTitleContainer,
  QuizIntroRulesText,
  QuizIntroObjectiveText,
} from "./ActivityQuiz.style";
import { theme } from "../../theme";
import { StoreContext } from "../../context";
import deepClone from "lodash/cloneDeep";
import { toJS } from "mobx";
import { getHeritedQuestionList } from "../../utils/utils";

interface Props extends BaseActivityProps {
  activity: ActivityQuiz;
}

const ActivityQuizComponent: React.FC<Props> = (props) => {
  const { store, core } = useContext(StoreContext);
  const [clonedQuiz, setClonedQuiz] = useState(deepClone(toJS(props.activity.quiz)));
  const texts = store.interfaceText;
  let init_step = 0;
  if (!props.activity.intro) {
    init_step = 1;
  }
  const heritedQuestionList: Question[] = props.activity.relatedSkillTesterQuizIdList
    ? getHeritedQuestionList(props.activity.relatedSkillTesterQuizIdList)
    : [];

  useEffect(() => {
    setClonedQuiz({ ...clonedQuiz, questionList: [...clonedQuiz.questionList, ...heritedQuestionList] });
    core.resetQuizAnswerResult();
  }, []);

  const [step, setStep] = useState(init_step);

  const onAnswerResult = async (result: AnswerResult) => {
    if (store.currentBox) {
      await core.setQuizAnswerResult({
        quiz: clonedQuiz,
        answer: result,
        threshold: (props.activity.validatingThreshold || 0) + heritedQuestionList.length,
        setAsGlobalScore: props.activity.tracking?.setAsGlobalScore,
      });
    }
  };
  const onNext = (index: number) => {
    if (index === clonedQuiz.questionList.length - 1) {
      if (props.goNext) props.goNext();
    }
  };
  const skipIntro = () => {
    setStep(1);
  };

  return (
    <Container className="quiz-main-container">
      {props.activity.title && (
        <QuizTitleContainer className="quiz-title-container">
          <Title level={2} title={props.activity.title} />
        </QuizTitleContainer>
      )}
      {step === 0 && props.activity.intro && (
        <Container className="quiz-intro-main-container">
          {props.activity.intro.icon && (
            <QuizIntroIconContainer className={"quiz-intro-icon-container"}>
              <QuizIntroIcon bg={props.activity.intro.icon} className={"quiz-intro-icon"}></QuizIntroIcon>
            </QuizIntroIconContainer>
          )}
          {props.activity.intro.title && (
            <QuizIntroTitleContainer className="quiz-intro-title">
              <Title fontColor={props.activity.intro.color} level={1} title={props.activity.intro.title} />
            </QuizIntroTitleContainer>
          )}
          <QuizIntroContainer className="quiz-intro-container">
            <Container>
              <Container className="quiz-intro-rules">
                <QuizIntroRulesText>{props.activity.intro.rules}</QuizIntroRulesText>
              </Container>
              <Container className="quiz-intro-objective">
                <QuizIntroObjectiveText>{props.activity.intro.objective}</QuizIntroObjectiveText>
              </Container>
              <StandardButton className="button-start-quiz" onClick={skipIntro}>
                {props.activity.intro.startQuiz}
              </StandardButton>
            </Container>
          </QuizIntroContainer>
        </Container>
      )}
      {step === 1 && (
        <QuizView
          theme={{
            primaryColor: theme.color.mainTitle,
            secondaryColor: theme.color.mainTitle,
            textColor: theme.color.text,
            textAnswerColor: theme.color.text,
            validColor: theme.color.quizValid,
            invalidColor: theme.color.quizInvalid,
          }}
          text={{
            next: store.interfaceText.next,
            validate: store.interfaceText.validate,
             correct: "correct",
            incorrect: "incorrect", 
          }}
          onAnswerQuestion={onAnswerResult} 
          onNextQuestion={onNext}
          questionAggregate={{
            ...clonedQuiz,
            text: { next: texts.next, validate: texts.validate,  correct: "correct", incorrect: "incorrect"  },
          }}
          startingIndex={0}
        />
      )}
      {step === 2 && <Container></Container>}
      {step === 3 && <Container></Container>}
      {step === 4 && <Container></Container>}
    </Container>
  );
};

export default ActivityQuizComponent;
