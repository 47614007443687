import { styled } from "../../theme";

export const AnswerButtonLabelStyle = styled.label`
  position: relative;
  font-size: 1rem;
`;
export const HiddenCheckboxStyle = styled.input`
  position: absolute;
  top: 0;
  opacity: 0;
  margin: 0;
  padding: 0;
  width: 100%;
  height: 100%;
  &:hover {
    cursor: pointer;
  }
`;
