import React from "react";
import { ContentBox } from "../../types";
import Title from "../Title/Title.component";
import { DescriptionContainer, KeypointItemContainer, KeypointOverlay, TitleContainer } from "./KeypointItem.style";
import LinearProgress from "@material-ui/core/LinearProgress";
import { Grid } from "@material-ui/core";

interface Props {
  box: ContentBox;
  initialStatus: {
    status: "passed" | "failed" | "partial" | "not-implemented";
    progressValue: number;
    locked?: boolean;
  };
  squareShape: boolean;
}

const KeypointItem: React.FC<Props> = (props) => {
  return (
    <Grid item xs={6} md={4} lg={2}>
      <KeypointItemContainer className={`keypoint-item-container ${props.initialStatus.locked ? "locked" : ""}`}>
        {props.initialStatus.locked && <KeypointOverlay />}
        <TitleContainer>
          {props.box.title && <Title level={2} title={props.box.title} boxId={props.box.id} />}
        </TitleContainer>
        <DescriptionContainer>
          {props.box.keypoint?.description ? (
            <p
              dangerouslySetInnerHTML={{
                __html: props.box.keypoint?.description,
              }}
            />
          ) : null}
        </DescriptionContainer>
        <LinearProgress
          variant="determinate"
          value={props.initialStatus.progressValue}
          color="primary"
          className={`progress-bar ${props.initialStatus.status}`}
        />
      </KeypointItemContainer>
    </Grid>
  );
};

export default KeypointItem;
