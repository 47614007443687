import { styled } from "./theme";

export const AppStyle = styled.div`
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  font-family: ${({ theme }) => theme.font.mainFont.name};
  font-weight: normal;
  font-style: normal;
  ${({ theme }) => theme.breakpoints.down("sm")} {
    height: inherit;
  }
`;
interface overlayProps {
  overlayState: boolean;
}
interface isAppReadyProps {
  isAppReady: boolean;
}
export const Overlay = styled.div<overlayProps>`
  z-index: 10;
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  display: ${({ overlayState }) => `${overlayState ? "block" : "none"}`};
  background-color: ${({ theme }) => theme.color.overlayColor};
  animation: fadeIn linear 0.2s;
  @keyframes fadeIn {
    0% {
      opacity: 0;
    }
    100% {
      opacity: 1;
    }
  }
  ${({ theme }) => theme.breakpoints.down("sm")} {
    height: inherit;
    min-height: 100%;
  }
`;
export const LoadingScreen = styled.div<isAppReadyProps>`
  z-index: 10;
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  display: ${({ isAppReady }) => `${isAppReady ? "none" : "block"}`};
  background-color: ${({ theme }) => theme.color.overlayColor};
  animation: fadeIn linear 0.2s;
  @keyframes fadeIn {
    0% {
      opacity: 0;
    }
    100% {
      opacity: 1;
    }
  }
  ${({ theme }) => theme.breakpoints.down("sm")} {
    height: inherit;
    min-height: 100%;
  }
`;

export const MainContainer = styled.main<{ fullscreen: boolean }>`
  min-height: ${({ fullscreen }) => (fullscreen ? "100vh" : "73vh")};
  ${({ theme }) => theme.breakpoints.down("sm")} {
    flex: 1 1 auto;
    height: initial;
    min-height: initial;
  }
`;

export const ContainerInModal = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  position: relative;
`;

export const ModalCloseButtonContainer = styled.div`
  top: 0.1em;
  right: 0.1em;
  z-index: 10;
  position: absolute;
  ${({ theme }) => theme.breakpoints.down("sm")} {
  }
`;
