import React, { useContext, useEffect, useState } from "react";
import { ActivityQuizResult } from "../../types";
import {
  Container,
  ColumnContainer,
  Column,
  ScoreNumber,
  ScoreUnit,
  ScoreContainer,
  QuizResultTextContainer,
  QuizResultButtonContainer,
  QuizResultTitleContainer,
  ResultCorrectionItemContainer,
  QuizStatusText,
  QuizStatusContainer,
  ThresholdText,
  DetailText,
  ScoreLabel,
} from "./ActivityQuizResult.style";
import { BaseActivityProps } from "../Activity/Activity.component";
import { Title, StandardButton } from "..";
import { StoreContext } from "../../context";
import { observer } from "mobx-react";
import { useHistory } from "react-router-dom";
import ResultCorrectionItem from "../ResultCorrectionItem/ResultCorrectionItem";
import { isQuestionValid } from "@upandgo/react-quiz";
import ROUTES from "../../routes";

interface Props extends BaseActivityProps {
  activity: ActivityQuizResult;
}

const formatScoreUnit = ({
  quizLength,
  scoreExpression,
}: {
  scoreExpression: "percentage" | "questionLenght";
  quizLength: number;
}) => (scoreExpression === "percentage" ? "%" : `/${quizLength}`);

const ActivityQuizResultComponent: React.FC<Props> = (props) => {
  const { core, store } = useContext(StoreContext);
  const texts = store.interfaceText;
  const result = store.quizResults[props.activity.relatedQuiz];
  const success = store.quizResults[props.activity.relatedQuiz].successStatus === "passed";
  const score = store.quizResults[props.activity.relatedQuiz].numberOfRightAnswers;

  const history = useHistory();
  const [details, setDetail] = useState<boolean>(false);
  const [certificate, setCertificate] = useState<boolean>(false);

  useEffect(() => {
    core.setInteractionResult(props.activity.relatedQuiz, success ? "correct" : "incorrect");
  }, []);

  const reDoQuiz = () => {
    const idBox = store.currentBox?.id;
    if (idBox) {
      history.push(`${ROUTES.ACTIVITY_LIST.path}/${idBox}`);
    }
  };

  const onSubmitIdentity = (identity: { firstName: string; lastName: string }) => {
    if (props.activity.shouldSendDataTo) {
      void core.sendSCORMStatsToServer({ url: props.activity.shouldSendDataTo, identity });
    }
  };

  const getAnswerText = (question_id: string, answer_id: string) => {
    const answer_text = result.quiz.questionList
      .find((q) => q.id === question_id)
      ?.answerList.find((a) => a.id === answer_id);
    if (answer_text) {
      return { id: answer_id, text: answer_text.text };
    } else {
      return { id: answer_id, text: answer_id };
    }
  };
  const getQuestionAnswer = (question_id: string) => {
    const answer = result.answerList.find((e) => e.idQuestion === question_id);
    if (answer) {
      return answer.idAnswers;
    } else {
      return [];
    }
  };

  const getAllAnswer = () => {
    const answer_array: Array<Array<{ id: string; text: string }>> = [];
    result.quiz.questionList.forEach((q) => {
      const answer = getQuestionAnswer(q.id);
      const answer_full: Array<{ id: string; text: string }> = [];
      answer.forEach((a) => {
        answer_full.push(getAnswerText(q.id, a));
      });
      answer_array.push(answer_full);
    });
    return answer_array;
  };

  const seeDetails = () => {
    setDetail(true);
  };

  const getCertificate = () => {
    setCertificate(true);
  };

  const closeDetails = () => {
    setDetail(false);
  };

  return (
    <Container>
      {props.activity.title && (
        <QuizResultTitleContainer className="quiz-result-title-container">
          <Title level={2} title={props.activity.title} />
        </QuizResultTitleContainer>
      )}
      <ColumnContainer className={"result-container"} reverse={false}>
        {!certificate && !details && (
          <Column className="column-result-content" detail={details} fullWidth={true}>
            <QuizStatusContainer className="quiz-status-container">
              <QuizStatusText className="quiz-status-text">
                {success ? props.activity.success.status : props.activity.fail.status}
              </QuizStatusText>
            </QuizStatusContainer>

            <QuizResultTextContainer className="result-text-container"></QuizResultTextContainer>
            <ScoreContainer className="score-container">
              <ScoreLabel className="score-text">{`${texts.score}`}&nbsp;</ScoreLabel>
              <div className="score-number-unit">
                <ScoreNumber className="score-number">
                  {props.activity.scoreExpression === "questionLenght"
                    ? score
                    : Math.round(
                        (score / store.quizResults[props.activity.relatedQuiz].quiz.questionList.length) * 100,
                      )}
                </ScoreNumber>
                <ScoreUnit className="score-unit">
                  {formatScoreUnit({
                    quizLength: store.quizResults[props.activity.relatedQuiz].quiz.questionList.length,
                    scoreExpression: props.activity.scoreExpression,
                  })}
                </ScoreUnit>
              </div>
            </ScoreContainer>
            <ThresholdText className="result-thresold">{texts.thresoldScore + " %"}</ThresholdText>
            {!details && (
              <DetailText className="result-detail" onClick={seeDetails}>
                {texts.seeDetail}
              </DetailText>
            )}
            {!details && (
              <QuizResultButtonContainer className="result-button-container">
                <StandardButton className="result-button-home" onClick={props.onClickClose}>
                  {texts.backToHome}
                </StandardButton>
                {(!props.activity.success.certificate || !success) && (
                  <StandardButton className="result-button-tryagain" onClick={reDoQuiz}>
                    {texts.tryAgain}
                  </StandardButton>
                )}
                {props.activity.success.certificate && success && (
                  <StandardButton className="result-button-cert" onClick={getCertificate}>
                    {texts.getCertificate}
                  </StandardButton>
                )}
              </QuizResultButtonContainer>
            )}
          </Column>
        )}
        {details && (
          <Column className="column-result-content" detail={false} fullWidth={true}>
            <ResultCorrectionItemContainer>
              {result.quiz.questionList.map((question, index) => {
                return (
                  <ResultCorrectionItem
                    key={question.id}
                    index={index}
                    question={question.question.length > 0 ? question.question : question.context?.title || ""}
                    userAnswers={getAllAnswer()[index].map((answer) => answer.text)}
                    validAnswers={question.answerList.filter((answer) => answer.valid).map((answer) => answer.text)}
                    isValid={isQuestionValid(question)}
                  />
                );
              })}
            </ResultCorrectionItemContainer>
            <StandardButton onClick={closeDetails}>{texts.close}</StandardButton>
          </Column>
        )}
      </ColumnContainer>
    </Container>
  );
};

export default observer(ActivityQuizResultComponent);
