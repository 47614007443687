import React, { useContext, useEffect } from "react";
import { observer } from "mobx-react-lite";
import { Redirect, useParams } from "react-router-dom";

import { StoreContext } from "../context";
import { SubMenu } from "../components";
import { Modal } from "@material-ui/core";

interface Props {
  parent: HTMLDivElement | null;
}

const BoxPage: React.FC<Props> = (props) => {
  const { parent } = props;
  const { store, core } = useContext(StoreContext);
  const { boxId } = useParams<{ boxId: string }>();

  const currentBox = core.getBox(boxId, store.content.boxList);

  useEffect(() => {
    core.setCurrentBox(currentBox);

    return () => core.setCurrentBox(undefined);
  }, [boxId]);

  if (!currentBox || currentBox.type !== "menu") return <Redirect to={"/"} />;

  return (
    <Modal open container={parent} style={{ position: "absolute", overflow: "auto" }} disableScrollLock>
      {/* This div is mandatory because Modal's first child should be able to hold a ref. Other solution is to ForwardRef on SebMenu. ABEDOS - 2023/08/03 */}
      <div>
        <SubMenu parentBox={currentBox} />
      </div>
    </Modal>
  );
};

export default observer(BoxPage);
