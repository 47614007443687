import { Content } from "../types";
import DUMMY_CONTENT from "./dummy_content";
import DUMMY_GLOBAL_PARAMS from "./dummy_globalParams";
import frContent from "./fr_SDH_diversite";

import { LANGUAGES } from "../constants";
import globalParams from "./globalParams";

const translatedContent: { [key in LANGUAGES]: Content } = {
  [LANGUAGES.FR]: process.env.REACT_APP_DUMMY_MODE === "true" ? DUMMY_CONTENT : frContent,
  /* [LANGUAGES.EN]: enContent, */
} as const;
const GLOBAL_PARAMS = process.env.REACT_APP_DUMMY_MODE === "true" ? DUMMY_GLOBAL_PARAMS : globalParams;

export { translatedContent, GLOBAL_PARAMS };
