import React, { useContext, useEffect } from "react";
import { observer } from "mobx-react-lite";
import { useParams } from "react-router-dom";

import { StoreContext } from "../context";
import { ActivityList } from "../components";
import { Modal } from "@material-ui/core";

const ActivityLockPage: React.FC = () => {
  const { store, core } = useContext(StoreContext);
  const { boxId } = useParams<{ boxId: string }>();

  const currentBox = core.getBox(boxId, store.content.boxList);

  useEffect(() => {
    core.setCurrentBox(currentBox);

    return () => core.setCurrentBox(undefined);
  }, [boxId]);

  if (!currentBox) return <div>No current box selected for activityLock route</div>;
  if (currentBox.lockedByThisActivity) {
    const activityList = currentBox.lockedByThisActivity;
    const background = currentBox.lockedByThisActivity[0].background;

    return (
      <Modal open>
        <ActivityList box={currentBox} activityList={activityList} background={background} />
      </Modal>
    );
  }
  return <div>No current lockedActivityList</div>;
};

export default observer(ActivityLockPage);
