import React, { useContext, useEffect } from "react";
import { ActivityText2 } from "../../types";
import { Title, StandardButton } from "../";
import { BaseActivityProps } from "../Activity/Activity.component";
import { observer } from "mobx-react";
import { StoreContext } from "../../context";
import { Container, TextTitleContainer } from "./ActivityText2.style";

interface Props extends BaseActivityProps {
  activity: ActivityText2;
}

const ActivityText2Component: React.FC<Props> = (props) => {
  const { core, store } = useContext(StoreContext);
  const texts = store.interfaceText;
  const onValidate = () => {
    if (props.goNext) props.goNext();
  };
  const isTracked = props.activity.isNeededForCompletion || store.currentBox?.lockedByThisActivity;
  const setActivityCompletionAccordingToCompletionCondition = async () => {
    if (isTracked && props.activity.tracking) {
      if (props.activity.tracking.completeCondition === "open") {
        await core.setInteractionCompletion(props.activity.id);
      }
    }
    if (props.nextBehaviour === "close" && props.onEnd) {
      props.onEnd();
    }
  };

  useEffect(() => {
    void setActivityCompletionAccordingToCompletionCondition();
  }, []);

  return (
    <Container>
      {props.activity.title && (
        <TextTitleContainer className="text-title-container">
          <Title level={2} title={props.activity.title} />
        </TextTitleContainer>
      )}
      <div dangerouslySetInnerHTML={{ __html: props.activity.text }} />
      {props.goNext && (
        <StandardButton onClick={onValidate}>
          {props.nextBehaviour === "close" ? texts.close : texts.next}
        </StandardButton>
      )}
    </Container>
  );
};

export default observer(ActivityText2Component);
