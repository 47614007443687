import { styled } from "../../theme";

export const Container = styled.div`
  max-width: 90%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
  margin-left: auto;
  margin-right: auto;
  min-height: 100%;

  .quiz-intro-main-container {
    height: 100%;
  }

  .answer-checkbox {
    padding: 1.1em 0.6em;
    font-family: ${({ theme }) => theme.font.mainFont.name};
    font-weight: normal;
    font-size: 2rem;
    justify-content: center !important;
    text-align: center !important;
  }

  .button-start-quiz {
    background-color: ${({ theme }) => theme.color.mainTitle};
    border: none;
    border-radius: 2em;
    margin: 1em;
    text-transform: uppercase;
    font-family: ${({ theme }) => theme.font.styledFont.name};
  }

  .question-column {
    max-width: 100% !important;
  }
  .question-column.context {
    display: block;
  }

  .question-column.question {
    width: 100%;
  }
  .question-context-description {
    display: none;
  }
  .question-context-title {
    text-align: center;
    margin-bottom: 0;
    margin-top: 2em;
  }

  .question-title {
    font-size: 2.5rem;
    margin-top: 0;
    margin-bottom: 0.5em;
    font-family: ${({ theme }) => theme.font.styledFont.name};
    color: ${({ theme }) => theme.color.mainTitle};
  }
  .quiz-container {
    height: 100%;
  }

  .question-content {
    flex-direction: column;
    align-content: center;
  }
  .question-internal-container {
    height: 100%;
  }
  .question-instructions {
    margin-top: 0 !important;
    font-size: 1.6rem;
    font-family: ${({ theme }) => theme.font.mainFont.name};
    color: ${({ theme }) => theme.color.lightText};
  }

  .question-answers-container {
    flex-direction: column;
    justify-content: flex-start;
  }

  .question-button-validate {
    font-family: ${({ theme }) => theme.font.styledFont.name};
    background-color: ${({ theme }) => theme.color.mainTitle};
    border-radius: 25px;
    height: 2em;
    text-transform: uppercase;
  }
  .answer-container {
    width: 95%;
  }

  ${({ theme }) => theme.breakpoints.down("md")} {
    .question-title {
      margin-top: 0;
      font-family: ${({ theme }) => theme.font.styledFont.name};
      color: ${({ theme }) => theme.color.mainTitle};
    }
    .answer-checkbox {
      justify-content: center;
      padding: 0.8em 0.6em;
    }
  }
  ${({ theme }) => theme.breakpoints.down("sm")} {
    .quiz-intro-main-container {
      height: 100%;
      width: 100%;
    }

    .button-start-quiz {
      font-size: 1.6rem;
    }
    .quiz-container {
      height: 100%;
      width: 100%;
    }
    .question-context-title {
      margin-top: 1.8em;
      font-size: 1.8rem;
    }
    .question-title {
      font-size: 2rem;
    }
    .question-internal-container {
    }
    .question-instructions {
      font-size: 1.6rem;
    }
    .question-answers-container {
      flex-direction: column;
      justify-content: flex-start;
    }
    .question-button-validate {
      font-size: 1.6rem !important;
    }
    .answer-container {
      width: 95%;
      margin: 0.5em 0;
    }
    .answer-checkbox {
      font-size: 1.6rem;
      justify-content: flex-start;
      padding: 0.8em 0.6em;
    }
  }
`;
export const QuizTitleContainer = styled.div``;

export const QuizIntroTitleContainer = styled.div`
  color: ${({ theme }) => theme.color.mainTitle};
  margin-bottom: 1.2em;
  ${({ theme }) => theme.breakpoints.down("sm")} {
    margin-top: 2em;
  }
`;
export const QuizIntroContainer = styled.div``;
export const QuizIntroIconContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 7em;
  height: 6em;
  box-sizing: border-box;
  margin-top: 2em;
  margin-bottom: 2em;
  ${({ theme }) => theme.breakpoints.down("sm")} {
    display: none;
  }
`;
export const QuizIntroIcon = styled.span<{ bg: string }>`
  width: 100%;
  height: 100%;
  background-image: ${({ bg }) => `url("${bg}")`};
  background-size: contain;
  background-repeat: no-repeat;
  background-position: center;
  display: block;
`;
export const QuizIntroRulesText = styled.p`
  font-family: ${({ theme }) => theme.font.mainFont.name};
  color: ${({ theme }) => theme.color.text};
  text-transform: none !important;
  font-weight: normal;
  font-size: 3.45rem;
  margin: 0;
  text-align: center;
  line-height: 1.2;
  font-stretch: condensed;
  ${({ theme }) => theme.breakpoints.down("sm")} {
    font-size: 2.45rem;
  }
`;
export const QuizIntroObjectiveText = styled.p`
  font-family: ${({ theme }) => theme.font.mainFont.name};
  color: ${({ theme }) => theme.color.lightText};
  text-transform: none !important;
  font-weight: normal;
  font-size: 2.5rem;
  margin-top: 1em;
  text-align: center;
  line-height: 1.2;
  font-stretch: condensed;
  ${({ theme }) => theme.breakpoints.down("sm")} {
    font-size: 2rem;
  }
`;
