import React, { ChangeEvent, useContext, useState } from "react";
import { StandardButton } from "..";
import { ActivityKeyword } from "../../types";
import { ColumnContainer, Column, KeywordTitleContainer, Container, KeywordForm } from "./ActivityKeyword.style";
import { BaseActivityProps } from "../Activity/Activity.component";
import { observer } from "mobx-react";
import TextField from "@material-ui/core/TextField";
import { StoreContext } from "../../context";
import Title from "../Title/Title.component";
import { isMobile, getCurrentMonthIndex, getPreviousMonthIndex } from "../../utils/utils";
import { keywordList, universalKeyword } from "../../content/keywordList";

interface Props extends BaseActivityProps {
  activity: ActivityKeyword;
}

const ActivityKeywordComponent: React.FC<Props> = (props) => {
  const { core, store } = useContext(StoreContext);
  const [keyword, setKeyword] = useState("");
  const [textfieldError, setTextfieldError] = useState(false);
  const [errorMessage, setErrorMessage] = useState("");

  const activityKeychain = keywordList[props.activity.id];

  const getCurrentValidKeywordArray = () => {
    const validKeyword = [universalKeyword];
    const currentMonthIndex = getCurrentMonthIndex();
    const previousMonthIndex = getPreviousMonthIndex();
    if (activityKeychain) {
      validKeyword.push(activityKeychain[currentMonthIndex], activityKeychain[previousMonthIndex]);
    }
    return validKeyword;
  };
  const onChangeKeyword = (event: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
    setKeyword(event.target.value);
  };
  const onSubmit = () => {
    const unlockKeywordList = getCurrentValidKeywordArray();
    if (unlockKeywordList.find((kitem) => kitem === keyword.trim().toLowerCase())) {
      setActivityCompletionAccordingToCompletionCondition();
      if (props.goNext) props.goNext();
    } else {
      setTextfieldError(true);
      setErrorMessage(
        store.interfaceText.keywordMismatch
          ? store.interfaceText.keywordMismatch
          : " keywordMismatch error is not set !",
      );
    }
  };

  const texts = store.interfaceText;
  const isTracked = props.activity.isNeededForCompletion || store.currentBox?.lockedByThisActivity;
  const onValidate = () => {
    if (props.goNext) props.goNext();
  };

  const setActivityCompletionAccordingToCompletionCondition = () => {
    if (isTracked && props.activity.tracking) {
      if (props.activity.tracking.completeCondition === "match") {
        void core.setInteractionCompletion(props.activity.id);
      }
    }
  };

  return (
    <Container className="keyword-container">
      {props.activity.title && (
        <KeywordTitleContainer className="keyword-title-container">
          <Title level={2} title={props.activity.title} />
        </KeywordTitleContainer>
      )}
      <ColumnContainer reverse={props.activity.type === "keyword"}>
        <Column>
          <React.Fragment>
            {" "}
            <div
              id={props.activity.id}
              className={`${props.activity.type} rich-text-container`}
              dangerouslySetInnerHTML={{ __html: props.activity.text }}
            />
            <KeywordForm onSubmit={onSubmit}>
              <TextField
                label={"Keyword"}
                value={keyword}
                onChange={onChangeKeyword}
                error={textfieldError}
                helperText={errorMessage}
              />
              <StandardButton style={{ marginTop: "1em" }} type="submit">
                {store.interfaceText.submit}
              </StandardButton>
            </KeywordForm>
          </React.Fragment>
        </Column>
        {props.goNext && isMobile() && (
          <Column className="navigation-column">
            <StandardButton onClick={onValidate}>
              {props.nextBehaviour === "close" ? texts.close : texts.next}
            </StandardButton>
          </Column>
        )}
      </ColumnContainer>
    </Container>
  );
};

export default observer(ActivityKeywordComponent);
