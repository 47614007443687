import React, { ChangeEvent, useContext, useEffect, useRef, useState } from "react";
import { Media } from "../../types";
import { Image, Video } from "./MediaContainer.style";
import { isMobile } from "../../utils/utils";
import { StoreContext } from "../../context";

const getVideoType = (url: string) => {
  const types = [
    {
      name: "mp4",
      regex: "/*.mp4",
    },
    {
      name: "webm",
      regex: "/*.webm",
    },
    {
      name: "ogg",
      regex: "/*.ogv",
    },
  ];
  for (let i = 0, n = types.length; i < n; i++) {
    if (RegExp(types[i].regex).test(url)) return types[i].name;
  }
  return "mp4";
};

interface Props {
  media: Media;
  style?: React.CSSProperties;
  onEnded?: () => void;
  onProgressMeasureChange?: (progressInPercents: number) => void;
}
const MediaContainerComponent = (props: Props) => {
  const { store } = useContext(StoreContext);
  const ref = useRef<HTMLVideoElement>(null);
  const [duration, setDuration] = useState(-1);

  const onDurationChange = (event: ChangeEvent<HTMLVideoElement>) => {
    setDuration(event.target.duration);
  };
  const onTimeUpdate = (event: ChangeEvent<HTMLVideoElement>) => {
    const progressInPercent = Math.round((event.target.currentTime / duration) * 100);
    props.onProgressMeasureChange && props.onProgressMeasureChange(progressInPercent);
  };

  useEffect(() => {
    ref.current?.load();
  }, [props.media.url]);
  if (props.media.type === "image") {
    if (props.media.mobileUrl && isMobile()) {
      return <Image style={props.style} src={props.media.mobileUrl} alt={props.media.alt} />;
    } else {
      return <Image style={props.style} src={props.media.url} alt={props.media.alt} />;
    }
  } else
    return (
      <Video
        onDurationChange={onDurationChange}
        onTimeUpdate={onTimeUpdate}
        autoPlay
        ref={ref}
        controls
        onEnded={props.onEnded}
      >
        <source src={props.media.url} type={`video/${getVideoType(props.media.url)}`} />
        {props.media.subtitle && <track src={props.media.subtitle} kind="subtitles" />}
        {props.media.caption && <track src={props.media.caption} kind="captions" default={store.accessibilityMode} />}
      </Video>
    );
};

export default MediaContainerComponent;
