import React, { useContext, useState, useEffect, useRef } from "react";
import FocusTrap from "focus-trap-react";
import { MatomoProvider, createInstance } from "@datapunt/matomo-tracker-react";
import { Route, Switch } from "react-router-dom";
import { observer } from "mobx-react";
import ReactModal from "react-modal";
import ReactGA from "react-ga";
import CookieConsent from "react-cookie-consent";
import { ThemeProvider } from "styled-components";

import { Footer, Header, MainMenu, StandardCloseButton, Background } from "./components";
import { AppStyle, MainContainer, ContainerInModal, ModalCloseButtonContainer, LoadingScreen } from "./App.style";
import { accessTheme, theme } from "./theme";
import { StoreContext } from "./context";
import BlockedRoute from "./routes/BlockedRoute";
import { GLOBAL_PARAMS } from "./content";
import { ActivityListPage, ActivityLockPage, BoxPage, GoBackPage } from "./pages";
import ROUTES from "./routes";
import AccessibleOffscreenNavComponent from "./components/AccessibleOffscreenNav/AccessibleOffscreenNav.component";
import { useMatomoTracking } from "./hooks";

const App: React.FC = () => {
  const { store, core } = useContext(StoreContext);

  const [modalContent, setModalContent] = useState<string | undefined>();
  const [cookieAcceptance, setCookieAcceptance] = useState<boolean>();
  const [isAppReady, setAppReady] = useState(false);
  const [currentTheme, setCurrentTheme] = useState(theme);
  const [parent, setParent] = useState<HTMLDivElement | null>(null);

  const mainMenuRef = useRef<HTMLDivElement>(null);

  useMatomoTracking({ documentTitle: "Landing Page" });

  if (GLOBAL_PARAMS["analyticsTrackingID"] && cookieAcceptance) {
    ReactGA.initialize(GLOBAL_PARAMS["analyticsTrackingID"], {
      debug: process.env.REACT_APP_DEBUG === "true" ? true : false,
    });
  }
  const instance = GLOBAL_PARAMS["matomoTracking"]
    ? createInstance({
        urlBase: GLOBAL_PARAMS["matomoTracking"]["urlBase"],
        siteId: GLOBAL_PARAMS["matomoTracking"]["siteId"],
        configurations: {
          disableCookies: true,
        },
      })
    : undefined;

  const matomoTrackingWrapper = (children: JSX.Element): JSX.Element => {
    if (instance) return <MatomoProvider value={instance}>{children}</MatomoProvider>;
    return children;
  };

  useEffect(() => {
    void core.bootstrap().then(() => setAppReady(true));
    const shouldOpenModal = () => {
      if (store.isFirstTime && store.content.welcomeMessage) setModalContent(store.content.welcomeMessage);
    };
    setTimeout(() => {
      shouldOpenModal();
    }, 1000);
  }, []);

  useEffect(() => {
    if (GLOBAL_PARAMS["analyticsTrackingID"] && cookieAcceptance) {
      ReactGA.initialize(GLOBAL_PARAMS["analyticsTrackingID"], {
        debug: process.env.REACT_APP_DEBUG === "true" ? true : false,
      });
    }
  }, [cookieAcceptance]);

  useEffect(() => {
    setCurrentTheme(store.accessibilityMode ? accessTheme : theme);
  }, [store.accessibilityMode]);

  const getImage = () => {
    if (!(store.content.backgroundMedia instanceof Array)) {
      if (store.content.backgroundMedia.type === "video") {
        return store.content.backgroundMedia.poster ? store.content.backgroundMedia.poster : "#FFFFFF";
      } else {
        return store.content.backgroundMedia;
      }
    } else {
      return store.content.backgroundMedia[0];
    }
  };
  useEffect(() => {
    return core.close;
  }, []);

  useEffect(() => {
    setParent(mainMenuRef.current);
  });

  if (!isAppReady) return <LoadingScreen isAppReady={isAppReady} />;

  return matomoTrackingWrapper(
    <>
      <ThemeProvider theme={currentTheme}>
        <AppStyle className={`app-container ${store.accessibilityMode ? "accessible_mode" : ""} `}>
          {/* <AccessibleOffscreenNavComponent /> */}
          <Header
            hidden={store.isFullScreen}
            logo={{ type: "image", url: store.content.logo }}
            title={store.content.title}
            background={getImage()}
          />
          <MainContainer role="main" className="main-container" id="main" fullscreen={store.isFullScreen}>
            <Background background={store.content.backgroundMedia}>
              <MainMenu ref={mainMenuRef} boxList={store.content.boxList} />
              <Switch>
                <BlockedRoute
                  path={`${ROUTES.ACTIVITY_LOCK_LIST.path}/:boxId`}
                  component={() => <ActivityLockPage />}
                />
                <BlockedRoute path={`${ROUTES.ACTIVITY_LIST.path}/:boxId`} component={() => <ActivityListPage />} />
                <BlockedRoute path={`${ROUTES.BOX.path}/:boxId`} component={() => <BoxPage parent={parent} />} />
                <Route path={ROUTES.GO_BACK.path} component={GoBackPage} />
                <BlockedRoute path={"/"} component={() => null} />
              </Switch>
            </Background>
          </MainContainer>
          <Footer
            hidden={store.isFullScreen}
            leftItemList={store.content.footerLeft}
            rightItemList={store.content.footerRight}
            footerBackground={store.content.footerBackground}
          />
          <ReactModal
            style={{
              overlay: {
                backgroundColor: theme.color.overlayColor,
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
                zIndex: 10,
              },
              content: {
                opacity: "initial",
                position: "unset",
                maxWidth: "70vw",
                maxHeight: "90vh",
              },
            }}
            isOpen={modalContent !== undefined}
            onRequestClose={() => setModalContent(undefined)}
            appElement={document.getElementById("root") || undefined}
          >
            <FocusTrap>
              <ContainerInModal>
                <ModalCloseButtonContainer>
                  <StandardCloseButton onClick={() => setModalContent(undefined)} />
                </ModalCloseButtonContainer>
                <div
                  style={{ paddingRight: "3em", paddingLeft: "3em" }}
                  dangerouslySetInnerHTML={{ __html: modalContent || "" }}
                />
              </ContainerInModal>
            </FocusTrap>
          </ReactModal>
          {GLOBAL_PARAMS["analyticsTrackingID"] && (
            <CookieConsent
              buttonText={
                store.content.overrideCookieCompliance?.acceptButton ||
                store.interfaceText.cookieCompliance?.acceptButton
              }
              enableDeclineButton
              visible={cookieAcceptance === undefined ? "show" : "hidden"}
              declineButtonText={
                store.content.overrideCookieCompliance?.declineButton ||
                store.interfaceText.cookieCompliance?.declineButton
              }
              onAccept={() => setCookieAcceptance(true)}
              onDecline={() => setCookieAcceptance(false)}
              overlay
              style={{
                background: "black",
                color: "white",
              }}
              buttonStyle={{
                background: "green",
                color: "white",
                fontWeight: "bolder",
              }}
              declineButtonStyle={{ background: "red", color: "white" }}
            >
              <h2>{store.content.overrideCookieCompliance?.title || store.interfaceText.cookieCompliance?.title}</h2>
              <p>{store.content.overrideCookieCompliance?.text || store.interfaceText.cookieCompliance?.text}</p>
            </CookieConsent>
          )}
        </AppStyle>
      </ThemeProvider>
    </>,
  );
};

export default observer(App);
