import React, { useReducer, useEffect, useContext, useState } from "react";
import { observer } from "mobx-react-lite";
import { Activity, Box, MediaImage } from "../../types";
import { Activity as ActivityComponent, SkipBoxCompletion, Title } from "../";
import { ActivityListContainer, ActivityContainer, ControlsColumn, CloseButtonContainer } from "./ActivityList.style";
import NavigationButton from "./NavigationButton.component";
import { useHistory } from "react-router-dom";
import StandardCloseButtonComponent from "../StandardCloseButton/StandardCloseButton.component";
import Debug from "../DebugComponent/Debug.component";
import { StoreContext } from "../../context";
import ROUTES from "../../routes";
import GLOBAL_PARAMS from "../../content/globalParams";
import FocusTrap from "focus-trap-react";
import { useMatomoTracking } from "../../hooks";
import Snackbar from "@material-ui/core/Snackbar";
import MuiAlert from "@material-ui/lab/Alert";
interface Props {
  box: Box;
  activityList: Activity[];
  background?: MediaImage | string;
}

type Action = { type: "nextSlide" } | { type: "previousSlide" };
type State = {
  index: number;
  displayArrowPrevious: boolean;
  displayArrowNext: boolean;
  nextBehaviour: "next" | "close";
};

const Alert = (props: any) => {
  return <MuiAlert elevation={6} variant="filled" {...props} />;
};

const ActivityList: React.FC<Props> = (props) => {
  const history = useHistory();
  const [openEndSnackBar, setOpenEndSnackBar] = React.useState(false);
  useMatomoTracking({ documentTitle: props.box.title ?? props.box.id });
  const { core, store } = useContext(StoreContext);
  const activityListReducer = (state: State, action: Action): State => {
    const newState = { ...state };
    if (action.type === "previousSlide") {
      newState.index--;
    }
    if (action.type === "nextSlide") {
      newState.index++;
    }
    newState.nextBehaviour = newState.index < props.activityList.length - 1 ? "next" : "close";
    newState.displayArrowNext =
      newState.index < props.activityList.length - 1 && !props.activityList[newState.index].embedNavigation;
    newState.displayArrowPrevious = newState.index !== 0 && !props.activityList[newState.index].embedNavigation;
    return newState;
  };
  const [state, dispatch] = useReducer(activityListReducer, {
    index: 0,
    displayArrowPrevious: false && !props.activityList[0].embedNavigation,
    displayArrowNext: true && !props.activityList[0].embedNavigation && props.activityList.length > 1,
    nextBehaviour: props.activityList.length > 1 ? "next" : "close",
  });
  const [shouldDisplayModal, setShouldDisplayModal] = useState(
    core.isValidateBySkillTester(props.box) && !core.isBoxComplete(props.box.id),
  );

  const currentActivity = props.activityList[state.index];

  const goPrevious = () => dispatch({ type: "previousSlide" });
  const goNext = () => {
    if (state.nextBehaviour === "next") dispatch({ type: "nextSlide" });
    else onClickClose();
  };
  const onClickClose = () => {
    if (store.currentBox) {
      const parentBox = core.getBoxParent(store.currentBox.id, store.content.boxList);
      history.push(parentBox ? `${ROUTES.BOX.path}/${parentBox.id}` : "/");
    }
  };

  if (!currentActivity) {
    return <>No Activity selected</>;
  }

  const closeEndSnackBar = (event: any, reason: any) => {
    if (reason === "clickaway") {
      return;
    }
    setOpenEndSnackBar(false);
  };

  const handleOpenEndSnackBar = () => {
    if (store.currentBox && store.currentBox.type === "content" && store.currentBox.hideEndHelper) {
      return;
    } else {
      setOpenEndSnackBar(true);
    }
  };

  if (shouldDisplayModal) {
    return <SkipBoxCompletion close={onClickClose} onAction={() => setShouldDisplayModal(false)} box={props.box} />;
  } else
    return (
      <FocusTrap>
        <ActivityListContainer
          id={currentActivity.id}
          className={"activity-list-container" + ` activity_${currentActivity.type}`}
          bg={props.background || core.isBoxlockedByThisActivity(currentActivity.id) ? props.background : undefined}
          role="dialog"
          aria-modal
        >
          {store.interfaceText.activityCompleted ? (
            <Snackbar
              anchorOrigin={{ vertical: "top", horizontal: "center" }}
              open={openEndSnackBar}
              autoHideDuration={6000}
              onClose={closeEndSnackBar}
            >
              <Alert onClose={closeEndSnackBar} severity="success" sx={{ width: "100%" }}>
                <Title level={3} title={store.interfaceText.activityCompleted || ""} />
              </Alert>
            </Snackbar>
          ) : null}
          <ControlsColumn className="activity-list-controls-column">
            <NavigationButton reverseDirection={false} hidden={!state.displayArrowPrevious} onClick={goPrevious} />
          </ControlsColumn>
          <ActivityContainer className="activity-list-activity-container">
            {store.currentBox?.id !== GLOBAL_PARAMS.onStartContent && (
              <CloseButtonContainer className="activity-list-close-button-container">
                <StandardCloseButtonComponent onClick={onClickClose} />
              </CloseButtonContainer>
            )}
            {/* <Debug /> */}
            <ActivityComponent
              activity={currentActivity}
              onClickClose={onClickClose}
              goPrevious={goPrevious}
              goNext={goNext}
              nextBehaviour={state.nextBehaviour}
              onEnd={handleOpenEndSnackBar}
            />
          </ActivityContainer>
          <ControlsColumn className="activity-list-controls-column">
            <NavigationButton reverseDirection={true} hidden={!state.displayArrowNext} onClick={goNext} />
          </ControlsColumn>
        </ActivityListContainer>
      </FocusTrap>
    );
};

export default observer(ActivityList);
