import React, { useContext, useEffect } from "react";
import { Activity } from "../../types";
import {
  ActivityText2,
  ActivityText1,
  ActivityImage,
  ActivityMultimedia,
  ActivityVideo,
  ActivityFakeQuestion,
  ActivityScormStorylineWithQuiz,
  ActivityScormStoryline,
  ActivityQuizResult,
  ActivityKnowledgeMapping,
  ActivityQuiz,
  ActivityResourceList,
  ActivitySkillTester,
  ActivityKeyword,
  Debug,
  ActivityPlaylistVideo,
  ActivityWebStoryline,
  ActivityEmbeddedScorm,
} from "../";
import { Container } from "./Activity.style";
import { StoreContext } from "../../context";
import { isMobile } from "../../utils/utils";

export interface BaseActivityProps {
  goNext?: () => any;
  goPrevious?: () => any;
  onClickClose?: () => any;
  nextBehaviour?: "close" | "next";
  onEnd?: () => any;
}
interface Props extends BaseActivityProps {
  activity: Activity;
}
const ActivityComponent = (props: Props): JSX.Element => {
  const ACTIVITY_MAPPER: Partial<{ [key in Activity["type"]]: React.FC<{ activity: any } & BaseActivityProps> }> = {
    text1: ActivityText1,
    text2: ActivityText2,
    image: ActivityImage,
    multimedia1: ActivityMultimedia,
    multimedia2: ActivityMultimedia,
    keyword: ActivityKeyword,
    video: ActivityVideo,
    playlistVideo: ActivityPlaylistVideo,
    fakeQuestion: ActivityFakeQuestion,
    webStoryline: ActivityWebStoryline,
    embeddedScorm: ActivityEmbeddedScorm,
    scormStoryline: ActivityScormStoryline,
    scormStorylineWithQuiz: ActivityScormStorylineWithQuiz,
    quizResult: ActivityQuizResult,
    realQuiz: ActivityQuiz,
    resourceList: ActivityResourceList,
    skillTester: ActivitySkillTester,
    knowledgeMapping: ActivityKnowledgeMapping,
  };

  const goNext = props.activity.embedNavigation ? props.goNext : undefined;
  const goPrevious = props.activity.embedNavigation ? props.goPrevious : undefined;
  const onClickClose = props.onClickClose ? props.onClickClose : undefined;
  const onEnd = props.onEnd;
  const ActivityComponent = ACTIVITY_MAPPER[props.activity.type];

  useEffect(() => {
    console.log(props.nextBehaviour);
  }, []);

  if (!ActivityComponent) return <div>{`Activity type ${props.activity.type} not yet implemented`}</div>;
  return (
    <Container
      className={"base-activity-container" + ` base-activity_${props.activity.type}`}
      bg={props.activity.background}
    >
      {/* {!isMobile() && <Debug />} */}
      <ActivityComponent
        activity={props.activity}
        goPrevious={goPrevious}
        goNext={goNext}
        onClickClose={onClickClose}
        nextBehaviour={props.nextBehaviour}
        onEnd={onEnd}
      />
    </Container>
  );
};

export default ActivityComponent;
