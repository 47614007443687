import { styled } from "../../theme";

interface CorrectionItemProps {
  isValid: boolean;
}
export const CorrectionItemContainer = styled.div<CorrectionItemProps>`
  display: flex;
  flex-direction: column;
  background-color: #fff;
  border-style: solid;
  border-color: ${(props) => (props.isValid ? props.theme.color.quizValid : props.theme.color.quizInvalid)};
  border-width: 2px;
  padding: 1em;
  box-shadow: 0px 0px 9px 2px
    ${(props) => (props.isValid ? props.theme.color.quizValid : props.theme.color.quizInvalid)}88;
  width: 20em;
  max-width: 90%;
  height: auto;
  border-radius: 1em;
  transition: all 0.2s ease-in-out;
  margin: 0px 1em 1em 1em;
  ${({ theme }) => theme.breakpoints.down("sm")} {
    width: 100%;
  }
`;

export const CorrectionItemTitle = styled.div<CorrectionItemProps>`
  color: ${(props) => (props.isValid ? props.theme.color.quizValid : props.theme.color.quizInvalid)};
  font-weight: bold;
  font-size: 1.5rem;
  padding-bottom: 0.5em;
  border-bottom: solid;
  border-bottom-width: 2px;
  border-bottom-color: ${(props) => props.theme.color.lightText};
`;

export const CorrectionItemSubtitle = styled.div`
  color: ${(props) => props.theme.color.text};
  margin-top: 1em;
  font-size: 1.3rem;
`;

export const CorrectionItemAnswersContainer = styled.div`
  display: flex;
  flex-direction: column;
`;

export const ItemAnwer = styled.div`
  font-size: 1.1rem;
`;
