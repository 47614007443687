import { styled } from "../../theme";

export const KeypointItemWrapper = styled.li<{ squareShape: boolean }>`
  width: ${100 / 6}%;
  padding: 1rem;
  box-sizing: border-box;
  ${({ theme }) => theme.breakpoints.down("sm")} {
    width: unset;
    height: unset;
  }
`;

export const KeypointItemContainer = styled.div`
  width: 100%;
  height: 100%;
  border-radius: 0.3em;
  display: flex;
  flex-direction: column;
  justify-content: stretch;
  align-items: center;
  background-color: #fff;
  position: relative;

  ${({ theme }) => theme.breakpoints.down("md")} {
  }

  &:last-of-type {
    margin-bottom: unset;
  }
  .progress-bar {
    background-color: ${({ theme }) => theme.color.lightText};
    width: 80%;
    min-height: 1.5em;
    border-radius: 0.2em;
    &.failed .MuiLinearProgress-barColorPrimary {
      background-color: ${({ theme }) => theme.color.keypointFailed};
    }
    &.passed .MuiLinearProgress-barColorPrimary {
      background-color: ${({ theme }) => theme.color.keypointPassed};
    }
    &.partial .MuiLinearProgress-barColorPrimary {
      background-color: ${({ theme }) => theme.color.keypointPartial};
    }
    .MuiLinearProgress-barColorPrimary {
      background-size: 1.5rem 1.5rem;
      background-image: linear-gradient(
        45deg,
        #ffffff26 25%,
        #0000 25%,
        #0000 50%,
        #ffffff26 50%,
        #ffffff26 75%,
        #0000 75%,
        #0000
      );
    }
    ${({ theme }) => theme.breakpoints.down("md")} {
      min-height: 1em;
    }
  }
`;
export const KeypointOverlay = styled.div``;
export const TitleContainer = styled.div`
  padding: 1.5rem 1rem;
  flex-grow: 1;
  display: flex;
  h2 {
    font-style: italic;
    color: ${({ theme }) => theme.color.text};
    text-align: center;
    font-size: 1.5rem;
    margin: auto;

    ${({ theme }) => theme.breakpoints.up("lg")} {
      font-size: 1.7rem;
    }
  }
  ${({ theme }) => theme.breakpoints.down("md")} {
    padding: 1rem 0.5rem;
  }
`;
export const DescriptionContainer = styled.div`
  color: ${({ theme }) => theme.color.text};
  text-align: center;
  max-width: 90%;
  ${({ theme }) => theme.breakpoints.down("md")} {
    font-size: 0.7em;
  }
`;
export const StatusContainer = styled.div``;
