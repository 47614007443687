import React, { useContext, useEffect, useState } from "react";
import { BaseActivityProps } from "../Activity/Activity.component";
import Title from "../Title/Title.component";
import { StandardButton } from "..";
import { ActivitySkillTester } from "../../types";
import QuizView, { AnswerResult } from "@upandgo/react-quiz";
import {
  Container,
  QuizIntroTitleContainer,
  ObjectiveText,
  ObjectiveContainer,
  ObjectiveList,
  ObjectiveWrapper,
  SkillTesterObjectiveBlock,
  SkillTesterContextBlock,
  ContextIconContainer,
  ContextIcon,
  ContextDescription,
  ContextWrapper,
  SkillTesterButtonBlock,
  ButtonListWrapper,
  ButtonBlock,
  DurationBlock,
  SkillTesterTitleContainer,
  ObjectiveItemContainer,
} from "./ActivitySkillTester.style";
import { theme } from "../../theme";
import { StoreContext } from "../../context";
import deepClone from "lodash/cloneDeep";
import { toJS } from "mobx";
import {
  computeSkillTestResults,
  getAllQuestionId,
  getKeypointBoxList,
  getTotalBoxListDuration,
  getTotalSkippableTime,
  roundToNearestMultiple,
} from "../../utils/utils";
import QuizBreadcrumb from "../QuizBreadcrumb/QuizBreadcrumb";

interface Props extends BaseActivityProps {
  activity: ActivitySkillTester;
}

// Set to 1 if you need basic round
const NEAREST_MULTPLIE = 5;

const ActivitySkillTesterComponent: React.FC<Props> = (props) => {
  const { store, core } = useContext(StoreContext);
  const [currentQuestionIndex, setCurrentQUestionIndex] = useState(0);
  const texts = store.interfaceText;
  let init_step = 0;
  if (!props.activity.intro) {
    init_step = 1;
  }

  const clonedQuiz = deepClone(toJS(props.activity.quiz));

  useEffect(() => {
    core.resetQuizAnswerResult();
  }, []);

  useEffect(() => {
    core.resetSkillTesterResults(props.activity.quiz.id);
  }, []);

  useEffect(() => {
    if (store.currentBox) {
      if (core.isBoxComplete(store.currentBox?.id) || core.isBoxPassed(store.currentBox?.id)) {
        setStep(2);
      }
    }
  }, []);
  const [step, setStep] = useState(init_step);

  const adaptiveCourseDuration = () => {
    const keypointList = getKeypointBoxList(store.content.boxList);
    const maxSkippableDuration = getTotalSkippableTime(keypointList);
    const moduleGlobaleDuration = getTotalBoxListDuration(store.content.boxList);
    const minModuleGlobalDuration = Math.max(0, moduleGlobaleDuration - maxSkippableDuration);
    return `${roundToNearestMultiple(minModuleGlobalDuration, NEAREST_MULTPLIE)} - ${roundToNearestMultiple(
      moduleGlobaleDuration,
      NEAREST_MULTPLIE,
    )} min`;
  };
  const completeCourseDuration = () => {
    const moduleGlobaleDuration = getTotalBoxListDuration(store.content.boxList);
    return `${roundToNearestMultiple(moduleGlobaleDuration, NEAREST_MULTPLIE)} min`;
  };

  const onAnswerResult = async (result: AnswerResult) => {
    if (store.currentBox) {
      await core.setQuizAnswerResult({
        quiz: clonedQuiz,
        answer: result,
        threshold: props.activity.validatingThreshold || 0,
        setAsSkillTester: true,
        setAsGlobalScore: props.activity.tracking?.setAsGlobalScore,
      });
    }
  };
  const onNext = (index: number) => {
    if (index === props.activity.quiz.questionList.length - 1) {
      if (store.quizResults[props.activity.quiz.id]) {
        computeSkillTestResults(props.activity.id);
        const skillTesterStatus =
          store.quizResults[props.activity.quiz.id].successStatus === "passed" ? "correct" : "neutral";
        core.setInteractionResult(props.activity.quiz.id, skillTesterStatus);
      }
      if (props.goNext) props.goNext();
    }
    setCurrentQUestionIndex(currentQuestionIndex + 1);
  };
  const skipIntro = () => {
    setStep(1);
  };
  const skipSkillTester = () => {
    const fullQuestionIdList = getAllQuestionId(props.activity.quiz.id);
    fullQuestionIdList.forEach((question) => core.setSkillTesterResults(props.activity.quiz.id, question));
    core.setInteractionResult(props.activity.quiz.id, "neutral");
    if (props.onClickClose) props.onClickClose();
  };

  return (
    <Container className="skilltester-main-container">
      {props.activity.title && (
        <SkillTesterTitleContainer className="skilltester-title-container">
          <Title level={2} title={props.activity.title} />
        </SkillTesterTitleContainer>
      )}
      {step === 0 && props.activity.intro && (
        <React.Fragment>
          <SkillTesterObjectiveBlock className="skill-tester-objective-block">
            <ObjectiveWrapper>
              {" "}
              {props.activity.intro.title && (
                <QuizIntroTitleContainer className="skilltester-intro-title">
                  <Title fontColor={props.activity.intro.color} level={1} title={props.activity.intro.title} />
                </QuizIntroTitleContainer>
              )}
              {props.activity.intro.objective && (
                <ObjectiveContainer>
                  <ObjectiveText>{props.activity.intro.objective.text}</ObjectiveText>
                  <ObjectiveList className="objective-list">
                    {
                      <React.Fragment>
                        {" "}
                        {props.activity.intro.objective.list.map((objective, index) => (
                          <ObjectiveItemContainer key={index}>{objective}</ObjectiveItemContainer>
                        ))}
                      </React.Fragment>
                    }
                  </ObjectiveList>
                </ObjectiveContainer>
              )}
            </ObjectiveWrapper>
          </SkillTesterObjectiveBlock>
          <SkillTesterContextBlock>
            <ContextWrapper>
              {props.activity.intro.icon && (
                <ContextIconContainer className={"skilltester-intro-icon-container"}>
                  <ContextIcon bg={props.activity.intro.icon} className={"skilltester-intro-icon"} />
                </ContextIconContainer>
              )}
              <ContextDescription>{texts.skillTester?.contextDescription}</ContextDescription>
            </ContextWrapper>
          </SkillTesterContextBlock>
          <SkillTesterButtonBlock>
            <ButtonListWrapper>
              <ButtonBlock>
                {" "}
                <StandardButton className="button-adaptive" onClick={skipIntro}>
                  {texts.skillTester?.adaptiveButton}
                </StandardButton>
                <DurationBlock>{adaptiveCourseDuration()}</DurationBlock>
              </ButtonBlock>

              {!props.activity.intro.isUnskippable && (
                <ButtonBlock>
                  {" "}
                  <StandardButton className="button-complete" onClick={skipSkillTester}>
                    {texts.skillTester?.completeCourseButton}
                  </StandardButton>
                  <DurationBlock>{completeCourseDuration()}</DurationBlock>
                </ButtonBlock>
              )}
            </ButtonListWrapper>
          </SkillTesterButtonBlock>
        </React.Fragment>
      )}
      {step === 1 && (
        <React.Fragment>
          {" "}
          <QuizBreadcrumb
            questionsLenght={props.activity.quiz.questionList.length}
            currentQuestionIndex={currentQuestionIndex}
          />
          <QuizView
            theme={{
              primaryColor: theme.color.mainTitle,
              secondaryColor: theme.color.mainTitle,
              textColor: theme.color.text,
              textAnswerColor: theme.color.text,
              validColor: theme.color.quizValid,
              invalidColor: theme.color.quizInvalid,
              focusColor: theme.color.focus,
            }}
            text={{
              next: store.interfaceText.next,
              validate: store.interfaceText.validate,
              correct: "correct",
              incorrect: "incorrect",
            }}
            onAnswerQuestion={onAnswerResult}
            onNextQuestion={onNext}
            questionAggregate={{
              ...clonedQuiz,
              text: { next: texts.next, validate: texts.validate, correct: "correct", incorrect: "incorrect" },
            }}
            startingIndex={0}
          />
        </React.Fragment>
      )}
      {step === 2 && (
        <Container>
          <>
            <p>Vous avez choisis de parcourir le parcours dans son intégralité</p>
          </>
        </Container>
      )}
      {step === 3 && <Container></Container>}
      {step === 4 && <Container></Container>}
    </Container>
  );
};

export default ActivitySkillTesterComponent;
