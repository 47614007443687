import React, { useContext, useEffect, useRef, useState } from "react";
import { BaseActivityProps } from "../Activity/Activity.component";
import { ActivityVideo } from "../../types";
import { observer } from "mobx-react";
import { StoreContext } from "../../context";
import { Container, VideoContainer, VideoTitleContainer } from "./ActivityVideo.style";
import { MediaContainer, StandardButton, Title } from "..";

interface Props extends BaseActivityProps {
  activity: ActivityVideo;
}

const ActivityVideoContainer: React.FC<Props> = (props) => {
  const ref = useRef<HTMLDivElement>(null);
  const { core, store } = useContext(StoreContext);
  const texts = store.interfaceText;
  const [titleContainerHeight, setTitleContainerHeight] = useState(0);
  const isTracked = props.activity.isNeededForCompletion || store.currentBox?.lockedByThisActivity;
  const [endFlag, setEndFlag] = useState(false);

  const setActivityCompletionAccordingToCompletionCondition = async (progressMeasure: number) => {
    if (isTracked && props.activity.tracking?.completeCondition && !endFlag) {
      if (progressMeasure < props.activity.tracking.completeCondition) {
        return;
      }
      await core.setInteractionCompletion(props.activity.id);
      setEndFlag(true);
    }
    if (props.nextBehaviour === "close" && props.onEnd && !endFlag) {
      props.onEnd();
    }
  };

  const onValidate = () => {
    if (props.goNext) props.goNext();
  };
  useEffect(() => {
    if (titleContainerHeight === 0) {
      setTitleContainerHeight(ref.current ? ref.current.offsetHeight : 0);
    }
  }, [ref]);

  return (
    <Container className="main-video-container">
      {props.activity.title && (
        <VideoTitleContainer ref={ref} className="video-title-container">
          <Title level={2} title={props.activity.title} />
        </VideoTitleContainer>
      )}

      {
        <VideoContainer titleHeight={titleContainerHeight} className="video-container">
          <MediaContainer
            media={props.activity.video}
            onProgressMeasureChange={setActivityCompletionAccordingToCompletionCondition}
          />{" "}
        </VideoContainer>
      }

      {props.goNext && (
        <StandardButton onClick={onValidate}>
          {props.nextBehaviour === "close" ? texts.close : texts.next}
        </StandardButton>
      )}
    </Container>
  );
};

export default observer(ActivityVideoContainer);
