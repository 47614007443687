import React, { useContext, useEffect, useRef, useState } from "react";
import { StandardResourceItem } from "..";
import { ActivityResource } from "../../types";
import { BaseActivityProps } from "../Activity/Activity.component";
import { StoreContext } from "../../context";
import {
  DownloadAlternative,
  DownloadCountDown,
  DownloadDisclamer,
  SingleResourceContainer,
  SingleResourceItemContainer,
} from "./SingleResource.style";
interface Props extends BaseActivityProps {
  activity: ActivityResource;
}

const SingleResourceComponent: React.FC<Props> = (props) => {
  const handleClick = () => {
    setcountdownFlag(true);
  };

  const { store } = useContext(StoreContext);
  const resource = props.activity.resourceList[0];
  const [timer, setTimer] = useState(5);
  const [countdownFlag, setcountdownFlag] = useState(false);
  const setCountDownForceDownload = () => {
    if (!countdownFlag) {
      if (timer !== 0) {
        setTimeout(() => setTimer(timer - 1), 1000);
      }
      if (timer === 0) {
        ref.current?.click();
        setcountdownFlag(true);
      }
    }
  };
  const ref = useRef<HTMLAnchorElement>(null);
  useEffect(() => setCountDownForceDownload(), [timer, countdownFlag]);

  return (
    <React.Fragment>
      <SingleResourceContainer>
        <DownloadDisclamer>{store.interfaceText.singleResourceDownload}</DownloadDisclamer>
        <DownloadCountDown>{timer}</DownloadCountDown>
        <DownloadAlternative>{store.interfaceText.singleResourceDownloadAlternative}</DownloadAlternative>
        <SingleResourceItemContainer>
          <StandardResourceItem ref={ref} resource={resource} actionClick={handleClick}></StandardResourceItem>
        </SingleResourceItemContainer>
      </SingleResourceContainer>
    </React.Fragment>
  );
};

export default SingleResourceComponent;
