import React from "react";
import { AnswerResult, isQuestionValid, Question, QuestionAggregate } from "@upandgo/react-quiz";
import deepClone from "lodash/cloneDeep";

import { theme } from "../theme";
import { ContentBox, Box } from "../types";
import { core, store } from "../state";
import { extractObjectifArrayFromSkillTesterQuestionId } from "./formatter";

export const spanify = (stringBlock: string, customItemClassname?: string) => {
  const stringArray = stringBlock.split("///");
  return stringArray.map((item, index) => (
    <span key={index} className={customItemClassname}>
      {item}
    </span>
  ));
};

export const lightenColor = function (color: string, percent: number): string {
  const num = parseInt(color.replace("#", ""), 16);
  const amt = Math.round(2.55 * percent);
  const R = (num >> 16) + amt;
  const B = ((num >> 8) & 0x00ff) + amt;
  const G = (num & 0x0000ff) + amt;

  return (
    "#" +
    (
      0x1000000 +
      (R < 255 ? (R < 1 ? 0 : R) : 255) * 0x10000 +
      (B < 255 ? (B < 1 ? 0 : B) : 255) * 0x100 +
      (G < 255 ? (G < 1 ? 0 : G) : 255)
    )
      .toString(16)
      .slice(1)
  );
};

export const pickTextColorBasedOnBgColor = function (bgColor: string, lightColor: string, darkColor: string): string {
  const color = bgColor.charAt(0) === "#" ? bgColor.substring(1, 7) : bgColor;
  const r = parseInt(color.substring(0, 2), 16);
  const g = parseInt(color.substring(2, 4), 16);
  const b = parseInt(color.substring(4, 6), 16);
  const uicolors = [r / 255, g / 255, b / 255];
  const c = uicolors.map((col) => {
    if (col <= 0.03928) {
      return col / 12.92;
    }
    return Math.pow((col + 0.055) / 1.055, 2.4);
  });
  const L = 0.2126 * c[0] + 0.7152 * c[1] + 0.0722 * c[2];
  return L > 0.179 ? darkColor : lightColor;
};

export const isMobile = function (screenWidth = window.innerWidth) {
  const breakPoint = theme.breakpoints.values.sm;
  return screenWidth <= breakPoint;
};

export const isPortrait = function (screenSize = { width: window.innerWidth, heigth: window.innerHeight }) {
  return screenSize.width < screenSize.heigth;
};

export const findItemAndIndexById = <T extends { id: string }>(
  id: string,
  tabWithId: T[],
):
  | {
      item: T;
      index: number;
    }
  | undefined => {
  try {
    let itemIndex = -1;
    const item = tabWithId.find((item, index) => {
      itemIndex = index;
      return item.id === id;
    });
    if (item) {
      return {
        item,
        index: itemIndex,
      };
    } else return undefined;
  } catch (e) {
    return undefined;
  }
};

export type RequiredBy<T, K extends keyof T> = Pick<T, K> & Required<Pick<T, K>>;

export function getNavigatorCompatibleLanguage() {
  return window.navigator.language.split("-")[0].toLowerCase();
}

export async function later(func: (...args: any) => any, delay: number) {
  return new Promise<void>(function (resolve, reject) {
    setTimeout(() => {
      try {
        func();
        resolve();
      } catch (e) {
        reject(e);
      }
    }, delay);
  });
}

export const getCurrentMonthIndex = () => {
  const currentTimestamp = new Date(Date.now());
  return currentTimestamp.getMonth();
};

export const getPreviousMonthIndex = () => {
  const currentTimestamp = new Date(Date.now());
  currentTimestamp.setMonth(currentTimestamp.getMonth() - 1);
  return currentTimestamp.getMonth();
};

export const updateQuizWithResult = (
  quiz: QuestionAggregate,
  result: AnswerResult,
): { updatedQuiz: QuestionAggregate; isAnswerValid: boolean } => {
  const clonedQuiz = deepClone(quiz);

  const currentQuestionIndex = clonedQuiz.questionList.findIndex(({ id }) => id === result.idQuestion);

  if (currentQuestionIndex === -1) return { updatedQuiz: clonedQuiz, isAnswerValid: false };

  clonedQuiz.questionList[currentQuestionIndex].answerList = clonedQuiz.questionList[
    currentQuestionIndex
  ].answerList.map((answer) => {
    return { ...answer, selected: result.idAnswers.includes(answer.id) };
  });

  return { updatedQuiz: clonedQuiz, isAnswerValid: isQuestionValid(clonedQuiz.questionList[currentQuestionIndex]) };
};

export const countItemOccurence = (array: string[], itemId: string): number => {
  const maxCount = array.reduce((acc, item) => {
    if (item === itemId) {
      return acc + 1;
    }
    return acc;
  }, 0);
  return maxCount;
};
export const computeSkillTestResults = (relatedActivityId: string): void => {
  const rawObjectiveList = getRawSkillTesterObjectiveList(relatedActivityId);
  const filteredObjectiveList = getFilteredSkillTesterObjectiveList(relatedActivityId);
  const rawValidObjectiveList = getRawSkillTesterResults(relatedActivityId);

  for (const objectiveList of filteredObjectiveList) {
    const objectiveMax = countItemOccurence(rawObjectiveList, objectiveList);
    const validObjectiveCount = countItemOccurence(rawValidObjectiveList, objectiveList);
    const objectiveScore = Math.round((validObjectiveCount / (objectiveMax || 1)) * 100);
    core.setObjectiveScoreMin(objectiveList, objectiveScore);
    core.setObjectiveScoreRaw(objectiveList, objectiveScore);
  }
};

export const getKeypointBoxList = (boxList: Box[]): ContentBox[] => {
  const keypointBoxList: ContentBox[] = [];
  boxList.forEach((box) => {
    if (box.type === "content" && box.keypoint !== undefined) {
      keypointBoxList.push(box);
    }
    if (box.type === "menu") {
      const subList = getKeypointBoxList(box.boxList);
      keypointBoxList.push(...subList);
    }
  });
  return keypointBoxList;
};

export const getTotalBoxListDuration = (boxList: Box[]): number => {
  const duration = boxList.reduce((acc, box) => {
    if (box.type === "content") {
      return acc + box.estimatedTime;
    }
    if (box.type === "menu") {
      const subList = box.boxList;
      return acc + getTotalBoxListDuration(subList);
    }
    return acc;
  }, 0);
  return duration;
};
export const getObjectiveScore = (keypointId: string): { scoreMin: number; scoreRaw: number } => {
  const KeypointScore = { scoreMin: 0, scoreRaw: 0 };
  const objective = findItemAndIndexById(keypointId, store.objectiveList);
  if (objective && objective.item) {
    KeypointScore.scoreMin = objective.item.scoreMin || 0;
    KeypointScore.scoreRaw = objective.item.scoreRaw || 0;
  }
  return KeypointScore;
};

export const getUserSkippedTime = (globalKeypointList: ContentBox[]): number => {
  const rawSkippedTime = globalKeypointList.reduce((acc, item) => {
    const objectiveScore = getObjectiveScore(item.id);
    if (objectiveScore && item.keypoint) {
      if (objectiveScore.scoreMin >= item.keypoint?.threshold) {
        return acc + item.estimatedTime;
      }
    }

    return acc;
  }, 0);
  return rawSkippedTime;
};

export const getTotalSkippableTime = (globalKeypointList: ContentBox[]): number => {
  const skippableTime = globalKeypointList.reduce((acc, item) => {
    if (item.keypoint) {
      return acc + item.estimatedTime;
    }
    return acc;
  }, 0);
  return skippableTime;
};

export const getMinimumSkippableTime = (globalKeypointList: ContentBox[]): number => {
  let minTime = 0;
  globalKeypointList.forEach((item) => {
    if (minTime === 0) minTime = item.estimatedTime;
    if (item.estimatedTime < minTime) {
      minTime = item.estimatedTime;
    }
  });
  return minTime;
};

export const roundToNearestMultiple = (num: number, multiple = 1): number => {
  const multipleInteger = Math.round(multiple);
  const rounded = Math.round(num / multipleInteger) * multipleInteger;

  return rounded;
};

export const getValidKeypointBoxList = (boxList: Box[]): ContentBox[] => {
  const keypointBoxList: ContentBox[] = getKeypointBoxList(boxList);
  const validKeypointBoxList: ContentBox[] = [];
  for (let i = 0; i < keypointBoxList.length; i++) {
    if (core.isValidateBySkillTester(keypointBoxList[i])) {
      validKeypointBoxList.push(keypointBoxList[i]);
    }
  }
  return validKeypointBoxList;
};

export const getRawSkillTesterObjectiveList = (relatedActivityId: string): string[] => {
  const skillTester = findItemAndIndexById(relatedActivityId, core.getAllActivities(store.content.boxList));
  const result = skillTester?.item.type === "skillTester" ? skillTester.item : undefined;
  const ObjectiveList: string[] = [];
  if (result) {
    result.quiz.questionList.forEach((question) => {
      const questionObjectiveArray = extractObjectifArrayFromSkillTesterQuestionId(question.id);
      for (let i = 0; i < questionObjectiveArray.length; i++) {
        ObjectiveList.push(questionObjectiveArray[i]);
      }
    });
  }
  return ObjectiveList;
};

export const getFilteredSkillTesterObjectiveList = (relatedActivityId: string): string[] => {
  const rawObjectiveList = getRawSkillTesterObjectiveList(relatedActivityId);
  return rawObjectiveList.filter((index, item) => rawObjectiveList.indexOf(index) == item);
};

export const getRawSkillTesterResults = (relatedActivityId: string): string[] => {
  const activity = findItemAndIndexById(relatedActivityId, core.getAllActivities(store.content.boxList));
  const validObjectiveList: string[] = [];
  if (activity && activity.item.type === "skillTester") {
    const result = store.quizResults[activity?.item.quiz.id];
    result.quiz.questionList.forEach((question) => {
      if (isQuestionValid(question)) {
        const questionObjectiveArray = question.id.split("_");
        questionObjectiveArray.pop();
        for (let i = 0; i < questionObjectiveArray.length; i++) {
          validObjectiveList.push(questionObjectiveArray[i]);
        }
      }
    });
  }
  return validObjectiveList;
};
export const getAllQuestion = (quizId: string): Question[] => {
  let questionList: Question[] = [];
  const activityList = core.getAllActivities(store.content.boxList);
  if (activityList) {
    activityList.forEach((activity) => {
      if (activity.type === "skillTester" || activity.type === "realQuiz") {
        if (activity.quiz.id === quizId) {
          questionList = questionList.concat(activity.quiz.questionList);
        }
      }
    });
  }
  return questionList;
};

export const getAllQuestionId = (quizId: string): string[] => {
  const questionIdList: string[] = [];
  const questionList: Question[] = getAllQuestion(quizId);
  if (questionList) {
    questionList.forEach((question) => questionIdList.push(question.id));
  }
  return questionIdList;
};
export const filteredQuestionFromResult = (quizId: string, filter: string[]): Question[] => {
  const questionList: Question[] = getAllQuestion(quizId);
  const filteredQuestionList: Question[] = [];
  filter.forEach((questionId) => {
    const foundQuestion = findItemAndIndexById(questionId, questionList);
    if (foundQuestion) filteredQuestionList.push(foundQuestion.item);
  });
  return filteredQuestionList;
};

export const getHeritedQuestionList = (relatedQuizIdList: string[]): Question[] => {
  let heritedQuestionList: Question[] = [];
  relatedQuizIdList.forEach((quizId) => {
    if (store.skillTesterResults[quizId] && store.skillTesterResults[quizId].failedQuestionIdList) {
      const questionList = filteredQuestionFromResult(quizId, store.skillTesterResults[quizId].failedQuestionIdList);
      heritedQuestionList = heritedQuestionList.concat(questionList);
    }
  });
  return heritedQuestionList;
};
