import { styled } from "../../theme";

export const BreadcrumbContainer = styled.ul`
  list-style: none;
  display: flex;
  justify-content: center;
  padding: 0;
`;

export const BreadcrumbItem = styled.li`
  font-size: 2.5em;
  padding-left: 0.25em;
  padding-right: 0.25em;
  color: #e6f5fb;
  &.current {
    color: ${({ theme }) => theme.color.enhance};
  }
  ${({ theme }) => theme.breakpoints.down("sm")} {
    font-size: 3em;
    padding-left: 0.1em;
    padding-right: 0.1em;
  }
`;
