import React, { useContext, useEffect, useRef } from "react";
import { BaseActivityProps } from "../Activity/Activity.component";
import {
  Container,
  StorylineIframe,
  StorylineIframeContainer,
  StorylineTitleContainer,
} from "./ActivityWebStoryline.style";
import { ActivityWebStoryline } from "../../types";
import { observer } from "mobx-react";
import { StoreContext } from "../../context";
import Title from "../Title/Title.component";
import { useState } from "react";
import { useMediaQuery } from "@material-ui/core";
import { theme } from "../../theme";

interface Props extends BaseActivityProps {
  activity: ActivityWebStoryline;
}
// window.postMessage({ action: "set", variable: "cmi.completion_status", value: "completed" });
// window.postMessage({ action: "closeStoryline" });

const ActivityWebStorylineContainer: React.FC<Props> = (props) => {
  const { core, store } = useContext(StoreContext);

  const ref = useRef<HTMLDivElement>(null);
  const refIframe = useRef<HTMLIFrameElement>(null);
  const shouldDisplayMobile = useMediaQuery(theme.breakpoints.down("sm")) && props.activity.mobileUrl !== undefined;
  const shouldDisplayAccessible = props.activity.accessibleUrl !== undefined && store.accessibilityMode;
  const isTracked = props.activity.isNeededForCompletion || store.currentBox?.lockedByThisActivity;

  const [titleContainerHeight, setTitleContainerHeight] = useState(0);
  const debugMode = process.env.REACT_APP_DEBUG === "true" ? true : false;

  const handler = (event: {
    data: {
      action: string;
      variable: string;
      value: string;
    };
  }) => {
    debugMode && console.log(event);
    if (
      event.data.action === "set" &&
      event.data.variable === "cmi.completion_status" &&
      event.data.value === "completed"
    ) {
      if (props.activity.isNeededForCompletion && props.activity.tracking?.completeCondition === "complete") {
        void core.setInteractionCompletion(props.activity.id);
      }
      if (props.nextBehaviour === "close" && props.onEnd) {
        props.onEnd();
      }
    }
    if (event.data.action === "closeStoryline" && props.onClickClose !== undefined) {
      props.onClickClose();
    }
    if (event.data.action === "nextActivity" && props.goNext !== undefined) {
      props.goNext();
    }
    if (event.data.action === "previousActivity" && props.goPrevious !== undefined) {
      props.goPrevious();
    }
  };

  useEffect(() => {
    if (titleContainerHeight === 0) {
      setTitleContainerHeight(ref.current ? ref.current.offsetHeight : 0);
    }
  }, [ref]);

  useEffect(() => {
    if (isTracked && props.activity.tracking) {
      if (props.activity.tracking.completeCondition === "open") {
        console.log(props.activity.id);
        void core.setInteractionCompletion(props.activity.id);
      }
    }
    const iframe = refIframe.current?.contentWindow;

    if (iframe) {
      iframe.addEventListener("message", handler, false);
      return () => {
        iframe.removeEventListener("message", handler);
      };
    }
  }, []); // empty array => run only once

  return (
    <Container className="activity-storyline-container">
      {props.activity.title && (
        <StorylineTitleContainer ref={ref} className="storyline-title-container">
          <Title level={2} title={props.activity.title} />
        </StorylineTitleContainer>
      )}
      <StorylineIframeContainer titleHeight={titleContainerHeight} className="storyline-iframe-container">
        {shouldDisplayAccessible && (
          <StorylineIframe
            className="storyline-iframe-accessible"
            title={props.activity.title}
            allowFullScreen={true}
            src={props.activity.accessibleUrl}
            ref={refIframe}
            tabIndex={0}
          />
        )}
        {shouldDisplayMobile && !shouldDisplayAccessible && (
          <StorylineIframe
            className="storyline-iframe-mobile"
            title={props.activity.title}
            allowFullScreen={true}
            src={props.activity.mobileUrl}
            ref={refIframe}
            tabIndex={0}
          />
        )}
        {!shouldDisplayMobile && !shouldDisplayAccessible && (
          <StorylineIframe
            className="storyline-iframe-desktop"
            title={props.activity.title}
            allowFullScreen={true}
            src={props.activity.desktopUrl}
            ref={refIframe}
            tabIndex={0}
          />
        )}
      </StorylineIframeContainer>
    </Container>
  );
};

export default observer(ActivityWebStorylineContainer);
