import { styled } from "../../theme";

export const BoxItemBadgeStyle = styled.span`
  position: absolute;
  right: 0.5em;
  top: 0.5em;
  width: 2.25em;
  height: 2.25em;
  border-radius: 50%;
  background-size: 75%;
  background-position: center;
  background-repeat: no-repeat;
  box-sizing: border-box;
  z-index: 1000;

  ${({ theme }) => theme.breakpoints.down("sm")} {
    right: 0.2em;
    top: 0.2em;
    width: 1.5em;
    height: 1.5em;
  }
`;
export const BoxItemActivityLockedBadgeStyle = styled(BoxItemBadgeStyle)`
  background-image: url(${({ theme }) => theme.icons.boxActivityLocked});
  background-color: ${({ theme }) => theme.color.bagdeActivityLocked};
`;

export const BoxItemCompletedBadgeStyle = styled(BoxItemBadgeStyle)`
  background-image: url(${({ theme }) => theme.icons.boxCompleted});
  background-color: ${({ theme }) => theme.color.badgeComplete};
`;
export const BoxItemLockedBadgeStyle = styled(BoxItemBadgeStyle)`
  background-image: url(${({ theme }) => theme.icons.boxLocked});
  background-color: ${({ theme }) => theme.color.bagdeLocked};
`;
export const BoxItemPassedBadgeStyle = styled(BoxItemBadgeStyle)`
  background-image: url(${({ theme }) => theme.icons.boxPassed});
  background-color: ${({ theme }) => theme.color.badgePassed};
`;
export const BoxItemFailedBadgeStyle = styled(BoxItemBadgeStyle)`
  background-image: url(${({ theme }) => theme.icons.boxFailed});
  background-color: ${({ theme }) => theme.color.badgeFailed};
`;
