import React, { useContext, useEffect } from "react";
import { ActivityText1 } from "../../types";
import { Container, TextContainer, TextColumn, TextTitleContainer } from "./ActivityText1.style";
import { BaseActivityProps } from "../Activity/Activity.component";
import { StandardButton, Title } from "..";
import { observer } from "mobx-react";
import { StoreContext } from "../../context";
interface Props extends BaseActivityProps {
  activity: ActivityText1;
}

const ActivityText1Component: React.FC<Props> = (props) => {
  const { core, store } = useContext(StoreContext);
  const texts = store.interfaceText;
  const isTracked = props.activity.isNeededForCompletion || store.currentBox?.lockedByThisActivity;

  const setActivityCompletionAccordingToCompletionCondition = async () => {
    if (isTracked && props.activity.tracking) {
      if (props.activity.tracking.completeCondition === "open") {
        await core.setInteractionCompletion(props.activity.id);
      }
    }
    if (props.nextBehaviour === "close" && props.onEnd) {
      props.onEnd();
    }
  };

  useEffect(() => {
    void setActivityCompletionAccordingToCompletionCondition();
  }, []);

  const onValidate = () => {
    if (props.goNext) props.goNext();
  };
  return (
    <Container>
      {props.activity.title && (
        <TextTitleContainer className="text-title-container">
         <Title level={2} title={props.activity.title} />
        </TextTitleContainer>
      )}
      <TextContainer className="text-activity-container">
        <TextColumn className="text-activity-column" dangerouslySetInnerHTML={{ __html: props.activity.textLeft }} />
        <TextColumn className="text-activity-column" dangerouslySetInnerHTML={{ __html: props.activity.textRight }} />
      </TextContainer>
      {props.goNext && (
        <StandardButton onClick={onValidate}>
          {props.nextBehaviour === "close" ? texts.close : texts.next}
        </StandardButton>
      )}
    </Container>
  );
};

export default observer(ActivityText1Component);
