import React, { useContext } from "react";
import { DebugContainer } from "./Debug.style";
import { useWindowSize, useWindowPixelRatio, useMUIbreakpointName } from "../../hooks";
import { StoreContext } from "../../context";
import { useHistory } from "react-router-dom";
import icon from "./delete.svg";
import { theme } from "../../theme";

const Debug: React.FC = () => {
  const { core } = useContext(StoreContext);
  const history = useHistory();
  const activeResetFunction =
    process.env.REACT_APP_SCORM_STORAGE !== "1.2" && process.env.REACT_APP_SCORM_STORAGE !== "2004";
  const debugReset = () => {
    void core.resetStore();
    history.push("/");
    window.location.reload();
  };
  return (
    <DebugContainer className="debug-bar" aria-hidden debug={process.env.REACT_APP_DEBUG === "true" ? true : false}>
      <button disabled={!activeResetFunction} onClick={activeResetFunction ? debugReset : undefined}>
        <img width="15px " src={icon} />
      </button>
      <div className="debug-infos">
        {"w_size:" + useWindowSize().width.toString() + "X" + useWindowSize().height.toString()}
        <br></br>
        {"p_ratio :" + useWindowPixelRatio().pixelRatio.toString()}
        <br></br>
        {"@media:" + useMUIbreakpointName()}
      </div>
    </DebugContainer>
  );
};
export default Debug;
