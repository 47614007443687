import { useState, useLayoutEffect } from "react";

const useWindowPixelRatio = () => {
  const [pixelRatio, setPixelRatio] = useState<{ pixelRatio: number }>({
    pixelRatio: window.devicePixelRatio,
  });

  const handleZoom = () => {
    setPixelRatio({
      pixelRatio: window.devicePixelRatio,
    });
  };

  useLayoutEffect(() => {
    handleZoom();
    window.addEventListener("resize", handleZoom);
    return () => window.removeEventListener("resize", handleZoom);
  }, []);

  return pixelRatio;
};

export default useWindowPixelRatio;
