import "react-app-polyfill/ie11";
import "react-app-polyfill/stable";
import React, { useEffect } from "react";
import ReactDOM from "react-dom";
//import "./index.scss";
import App from "./App";
import * as serviceWorker from "./serviceWorker";
import { HashRouter as Router } from "react-router-dom";
import "normalize.css";
import { StoreContext } from "./context";
import { store, core } from "./state";
import { IndexStyle } from "./index.style";
import { ThemeProvider } from "styled-components";
import { theme } from "./theme";
import { createInstance, MatomoProvider } from "@datapunt/matomo-tracker-react";
import { GLOBAL_PARAMS } from "./content";

const instance = GLOBAL_PARAMS["matomoTracking"]
  ? createInstance({
      urlBase: GLOBAL_PARAMS["matomoTracking"]["urlBase"],
      siteId: GLOBAL_PARAMS["matomoTracking"]["siteId"],
      configurations: {
        disableCookies: false,
        setSecureCookie: false,
      },
    })
  : undefined;

const matomoTrackingWrapper = (children: JSX.Element): JSX.Element => {
  if (instance) return <MatomoProvider value={instance}>{children}</MatomoProvider>;
  return children;
};

ReactDOM.render(
  <React.StrictMode>
    <StoreContext.Provider value={{ store, core }}>
      <ThemeProvider theme={theme}>
        <Router>
          <IndexStyle />
          {matomoTrackingWrapper(<App />)}
        </Router>
      </ThemeProvider>
    </StoreContext.Provider>
  </React.StrictMode>,
  document.getElementById("root"),
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
