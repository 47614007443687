import React from "react";
import { BoxListStyle } from "./BoxList.style";
import { BoxItem } from "..";
import { Box } from "../../types";
import { observer } from "mobx-react";
import ROUTES from "../../routes";
import { core } from "../../state";
import { Grid } from "@material-ui/core";
import { useMUIbreakpointName } from "../../hooks";

interface Props {
  boxList: Box[];
}

function computeLinkProps(box: Box) {
  if (core.isBoxlockedByThisActivity(box.id)) {
    return { to: `${ROUTES.ACTIVITY_LOCK_LIST.path}/${box.id}`, downloadLink: false };
  } else {
    switch (box.type) {
      case "menu":
        return { to: `${ROUTES.BOX.path}/${box.id}`, downloadLink: false };

      case "content":
        return { to: `${ROUTES.ACTIVITY_LIST.path}/${box.id}`, downloadLink: false };

      case "resource":
        return { to: box.resource.url, downloadLink: true };

      default:
        return { to: "", downloadLink: false };
    }
  }
}

const BoxList: React.FC<Props> = (props) => {
  const submenucontext = props.boxList.length <= 6;
  const currentBreakpoint = useMUIbreakpointName();
  const displayAsPortrait = ["xs", "sm"].includes(currentBreakpoint);

  return (
    <Grid component={"ul"} container spacing={2} className={"boxlist"} id={"box-list-grid"} style={BoxListStyle}>
      {props.boxList.map((box) => {
        const displayBox = !(box.type === "content" && box.hiddenFromMenu);
        if (!displayBox) return null;
        return (
          <Grid
            component={"li"}
            xs={6}
            hidden={displayAsPortrait && box.type === "empty"}
            md={4}
            item
            key={box.id}
            style={displayAsPortrait ? { order: 0 } : { order: box.desktopDisplayOrder }}
            className={`boxlist-item-container item-${box.id}`}
          >
            <BoxItem box={box} linkProps={computeLinkProps(box)} submenuContext={submenucontext} />
          </Grid>
        );
      })}
    </Grid>
  );
};

export default observer(BoxList);
