import React, { useContext } from "react";
import { StoreContext } from "../../context";
import { StandardCloseButtonStyle } from "./StandardCloseButton.style";

interface Props {
  onClick: () => any | string;
}
const StandardCloseButton: React.FC<Props> = (props) => {
  const { store } = useContext(StoreContext);
  return <StandardCloseButtonStyle autoFocus aria-label={store.interfaceText.close} onClick={props.onClick} />;
};

export default StandardCloseButton;
