import { action, computed, observable } from "mobx";
import { Content, Box, InterfaceText, ContentBox } from "../types";
import { AnswerResult, QuestionAggregate } from "@upandgo/react-quiz";
import { translatedContent } from "../content";
import { DEFAULT_LANGUAGE, LANGUAGES } from "../constants";
import translatedInterface from "../texts";
import {
  /* computeTotalSkippedTime */ getKeypointBoxList,
  getTotalBoxListDuration,
  getTotalSkippableTime,
  getUserSkippedTime,
} from "../utils/utils";

class Store {
  @observable
  lang: LANGUAGES;

  @observable
  currentBox?: Box;

  @computed
  get isFullScreen(): boolean {
    return (
      this.currentBox !== undefined && this.currentBox.type === "content" && this.currentBox.fullscreen !== undefined
    );
  }
  @computed
  get totalModuleDuration(): number {
    return getTotalBoxListDuration(this.content.boxList);
  }

  @computed
  get userSkippedTime(): number {
    const keypointList = getKeypointBoxList(this.content.boxList);
    return getUserSkippedTime(keypointList);
  }
  @computed
  get totalSkippableTime(): number {
    const keypointList = getKeypointBoxList(this.content.boxList);
    return getTotalSkippableTime(keypointList);
  }

  @computed
  get content(): Content {
    return translatedContent[this.lang];
  }

  @computed
  get interfaceText(): InterfaceText {
    return translatedInterface[this.lang];
  }

  @observable
  isFirstTime = false;

  @observable
  accessibilityMode = false;

  @observable
  successStatus: "passed" | "failed" | "unknown" = "unknown";

  @observable
  completionStatus: "completed" | "incomplete" | "not attempted" | "unknown" = "unknown";

  @observable
  objectiveList: {
    id: string;
    completionStatus: "completed" | "incomplete" | "not attempted" | "unknown";
    successStatus: "passed" | "failed" | "unknown";
    scoreRaw?: number;
    scoreMin?: number;
  }[] = [];

  @observable
  interactionList: { id: string; result: "correct" | "incorrect" | "unanticipated" | "neutral" }[] = [];

  @observable
  interactionChunk: { id: string; location?: string; suspend_data?: string}[] = [];

  @observable
  quizResults: {
    [key: string]: {
      quiz: QuestionAggregate;
      successStatus: "passed" | "failed" | "unknown";
      answerList: AnswerResult[];
      numberOfRightAnswers: number;
    };
  } = {};

  @observable
  skillTesterResults: {
    [key: string]: {
      failedQuestionIdList: string[];
    };
  } = {};
  constructor(defaultLanguage: LANGUAGES) {
    this.lang = defaultLanguage;
  }
}

export { Store };
