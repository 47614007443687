import React from "react";

import {
  CorrectionItemContainer,
  CorrectionItemTitle,
  CorrectionItemSubtitle,
  CorrectionItemAnswersContainer,
  ItemAnwer,
} from "./ResultCorrectionItem.style";

interface Props {
  isValid: boolean;
  index: number;
  question: string;
  userAnswers: string[];
  validAnswers: string[];
}

const ResultCorrectionItem: React.FC<Props> = (props) => {
  return (
    <CorrectionItemContainer isValid={props.isValid}>
      <CorrectionItemTitle isValid={props.isValid}>{`${props.index + 1} - ${props.question}`}</CorrectionItemTitle>
      <CorrectionItemSubtitle>{"Vous avez répondu"}:</CorrectionItemSubtitle>
      <CorrectionItemAnswersContainer>
        {props.userAnswers.map((answer) => (
          <ItemAnwer key={answer}>{`${props.validAnswers.includes(answer) ? "✓" : "✕"} ${answer}`}</ItemAnwer>
        ))}
      </CorrectionItemAnswersContainer>

      <CorrectionItemSubtitle>
        {props.validAnswers.length === 1 ? "La bonne réponse" : "Les bonnes réponses"}:
      </CorrectionItemSubtitle>

      <CorrectionItemAnswersContainer>
        {props.validAnswers.map((answer) => (
          <ItemAnwer key={answer}>{answer}</ItemAnwer>
        ))}
      </CorrectionItemAnswersContainer>
    </CorrectionItemContainer>
  );
};

export default ResultCorrectionItem;
