import React, { useContext } from "react";
import { Title } from "../index";
import {
  BoxItemStyle,
  BoxItemTitleContainerStyle,
  BoxItemIconContainerStyle,
  BoxItemIconStyle,
  BoxItemConnector,
} from "./BoxItem.style";
import { Box } from "../../types";
import { observer } from "mobx-react";
import { StoreContext } from "../../context";
import BoxItemBadge from "./BoxItemBadge.component";
import { store } from "../../state";
interface Props {
  box: Box;
  linkProps?: { to: string; downloadLink: boolean };
  submenuContext?: boolean;
}
const BoxItem: React.FC<Props> = (props) => {
  const { core } = useContext(StoreContext);
  const computeBoxStatus = (boxId: string) => {
    if (!core.isBoxNeededForCompletion(boxId)) return "untracked";
    if (core.isBoxLocked(boxId)) return "locked";
    if (core.isBoxPassed(boxId)) return "passed";
    if (core.isBoxFailed(boxId)) return "failed";
    if (core.isBoxComplete(boxId)) return "completed";
    if (core.isBoxlockedByThisActivity(boxId)) return "activity_locked";
    return "pending";
  };

  const status = computeBoxStatus(props.box.id);
  const isBoxDisabled = status === "locked" || props.box.type === "empty";
  return (
    <BoxItemStyle
      to={props.linkProps?.to && status !== "locked" ? props.linkProps?.to : "#"}
      download={props.linkProps?.downloadLink}
      target={props.linkProps?.downloadLink ? "_blank" : "_self"}
      style={{ textDecoration: "none" }}
      id={props.box.id}
      className={`box-item ${isBoxDisabled ? "disabled " : ""}${status} ${props.box.type}`}
      aria-disabled={status === "locked"}
      aria-hidden={props.box.type === "empty"}
      tabIndex={props.box.type === "empty" ? -1 : 0}
      boxId={props.box.id}
      title={status}
    >
      {props.box.icon && (
        <BoxItemIconContainerStyle
          className={"box-item-icon-container"}
          data-alone={props.box.title ? "false" : "true"}
        >
          <BoxItemIconStyle fullsize={props.box.type === "empty"} bg={props.box.icon} className={"box-item-icon"} />
        </BoxItemIconContainerStyle>
      )}
      {props.box.title && (
        <BoxItemTitleContainerStyle className={"box-item-title-container"}>
          <span role="presentation" style={{ opacity: 0, position: "absolute" }}>
            {store.interfaceText.accessibility?.boxItemStatus[status] ?? ""}
          </span>
          <Title
            customClass={"box-item-title"}
            boxId={props.box.id}
            level={props.submenuContext ? 3 : 2}
            title={props.box.title}
          />
        </BoxItemTitleContainerStyle>
      )}
      <BoxItemBadge status={status} />
      {props.box.boxConnectionList &&
        props.box.boxConnectionList.map((connector, index) => (
          <BoxItemConnector key={index} className={`direction_${connector}`} />
        ))}
    </BoxItemStyle>
  );
};

export default observer(BoxItem);
