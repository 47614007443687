import { styled, theme } from "../../theme";

export const SingleResourceContainer = styled.div`
  min-height: 70%;
`;
export const DownloadDisclamer = styled.p`
  text-align: center;
  width: 100%;
`;
export const DownloadAlternative = styled.p`
  text-align: center;
  width: 100%;
`;
export const DownloadCountDown = styled.p`
  text-align: center;
  width: 100%;
  font-size: 3rem;
`;

export const SingleResourceItemContainer = styled.div``;
