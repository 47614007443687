import { styled } from "../../theme";
import { MediaImage } from "../../types";

export const Container = styled.div<{ bg?: MediaImage | string }>`
  min-height: 100%;
  background: ${({ bg }) => (bg ? (typeof bg === "string" ? bg : `url("${bg.url}")`) : "none")};
  background-size: cover;
  background-position: center;
  display: flex;

  ${({ theme }) => theme.breakpoints.down("xs")} {
    border-radius: 20px 20px 0 0;
    box-sizing: border-box;
    max-height: initial;
    height: initial;

    &.base-activity_realQuiz {
      background-position: left;
    }
    &.base-activity_video {
      display: flex;
      align-items: center;
    }
  }
`;
