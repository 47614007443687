import React, { useContext, useEffect, useRef } from "react";
import { BaseActivityProps } from "../Activity/Activity.component";
import {
  Container,
  StorylineIframe,
  StorylineIframeContainer,
  StorylineTitleContainer,
} from "./ActivityScormStorylineWithQuiz.style";
import { ActivityScormStorylineWithQuiz } from "../../types";
import { observer } from "mobx-react";
import { StoreContext } from "../../context";
import Title from "../Title/Title.component";
import { useState } from "react";
import { useMediaQuery } from "@material-ui/core";
import { theme } from "../../theme";

interface Props extends BaseActivityProps {
  activity: ActivityScormStorylineWithQuiz;
}

//REMEMBER TO REPLACE SCORM DRIVERS
const ActivityScormStorylineWithQuizContainer: React.FC<Props> = (props) => {
  const { core, store } = useContext(StoreContext);
  const ref = useRef<HTMLDivElement>(null);
  const refIframe = useRef<HTMLIFrameElement>(null);
  const shouldDisplayMobile = useMediaQuery(theme.breakpoints.down("sm")) && props.activity.mobileUrl !== undefined;
  const shouldDisplayAccessible =
    !shouldDisplayMobile && props.activity.accessibleUrl !== undefined && store.accessibilityMode;
  const isTracked = props.activity.isNeededForCompletion || store.currentBox?.lockedByThisActivity;

  const [titleContainerHeight, setTitleContainerHeight] = useState(0);

  useEffect(() => {
    if (titleContainerHeight === 0) {
      setTitleContainerHeight(ref.current ? ref.current.offsetHeight : 0);
    }
  }, [ref]);

  const debugMode = process.env.REACT_APP_DEBUG === "true" ? true : false;

  useEffect(() => {
    if (isTracked && props.activity.tracking) {
      const handler = (event: {
        data: {
          action: string;
          variable: string;
          value: number | string;
        };
      }) => {
        debugMode && console.log(event);
        if (props.activity.tracking?.successCondition === "success" && event.data.action == "set") {
          if (typeof event.data.value === "number" && props.activity.tracking.setAsGlobalScore) {
            event.data.variable === "cmi.score.scaled" && void core.setScoreScaled(event.data.value);
            event.data.variable === "cmi.score.raw" && void core.setScoreRaw(event.data.value);
          }
          if (event.data.variable === "cmi.success_status") {
            event.data.value === "passed" && void core.setInteractionResult(props.activity.id, "correct");
            event.data.value === "failed" && void core.setInteractionResult(props.activity.id, "incorrect");
          }
          if (props.nextBehaviour === "close" && props.onEnd) {
            props.onEnd();
          }
        }
        if (event.data.action === "closeStoryline" && props.onClickClose !== undefined) {
          props.onClickClose();
        }
        if (event.data.action === "nextActivity" && props.goNext !== undefined) {
          props.goNext();
        }
        if (event.data.action === "previousActivity" && props.goPrevious !== undefined) {
          props.goPrevious();
        }
      };
      const iframe = refIframe.current?.contentWindow;
      if (iframe) {
        iframe.addEventListener("message", handler, false);
        return () => {
          iframe.removeEventListener("message", handler);
        };
      }
    }
  }, []); // empty array => run only once

  return (
    <Container className="activity-storyline-container">
      {props.activity.title && (
        <StorylineTitleContainer ref={ref} className="storyline-title-container">
          <Title level={2} title={props.activity.title} />
        </StorylineTitleContainer>
      )}
      <StorylineIframeContainer titleHeight={titleContainerHeight} className="storyline-iframe-container">
        {shouldDisplayAccessible && (
          <StorylineIframe
            className="storyline-iframe-accessible"
            title={props.activity.title}
            allowFullScreen={true}
            src={props.activity.accessibleUrl}
            ref={refIframe}
            tabIndex={0}
          />
        )}
        {shouldDisplayMobile && !shouldDisplayAccessible && (
          <StorylineIframe
            className="storyline-iframe-mobile"
            title={props.activity.title}
            allowFullScreen={true}
            src={props.activity.mobileUrl}
            ref={refIframe}
            tabIndex={0}
          />
        )}
        {!shouldDisplayMobile && !shouldDisplayAccessible && (
          <StorylineIframe
            className="storyline-iframe-desktop"
            title={props.activity.title}
            allowFullScreen={true}
            src={props.activity.desktopUrl}
            ref={refIframe}
            tabIndex={0}
          />
        )}
      </StorylineIframeContainer>
    </Container>
  );
};

export default observer(ActivityScormStorylineWithQuizContainer);
