import { styled } from "../../theme";
//TODO use full height in desktop case
export const Video = styled.video`
  max-width: 100%;
  max-height: 100%;
  object-fit: contain; // Be careful on iPads with this prop
  ${({ theme }) => theme.breakpoints.down("sm")} {
    height: initial;
  }
`;

export const Image = styled.img`
  max-width: 100%;
  max-height: 100%;
  object-fit: contain; // Be careful on iPads with this prop
`;
