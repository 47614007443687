import { SQUARE_WIDTH, styled } from "../../theme";

export const MainMenuStyle = styled.div`
  width: 82vw;
  box-sizing: border-box;
  flex-grow: 1;
  display: flex;
  margin-left: auto;
  margin-right: auto;
  flex-direction: row;
  justify-content: flex-end;
  ${({ theme }) => theme.breakpoints.down("sm")} {
    width: 95vw;
    flex-direction: column;
    justify-content: center;
    height: inherit;
    padding-right: inherit;
  }
`;

export const ImgContainer = styled.div`
  flex-grow: 1;
`;

export const MainMenuWrapper = styled.div`
  box-sizing: border-box;
  width: ${SQUARE_WIDTH};
  position: relative;
  ${({ theme }) => theme.breakpoints.down("sm")} {
    width: 100%;
  }
`;
