import { SQUARE_WIDTH, TARGETED_HEADER_HEIGHT, styled } from "../../theme";
import { MediaImage } from "../../types";

export const HeaderContainer = styled.header<{ hidden?: boolean }>`
  width: 100%;
  overflow: ${({ hidden }) => (hidden ? "hidden" : "initial")};
  box-sizing: border-box;
  min-height: ${({ hidden }) => (hidden ? "0vh" : TARGETED_HEADER_HEIGHT)};
  display: flex;
  flex-wrap: no-wrap;
  justify-content: center;
  z-index: 5;
  position: relative;
  ${({ theme }) => theme.breakpoints.down("sm")} {
    padding-right: 0;
    padding-left: 0;
  }
`;

export const HeaderWrapper = styled.div<{ hidden?: boolean }>`
  width: calc(100vw * 0.82);
  overflow: ${({ hidden }) => (hidden ? "hidden" : "initial")};
  box-sizing: border-box;
  display: flex;
  flex-wrap: no-wrap;
  justify-content: space-between;
  z-index: 5;

  ${({ theme }) => theme.breakpoints.down("sm")} {
    padding-right: 0;
    padding-left: 0;
    flex-direction: column;
    justify-content: start;
    align-items: center;
    width: 100%;
    background-color: ${({ theme }) => theme.color.homeBackground};
  }
`;

export const HeaderLanguageSelectorContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  position: absolute;
  right: 2%;
  height: 100%;
  ${({ theme }) => theme.breakpoints.down("sm")} {
    max-width: 30%;
    display: none;
  }
  &.mobile {
    display: none;
    ${({ theme }) => theme.breakpoints.down("sm")} {
      display: none;
      position: relative;
      flex-direction: row-reverse;
      width: 100%;
      justify-content: flex-start;
      align-self: flex-end;
      height: inherit;
      .language-selector-flag-container {
        width: 1.5em;
        height: 1.5em;
      }
    }
  }
`;

export const HeaderRightContainer = styled.div<{ bg: string }>`
  display: flex;
  box-sizing: border-box;
  flex-direction: row;
  justify-content: flex-end;
  ${({ theme }) => theme.breakpoints.down("sm")} {
    flex-direction: column;
    ${({ bg }) => `background-color: ${bg}`};
    width: 100%;
    height: 100%;
  }
`;

export const HeaderCenterContainer = styled.div`
  display: none;
`;

export const HeaderLeftContainer = styled.div<{ bgUrl: string }>`
  display: flex;
  flex-direction: row;
  box-sizing: border-box;
  justify-content: flex-start;
  max-width: 30%;
  flex-grow: 1;
  margin: 1em 0;
  background-size: contain;
  background-repeat: no-repeat;
  background-position: 0 50%;
  background-image: url(${(props) => props.bgUrl});
  ${({ theme }) => theme.breakpoints.down("sm")} {
    height: 15vh;
    max-width: 60%;
    width: 100%;
    background-position: center;
  }
`;

export const HeaderLogoContainerStyle = styled.a`
  display: block;
  box-sizing: border-box;
  width: 100%;
`;

export const HeaderTitleContainerStyle = styled.div`
  width: ${SQUARE_WIDTH};
  box-sizing: border-box;
  padding: 1em;
  color: ${({ theme }) => theme.color.mainTitle};
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  h1 {
    font-family: ${({ theme }) => theme.font.mainFont.name};
  }
  ${({ theme }) => theme.breakpoints.down("sm")} {
    width: 100%;
    padding: 0em;
  }
`;

export const HeaderBackground = styled.div<{ bg: string | MediaImage }>`
  min-width: 100%;
  min-height: 100%;
  ${({ bg }) =>
    typeof bg === "string" ? `background-color: ${bg}` : `background-image: url(${bg.mobileUrl ?? bg.url})`};
  background-size: contain;
  background-position: center;
  background-repeat: no-repeat;
  height: 100%;
  width: 100%;
  z-index: -2;
`;
