import { styled } from "../../theme";

export const Container = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;

  @media (max-width: ${({ theme }) => theme.breakpoints.down("xs")}) {
    height: 70vh;
  }
`;
export const StorylineIframeContainer = styled.div<{ titleHeight?: number }>`
  width: 100%;
  position: relative;
`;

export const StorylineIframe = styled.iframe`
  border: none;
  box-sizing: border-box;
  width: 100%;
  aspect-ratio: 16/9;
  display: flex;

  ${({ theme }) => theme.breakpoints.down("xs")} {
    border-radius: 20px 20px 0 0;
    aspect-ratio: unset;
    height: 95svh;
  }
`;

export const StorylineTitleContainer = styled.div`
  min-height: 10%;
`;
