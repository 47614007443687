import { styled } from "../../theme";

interface ColumnContainerProps {
  reverse?: boolean;
}

export const Container = styled.div`
  max-width: 60%;
  display: flex;
  flex-direction: column;
  align-items: center;
  height: 100%;
  margin-right: auto;
  margin-left: auto;
  ${({ theme }) => theme.breakpoints.down("sm")} {
    max-width: 100%;
  }
`;

export const ColumnContainer = styled.div<ColumnContainerProps>`
  display: flex;
  flex-direction: ${({ reverse }) => (reverse ? "row-reverse" : "row")};
  align-items: center;
  flex-wrap: wrap;
  box-sizing: border-box;
  height: 100%;
  width: 100%;
  ${({ theme }) => theme.breakpoints.down("sm")} {
    height: initial;
    min-height: 100%;
    justify-content: flex-start;
    align-items: flex-start;
  }
`;
export const QuizStatusContainer = styled.div``;
export const QuizStatusText = styled.p`
  color: ${({ theme }) => theme.color.mainTitle};
  font-family: ${({ theme }) => theme.font.styledFont.name};
  text-align: center;
  font-weight: bold;
  font-size: 5rem;
  margin: 0;
`;

export const ScoreContainer = styled.div`
  display: flex;
  font-size: 2.7rem;
  flex-direction: row;
  justify-content: center;
  box-sizing: border-box;
  text-align: center;
  color: ${({ theme }) => theme.color.text};
  ${({ theme }) => theme.breakpoints.up("lg")} {
    width: 100%;
    max-width: 100%;
    min-width: 100%;
  }
`;
export const ScoreBlock = styled.span`
  box-sizing: border-box;
`;
export const ScoreLabel = styled(ScoreBlock)`
  font-family: ${({ theme }) => theme.font.mainFont.name};
  color: ${({ theme }) => theme.color.text};
  text-transform: none !important;
  font-weight: normal;
  text-align: center;
  display: block;
  font-stretch: condensed;
  margin-bottom: 1em;
  ${({ theme }) => theme.breakpoints.up("lg")} {
    width: 100%;
    max-width: 100%;
    min-width: 100%;
  }
`;
export const ScoreNumber = styled(ScoreBlock)``;
export const ScoreUnit = styled(ScoreBlock)`
  display: inline;
`;

export const Column = styled.div<{ fullWidth: boolean; detail: boolean }>`
  width: ${({ fullWidth }) => (fullWidth ? "100%" : "50%")};
  max-width: ${({ fullWidth }) => (fullWidth ? "100%" : "50%")};
  min-width: ${({ fullWidth }) => (fullWidth ? "100%" : "50%")};
  padding: 1em;
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
  min-height: 100%;
  height: 100%;
  justify-content: center;
  align-items: center;
  &.column-result-content {
    justify-content: ${({ detail }) => (detail ? "start" : "center")};
  }
  &.column-certificate-content {
  }
  ${({ theme }) => theme.breakpoints.down("sm")} {
    width: 100%;
    max-width: 100%;
    min-width: 100%;
  }
`;

export const QuizResultTextContainer = styled.div`
  font-family: ${({ theme }) => theme.font.mainFont.name};
  color: ${({ theme }) => theme.color.text};
  text-transform: none !important;
  font-weight: normal;
  text-align: center;
  font-stretch: condensed;

  .result-text {
    text-align: center;
    margin-top: 1em;
    margin-bottom: 1em;
  }
  p {
    margin-top: 1em;
    margin-bottom: 1em;
    min-width: 70%;
    ${({ theme }) => theme.breakpoints.down("sm")} {
      min-width: 100%;
    }
  }
`;
export const QuizResultButtonContainer = styled.div`
  box-sizing: border-box;
  display: flex;
  flex-direction: row;
  justify-content: space-around;
  align-items: center;
  margin-top: 3em;
  width: 100%;
  font-family: ${({ theme }) => theme.font.styledFont.name};
  button {
    width: 40%;
    background-color: ${({ theme }) => theme.color.mainTitle};
    text-transform: uppercase;
    border-radius: 25px;
    &.back-to-home {
      background-color: #fff;
      color: ${({ theme }) => theme.color.mainTitle};
    }
    ${({ theme }) => theme.breakpoints.down("sm")} {
      width: 80%;
      margin-top: 5%;
    }
  }
  ${({ theme }) => theme.breakpoints.down("sm")} {
    flex-direction: column;
  }
`;

export const CertificateTitleContainer = styled.div`
  width: 100%;
  display: flex;
  justify-content: center;
`;
export const CertificateContainer = styled.div`
  width: 70%;
`;
export const QuizResultTitleContainer = styled.div``;
export const ThresholdText = styled.div`
  padding-top: 4em;
`;

export const DetailText = styled.div`
  padding-top: 2em;
  font-size: 2.4rem;
  text-decoration: underline;
  :hover {
    cursor: pointer;
  }
`;
export const ResultCorrectionContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
`;
export const ResultCorrectionItemContainer = styled.div`
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
`;
