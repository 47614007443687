import React, { useRef, useState, useEffect } from "react";
import FocusTrap from "focus-trap-react";
import MuiAlert from "@material-ui/lab/Alert";
import { useHistory } from "react-router-dom";

import { Title, BoxList } from "../index";
import { core, store } from "../../state";
import {
  SubMenuStyle,
  SubMenuContainerStyle,
  SubMenuTitleContainerStyle,
  SubMenuObjectiveContainerStyle,
  SubMenuObjectiveTextStyle,
  SubMenuSectionStyle,
  SubMenuBoxItemContainerStyle,
  CloseButtonContainer,
  SubMenuWrapper,
  CtaContainer,
} from "./SubMenu.style";
import { MenuBox } from "../../types";
import StandardCloseButtonComponent from "../StandardCloseButton/StandardCloseButton.component";
import ROUTES from "../../routes";

interface Props {
  parentBox: MenuBox;
}

const Alert = (props: any) => {
  return <MuiAlert elevation={6} variant="filled" {...props} />;
};

const SubMenu: React.FC<Props> = (props) => {
  const history = useHistory();

  const onClickClose = () => {
    if (store.currentBox) {
      const parentBox = core.getBoxParent(store.currentBox.id, store.content.boxList);
      history.push(parentBox ? `${ROUTES.BOX.path}//${parentBox.id}` : "/");
    }
  };

  const allActivitiesComplete = () => {
    if (store.currentBox) {
      return core.isBoxComplete(store.currentBox.id);
    }
    return false;
  };

  return (
    <SubMenuStyle className={`submenu_${props.parentBox.id}`}>
      <FocusTrap>
        <SubMenuWrapper className={"submenu-wrapper"}>
          <SubMenuSectionStyle className={"submenu-section"}>
            <SubMenuContainerStyle className={"submenu-container"}>
              <CloseButtonContainer className="sub-menu-close-button-container">
                <StandardCloseButtonComponent onClick={onClickClose} />
              </CloseButtonContainer>
              {allActivitiesComplete() && store.interfaceText.subMenu ? (
                <CtaContainer>
                  <Alert severity="success" sx={{ width: "100%" }}>
                    <Title level={3} title={store.interfaceText.subMenu.allActivitiesCompleted} />
                  </Alert>
                </CtaContainer>
              ) : null}
              <SubMenuTitleContainerStyle className={"submenu-title-container"}>
                {props.parentBox.title && (
                  <Title customClass={"submenu-title"} level={2} title={props.parentBox.title} />
                )}
              </SubMenuTitleContainerStyle>
              <SubMenuTitleContainerStyle className={"submenu-title-container"}></SubMenuTitleContainerStyle>
              {props.parentBox.objectiveText && (
                <SubMenuObjectiveContainerStyle className={"submenu-objective-container"}>
                  <SubMenuObjectiveTextStyle className={"submenu-objective"}>
                    {props.parentBox.objectiveText}
                  </SubMenuObjectiveTextStyle>
                </SubMenuObjectiveContainerStyle>
              )}
              <SubMenuBoxItemContainerStyle role="navigation" className={"submenu-boxlist-container"}>
                <BoxList boxList={props.parentBox.boxList}></BoxList>
              </SubMenuBoxItemContainerStyle>
            </SubMenuContainerStyle>
          </SubMenuSectionStyle>
        </SubMenuWrapper>
      </FocusTrap>
    </SubMenuStyle>
  );
};

export default SubMenu;
