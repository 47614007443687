interface Route {
  path: string;
}

const ACTIVITY_LIST: Route = {
  path: "/activity-list",
};
const ACTIVITY_LOCK_LIST: Route = {
  path: "/activity-lock",
};

const BOX: Route = {
  path: "/box",
};

const HOME: Route = {
  path: "/",
};

const GO_BACK: Route = {
  path: "/go-back",
};

const ROUTES = { ACTIVITY_LIST, ACTIVITY_LOCK_LIST, BOX, HOME, GO_BACK };

export default ROUTES;
