import { styled } from "../../theme";

export const DebugContainer = styled.div<{ debug: boolean }>`
  display: ${({ debug }) => (debug ? "flex" : "none")};
  font-family: "Poppins-Light" !important;
  font-weight: normal;
  flex-direction: row;
  align-items: center;
  position: absolute;
  z-index: 9;
  color: black;
  left: 0;
  bottom: 1em;
  background-color: red;
  border-radius: 2em;
  width: 15%;
  padding: 0.2em;
  button {
    border: none;
    background-color: red;
    border-radius: 2em;
    &:hover {
      cursor: pointer;
    }
  }
  .debug-infos {
    color: white;
    font-family: Arial, Helvetica, sans-serif;
    font-size: 0.8em;
  }
  ${({ theme }) => theme.breakpoints.down("xs")} {
    right: 0;
    left: 0;
    top: 20%;
    width: 20em;
    margin-right: auto;
    margin-left: auto;
  }
`;
