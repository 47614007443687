import React, { useContext, useEffect } from "react";
import { StandardResourceItem } from "..";
import { ActivityResource } from "../../types";
import {
  ResourceListContainer,
  ItemContainer,
  Container,
  ResourceListTitleContainer,
  ResourceListIconContainer,
  ResourceIcon,
} from "./ActivityResourceList.style";
import { BaseActivityProps } from "../Activity/Activity.component";
import Title from "../Title/Title.component";
import {} from "../StandardResourceItem/StandardResourceItem.style";
import { StoreContext } from "../../context";
import SingleResourceComponent from "./SingleResource.component";
import { Grid, useMediaQuery } from "@material-ui/core";
import { theme } from "../../theme";
interface Props extends BaseActivityProps {
  activity: ActivityResource;
}

const ActivityResourceListComponent: React.FC<Props> = (props) => {
  const { core, store } = useContext(StoreContext);
  const isTracked = props.activity.isNeededForCompletion || store.currentBox?.lockedByThisActivity;
  const setActivityCompletionAccordingToCompletionCondition = () => {
    if (isTracked && props.activity.tracking) {
      if (props.activity.tracking.completeCondition === "open") {
        void core.setInteractionCompletion(props.activity.id);
      }
    }
  };
  const spacing = useMediaQuery(theme.breakpoints.down("sm")) ? 2 : 1;
  useEffect(() => setActivityCompletionAccordingToCompletionCondition(), []);
  return (
    <React.Fragment>
      <Container className="resources-container">
        {props.activity.icon && (
          <ResourceListIconContainer className={"resource-icon-container"}>
            <ResourceIcon bg={props.activity.icon} className={"resource-icon"}></ResourceIcon>
          </ResourceListIconContainer>
        )}
        {props.activity.title && (
          <ResourceListTitleContainer className="resources-title-container">
            {props.activity.title && <Title fontColor={props.activity.color} level={1} title={props.activity.title} />}
          </ResourceListTitleContainer>
        )}
        <ResourceListContainer>
          {props.activity.resourceList.length > 1 && (
            <Grid
              spacing={spacing}
              container
              component={"ul"}
              className="resourceList-container"
              alignItems="center"
              justifyContent="center"
              alignContent="center"
              style={{ listStyleType: "none", padding: 0 }}
            >
              {props.activity.resourceList.map((item) => (
                <Grid item xs={12} md={6} key={item.id} component={"li"}>
                  <ItemContainer>
                    <StandardResourceItem resource={item} />
                  </ItemContainer>
                </Grid>
              ))}
            </Grid>
          )}
        </ResourceListContainer>
        {props.activity.resourceList.length === 1 && <SingleResourceComponent activity={props.activity} />}
      </Container>
    </React.Fragment>
  );
};

export default ActivityResourceListComponent;
