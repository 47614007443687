import { styled, theme } from "../../theme";

const renderIcon = (type: string) => {
  if (type === "pdf") {
    return theme.icons.resourcePdfFile;
  }
  if (type === "hyperlink") {
    return theme.icons.resourceHyperLink;
  } else {
    return theme.icons.resourceDefaultIcon;
  }
};

export const ResourceItemContainer = styled.a`
  box-sizing: border-box;
  min-width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items:center;
  min-height: 100%;
  color: ${(props) => props.color || props.theme.color.text};
  text-decoration: none;
`;

export const ResourceIconContainer = styled.div`
  width: 10%;
  min-height: 100%;
  position: relative;
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-content: center;
  align-items: center;
  ${({ theme }) => theme.breakpoints.down("sm")} {
    width: 13%;
    justify-content: center;
  }
`;

export const ResourceIcon = styled.div<{ resourceType: string }>`
  width: 3em;
  height: 3em;
  /* border:1px dotted red; */
  margin-right:1em;
  background-image: ${({ resourceType }) => `url(${renderIcon(resourceType)})`};
  background-size: 100%;
  background-repeat: no-repeat;
  background-position: center;
  display: block;
  ${({ theme }) => theme.breakpoints.down("sm")} {
    width: 1.5em;
    height: 1.5em;
  }
`;
export const ResourceInfoContainer = styled.div`
  box-sizing: border-box;
  width: 85%;
  min-height: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  flex-direction: column;
  ${({ theme }) => theme.breakpoints.down("sm")} {
    justify-content: center;
  }
`;

export const ResourceName = styled.div`
  box-sizing: border-box;
  font-weight: bold;
  ${({ theme }) => theme.breakpoints.down("sm")} {
    font-size: 1.5rem;
    padding-top: 0.2em;
  }
`;

export const ResourceDescription = styled.div`
  box-sizing: border-box;
`;
