import React, { useContext, useEffect } from "react";
import { observer } from "mobx-react-lite";
import { useParams } from "react-router-dom";

import { StoreContext } from "../context";
import { ActivityList } from "../components";
import { Modal } from "@material-ui/core";

const ActivityListPage: React.FC = () => {
  const { store, core } = useContext(StoreContext);
  const { boxId } = useParams<{ boxId: string }>();

  const currentBox = core.getBox(boxId, store.content.boxList);

  useEffect(() => {
    core.setCurrentBox(currentBox);

    return () => core.setCurrentBox(undefined);
  }, [boxId]);

  if (!currentBox || currentBox.type !== "content") return <div>No current box selected for activityList route</div>;

  const activityList = currentBox.activityList;
  const background = currentBox.background;
  return (
    <Modal open style={{ overflow: "auto" }}>
      <ActivityList box={currentBox} activityList={activityList} background={background} />
    </Modal>
  );
};

export default observer(ActivityListPage);
