import { styled } from "../../theme";

export const H1 = styled.h1<{ fontColor?: string }>`
  font-family: ${({ theme }) => theme.font.mainFont.name};
  font-weight: normal;
  font-size: 3.4rem;
  text-align: center;
  margin: 0;
  color: ${({ fontColor }) => (fontColor ? fontColor : "")};
  ${({ theme }) => theme.breakpoints.down("xs")} {
    font-size: 2.7rem;
    max-width: inherit;
  }
  .title_item {
    display: block;
  }
`;

export const H2 = styled.h2<{ boxId?: string; fontColor?: string }>`
  color: ${({ theme, boxId, fontColor }) =>
    (boxId && theme.color.boxObject[`${boxId}`] && theme.color.boxObject[`${boxId}`].titleColor) ?? fontColor};
  font-family: ${({ theme }) => theme.font.styledFont.name};
  font-size: 2.6rem;
  margin: 0;
  .title_item {
    display: block;
  }
  ${({ theme }) => theme.breakpoints.down("xs")} {
    font-size: 2rem;
    max-width: inherit;
  }
`;
export const H3 = styled.h3<{ boxId?: string; fontColor?: string }>`
  color: ${({ theme, boxId, fontColor }) =>
    (boxId && theme.color.boxObject[`${boxId}`] && theme.color.boxObject[`${boxId}`].titleColor) ?? fontColor};
  font-family: ${({ theme }) => theme.font.styledFont.name};
  font-size: 2.8rem;
  margin: 0;
  .title_item {
    display: block;
  }
  ${({ theme }) => theme.breakpoints.down("lg")} {
    font-size: 1.7rem;
    max-width: inherit;
  }
`;
