import { styled } from "../../theme";
import { lightenColor } from "../../utils/utils";

export const NavigationButton = styled.button<{ reverseDirection: boolean }>`
  border: solid 1px;
  border-color: ${({ theme }) => theme.color.mainTitle};
  background-color: ${({ theme }) => theme.color.mainTitle};
  background-image: url(${({ theme }) => theme.icons.navigationArrow});
  background-position: center;
  background-size: 50%;
  background-repeat: no-repeat;
  padding: 0.5em 1em 0.5em 1em;
  width: 3em;
  height: 3em;
  align-self: center;
  transform: ${(props) => (props.reverseDirection ? "scaleX(-1)" : "scaleX(1)")};
  border-radius: 0.25em;
  transition: all 0.2s;
  cursor: pointer;
  box-sizing: border-box;
  :hover {
    box-shadow: 0px 0px 7px 3px rgba(0, 0, 0, 0.2);
  }
  :focus:not(:focus-visible) {
    border: none;
    outline: none;
    box-shadow: inherit;
  }
  :focus-visible {
    border: none;
    outline: none;
    box-shadow: 0px 0px 0px 0.5em ${({ theme }) => theme.color.focus};
  }
  ${({ theme }) => theme.breakpoints.down("sm")} {
    position: fixed;
    bottom: 1em;
    right: ${(props) => (props.reverseDirection ? "20%" : "initial")};
    left: ${(props) => (props.reverseDirection ? "initial" : "20%")};
  }
`;
