import React from "react";
import { H1, H2, H3 } from "./Title.style";
import { spanify } from "../../utils/utils";

interface TitleInterface {
  level: 1 | 2 | 3 | 4 | 5 | 6;
  fontColor?: string;
  customClass?: string;
  title: string;
  boxId?: string;
  style?: React.CSSProperties;
}

const Title: React.FC<TitleInterface> = (props) => {
  if (props.level === 1) {
    return (
      <H1
        style={props.style}
        fontColor={props.fontColor}
        className={`title_level_${props.level} ${props.customClass || ""} `}
      >
        {spanify(props.title, "title_item")}
      </H1>
    );
  }
  if (props.level === 2) {
    return (
      <H2
        boxId={props.boxId}
        fontColor={props.fontColor}
        className={`title_level_${props.level} ${props.customClass || ""}`}
        style={props.style}
      >
        {spanify(props.title, "title_item")}
      </H2>
    );
  }
  if (props.level === 3) {
    return (
      <H3
        boxId={props.boxId}
        fontColor={props.fontColor}
        className={`title_level_${props.level} ${props.customClass || ""}`}
        style={props.style}
      >
        {spanify(props.title, "title_item")}
      </H3>
    );
  }

  return null;
};

export default Title;
