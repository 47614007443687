import React from "react";

import {
  BoxItemCompletedBadgeStyle,
  BoxItemLockedBadgeStyle,
  BoxItemPassedBadgeStyle,
  BoxItemFailedBadgeStyle,
  BoxItemActivityLockedBadgeStyle,
} from "./BoxItemBadge.style";

interface Props {
  status: "untracked" | "locked" | "completed" | "passed" | "failed" | "pending" | "activity_locked";
}
const BoxItemBadge: React.FC<Props> = (props) => {
  return (
    <React.Fragment>
      {props.status === "activity_locked" && (
        <BoxItemActivityLockedBadgeStyle className={"box-item-badge badge-activity_locked"} />
      )}
      {props.status === "locked" && <BoxItemLockedBadgeStyle className={"box-item-badge badge-locked"} />}
      {props.status === "completed" && <BoxItemCompletedBadgeStyle className={"box-item-badge badge-completed"} />}
      {props.status === "passed" && <BoxItemPassedBadgeStyle className={"box-item-badge badge-passed"} />}
      {props.status === "failed" && <BoxItemFailedBadgeStyle className={"box-item-badge badge-failed"} />}
    </React.Fragment>
  );
};

export default BoxItemBadge;
