import { LANGUAGES } from "../constants";
import { Content } from "../types";
import { constructQuestionId } from "../utils/formatter";

const asset = (url: string) => process.env.PUBLIC_URL + url;
const content: Content = {
  title: "Digibox avec///SkillTester DUMMY",
  language: LANGUAGES.FR,
  overrideCookieCompliance: {
    title: "je suis overridé",
  },
  backgroundMedia: {
    type: "image",
    url: asset("/assets/content/backgrounds/home_background.jpg"),
  },
  footerLeft: [
    { id: "legals", text: "Mentions légales", content: "dzdzq" },
    {
      id: "cookie",
      text: "À propos des cookies",
      content:
        "<h1>Notre site utilise des cookies de performances</h1> <p>Ces cookies nous permettent de déterminer le nombre de visites et les sources du trafic, afin de mesurer et d’améliorer les performances de notre site Web. Ils nous aident également à identifier les pages les plus / moins visitées et d’évaluer comment les visiteurs naviguent sur le site Web. Toutes les informations collectées par ces cookies sont agrégées et donc anonymisées. Si vous n'acceptez pas ces cookies, nous ne serons pas informés de votre visite sur notre site.</p> ",
    },
  ],
  footerRight: [
    {
      id: "upandgo",
      image: {
        type: "image",
        url: asset("/assets/branding/logo_upandgo.png"),
        alt: "upandgo ",
      },
      accessibleLabel: "Vers site web Upandgo",
      url: "https://www.upandgo-rh.fr/",
    },
  ],
  footerBackground: "#FFF",
  logo: asset("/assets/branding/upandgo.svg"),
  boxList: [
    {
      id: "boxA",
      desktopDisplayOrder: 1,
      title: "box1a",
      type: "content",
      estimatedTime: 0,
      activityList: [
        {
          id: "activity_boxA",
          type: "scormStorylineWithQuiz",
          background: "#caca",
          isNeededForCompletion: true,
          tracking: { successCondition: "success" },
          desktopUrl: asset("/assets/content/boxA/index_lms.html"),
        },
      ],
    },
    {
      id: "boxB",
      desktopDisplayOrder: 2,
      title: "box2b",
      type: "content",
      estimatedTime: 0,
      activityList: [
        {
          id: "activity_boxB",
          type: "webStoryline",
          background: "#caca",
          isNeededForCompletion: true,
          tracking: { completeCondition: "complete" },
          desktopUrl: asset("/assets/content/boxB//story.html"),
        },
      ],
    },
    {
      id: "boxC",
      desktopDisplayOrder: 3,
      title: "box3c",
      type: "content",
      estimatedTime: 0,
      activityList: [
        {
          id: "activity_boxC",
          type: "realQuiz",
          embedNavigation: true,
          intro: {
            title: "Quiz de TEST",
            rules: "consigne / régles...",
            objective: "score à atteindre, objectif...",
            startQuiz: "bouton commencer le quiz",
          },
          validatingThreshold: 9,
          quiz: {
            id: "A COMPLETER_activity_quiz",
            questionList: [
              {
                type: "multipleAnswer",
                id: "q0",
                context: { title: "Question 1/3" },
                showEachAnswerResults: true,
                instructions: "Plusieurs possible",
                question: "Le texte de la question 1 ",
                answerList: [
                  { id: "q0-a0", text: "Oui - bonne réponse", valid: true },
                  { id: "q0-a1", text: "Non - mauvaise réponse", valid: false },
                  { id: "q0-a2", text: "Peut être - bonne réponse", valid: true },
                ],
              },
              {
                type: "singleAnswer",
                id: "q1",
                context: { title: "Question 2/3" },
                showEachAnswerResults: true,
                instructions: "Une seule réponse possible",
                question: "Le texte de la question 2 ",
                answerList: [
                  { id: "q1-a0", text: "Oui - bonne réponse", valid: true },
                  { id: "q1-a1", text: "Non - mauvaise réponse", valid: false },
                ],
              },
              {
                type: "singleAnswer",
                id: "q2",
                context: { title: "Question 3/3" },
                showEachAnswerResults: true,
                instructions: "Une seule réponse possible",
                question: "Le texte de la question 3",
                answerList: [
                  { id: "q2-a0", text: "Oui - bonne réponse", valid: true },
                  { id: "q2-a1", text: "Non - mauvaise réponse", valid: false },
                ],
              },
              {
                type: "singleAnswer",
                id: "AJOUTEZ ACTIVITE QUIZ RESULT ET SUPPRIMEZ MOI",
                context: { title: "AJOUTEZ ACTIVITE QUIZ RESULT ET SUPPRIMEZ MOI" },
                showEachAnswerResults: true,
                instructions: "AJOUTEZ ACTIVITE QUIZ RESULT ET SUPPRIMEZ MOI",
                question: "AJOUTEZ ACTIVITE QUIZ RESULT ET SUPPRIMEZ MOI",
                feedback: {
                  display: "under",
                  title: "Ajouter l'activité suivante",
                  description:
                    '{"id":"COMPLETER_activity_quiz_result","relatedQuiz":"COMPLETER_activity_quiz_ID","embedNavigation":true,"type":"quizResult","scoreExpression":"percentage","fail":{"status":"Désolé...","text":" Vous n\'avez pas atteint le score de 75% requis pour valider la formation."},"success":{"status":"Bravo !","text":"Vous êtes incollable !"}}',
                },
                answerList: [
                  { id: "q2-a0", text: "COMMENT FAIRE ?", valid: true },
                  { id: "q2-a1", text: "COMMENT FAIRE ?", valid: false },
                ],
              },
            ],
          },
        },
      ],
    },
    {
      id: "box1",
      type: "content",
      title: "Box 1",
      desktopDisplayOrder: 1,
      estimatedTime: 9,
      keypoint: {
        threshold: 95,
        description: "une courte description de l'objectif pédagogique a atteindre sur la box_1",
      },
      activityList: [
        {
          id: "box1-activity",
          type: "embeddedScorm",
          background: {
            type: "image",
            url: asset("/assets/content/backgrounds/quiz-background.jpg"),
          },
          isNeededForCompletion: true,
          tracking: {
            completeCondition: "complete",
          },
          desktopUrl: asset("/assets/content/webrise/GMT_scorm/scormdriver/indexAPI.html"),
        },
      ],
    },
    {
      id: "box2",
      type: "content",
      title: "Box 2",
      desktopDisplayOrder: 2,
      estimatedTime: 10,
      keypoint: {
        threshold: 95,
        description: "Du lorem ipsum ? Pourquoi pas?",
      },
      activityList: [
        {
          title: "Tracking de base et marque page",
          id: "box2-activity",
          background: {
            type: "image",
            url: asset("/assets/content/backgrounds/quiz-background.jpg"),
          },
          type: "multimedia1",
          isNeededForCompletion: true,
          tracking: {
            completeCondition: "open",
          },
          media: { type: "image", url: "https://dummyimage.com/550x550/000/fff" },
          text: "<div class='free-text-block'><p>Au fur et à mesure que vous consultez les cases de votre Digibox, son parcours se déverrouille. <p>En fermant cette case, une coche verte apparaitra pour signaler que vous avez suivi le contenu de celle-ci.</p><p>Nous allons maintenant vérifier les informations remontées par votre LMS pour le suivi de vos apprenants : retournez sur la page du questionnaire que vous avez laissée ouverte.</p></div>",
        },
      ],
      boxConnectionList: ["right"],
    },
    {
      id: "box3",
      type: "content",
      title: "Box 3",
      desktopDisplayOrder: 3,
      estimatedTime: 10,
      hideEndHelper: true,
      keypoint: {
        threshold: 95,
        description: "Et si on ajoutait un peu <b>de mise en forme</b> et de <i>décoration</i>",
      },
      activityList: [
        {
          title: "Tracking de base et marque page",
          id: "box3-activity",
          background: {
            type: "image",
            url: asset("/assets/content/backgrounds/quiz-background.jpg"),
          },
          type: "multimedia1",
          isNeededForCompletion: true,
          tracking: {
            completeCondition: "open",
          },
          media: { type: "image", url: "https://dummyimage.com/550x550/000/fff" },
          text: "<div class='free-text-block'><p>Au fur et à mesure que vous consultez les cases de votre Digibox, son parcours se déverrouille. <p>En fermant cette case, une coche verte apparaitra pour signaler que vous avez suivi le contenu de celle-ci.</p><p>Nous allons maintenant vérifier les informations remontées par votre LMS pour le suivi de vos apprenants : retournez sur la page du questionnaire que vous avez laissée ouverte.</p></div>",
        },
      ],
      boxConnectionList: ["down"],
    },
    {
      id: "box6",
      type: "content",
      title: "Box 6",
      desktopDisplayOrder: 4,
      estimatedTime: 10,
      keypoint: {
        threshold: 95,
        description: "Chaque box réprésente une économie potentielle de <b>XX</b> minutes",
      },
      activityList: [
        {
          title: "Tracking de base et marque page",
          id: "box6-activity",
          background: {
            type: "image",
            url: asset("/assets/content/backgrounds/quiz-background.jpg"),
          },
          type: "multimedia1",
          isNeededForCompletion: true,
          tracking: {
            completeCondition: "open",
          },
          media: { type: "image", url: "https://dummyimage.com/550x550/000/fff" },
          text: "<div class='free-text-block'><p>Au fur et à mesure que vous consultez les cases de votre Digibox, son parcours se déverrouille. <p>En fermant cette case, une coche verte apparaitra pour signaler que vous avez suivi le contenu de celle-ci.</p><p>Nous allons maintenant vérifier les informations remontées par votre LMS pour le suivi de vos apprenants : retournez sur la page du questionnaire que vous avez laissée ouverte.</p></div>",
        },
      ],
      boxConnectionList: ["right", "down"],
    },
    {
      id: "box5",
      type: "content",
      title: "box 5",
      objective: {
        successOnSucessOfInteractionList: ["quiz-1"],
      },
      desktopDisplayOrder: 5,
      boxConnectionList: ["right"],
      estimatedTime: 10,
      keypoint: {
        threshold: 95,
        description: "Et si on ajoutait un peu <b>de mise en forme</b> et de <i>décoration</i>",
      },
      activityList: [
        {
          title: "Principes et fonctionnement de la digibox ",
          id: "box5-activity",
          type: "multimedia1",
          background: {
            type: "image",
            url: asset("/assets/content/backgrounds/quiz-background.jpg"),
          },
          isNeededForCompletion: true,
          tracking: {
            completeCondition: "open",
          },
          media: { type: "image", url: "https://dummyimage.com/550x550/000/fff" },
          text: "<div class='free-text-block'><p>La Digibox est un parcours pédagogique à suivre, représenté par un petit trait et des cases de contenu pédagogique.<br> Chaque case doit être consultée pour être débloquée et permettre d'accéder à la suivante.<br><br> En fermant cette case, une coche verte apparaitra pour signaler que vous avez suivi le contenu de celle-ci.</p><p>Nous avons maintenant quelques questions à vous poser : gardez bien cette page ouverte et cliquez sur ce <a href='https://docs.google.com/forms/d/e/1FAIpQLSfuoVxCwqPXWA7OLzTQNbZ7XlBP9hMAfz4T9etNs9Ux0SKl6Q/viewform' target='_blank'>lien</a>.<br><br> <b>A tout de suite !</b></p></div>",
        },
      ],
    },
    {
      id: "box4",
      type: "content",
      title: "Box 4",
      desktopDisplayOrder: 6,
      estimatedTime: 10,
      keypoint: {
        threshold: 95,
        description: "Et si on ajoutait un peu <b>de mise en forme</b> et de <i>décoration</i>",
      },
      objective: {
        successOnSucessOfInteractionList: ["quiz-2"],
      },
      activityList: [
        {
          title: "Principes et fonctionnement de la digibox ",
          id: "box4-activity",
          type: "multimedia1",
          background: {
            type: "image",
            url: asset("/assets/content/backgrounds/quiz-background.jpg"),
          },
          isNeededForCompletion: true,
          tracking: {
            completeCondition: "open",
          },
          media: { type: "image", url: "https://dummyimage.com/550x550/000/fff" },
          text: "<div class='free-text-block'><p>La Digibox est un parcours pédagogique à suivre, représenté par un petit trait et des cases de contenu pédagogique.<br> Chaque case doit être consultée pour être débloquée et permettre d'accéder à la suivante.<br><br> En fermant cette case, une coche verte apparaitra pour signaler que vous avez suivi le contenu de celle-ci.</p><p>Nous avons maintenant quelques questions à vous poser : gardez bien cette page ouverte et cliquez sur ce <a href='https://docs.google.com/forms/d/e/1FAIpQLSfuoVxCwqPXWA7OLzTQNbZ7XlBP9hMAfz4T9etNs9Ux0SKl6Q/viewform' target='_blank'>lien</a>.<br><br> <b>A tout de suite !</b></p></div>",
        },
      ],
    },
    {
      id: "box7",
      title: "Box 7",
      type: "menu",
      objective: {
        successOnSucessOfInteractionList: ["box7-activity-1", "box7-activity-2"],
      },
      boxList: [
        {
          id: "box7a",
          type: "content",
          title: "Box 7a",
          desktopDisplayOrder: 7,
          estimatedTime: 10,
          keypoint: {
            threshold: 95,
            description: "Et si on ajoutait un peu <b>de mise en forme</b> et de <i>décoration</i>",
          },
          activityList: [
            {
              title: "Tracking de base et marque page",
              id: "box7-activity-1",
              background: {
                type: "image",
                url: asset("/assets/content/backgrounds/quiz-background.jpg"),
              },
              type: "multimedia1",
              isNeededForCompletion: true,
              tracking: {
                completeCondition: "open",
              },
              media: { type: "image", url: "https://dummyimage.com/550x550/000/fff" },
              text: "<div class='free-text-block'><p>Au fur et à mesure que vous consultez les cases de votre Digibox, son parcours se déverrouille. <p>En fermant cette case, une coche verte apparaitra pour signaler que vous avez suivi le contenu de celle-ci.</p><p>Nous allons maintenant vérifier les informations remontées par votre LMS pour le suivi de vos apprenants : retournez sur la page du questionnaire que vous avez laissée ouverte.</p></div>",
            },
          ],
          boxConnectionList: ["right"],
        },
        {
          id: "box7b",
          type: "content",
          title: "Box 7b",
          desktopDisplayOrder: 7,
          estimatedTime: 10,
          keypoint: {
            threshold: 95,
            description: "Et si on ajoutait un peu <b>de mise en forme</b> et de <i>décoration</i>",
          },
          activityList: [
            {
              title: "Tracking de base et marque page",
              id: "box7-activity-2",
              background: {
                type: "image",
                url: asset("/assets/content/backgrounds/quiz-background.jpg"),
              },
              type: "multimedia1",
              isNeededForCompletion: true,
              tracking: {
                completeCondition: "open",
              },
              media: { type: "image", url: "https://dummyimage.com/550x550/000/fff" },
              text: "<div class='free-text-block'><p>Au fur et à mesure que vous consultez les cases de votre Digibox, son parcours se déverrouille. <p>En fermant cette case, une coche verte apparaitra pour signaler que vous avez suivi le contenu de celle-ci.</p><p>Nous allons maintenant vérifier les informations remontées par votre LMS pour le suivi de vos apprenants : retournez sur la page du questionnaire que vous avez laissée ouverte.</p></div>",
            },
          ],
          boxConnectionList: ["right"],
        },
      ],
      desktopDisplayOrder: 7,
      boxConnectionList: ["right"],
    },
    {
      id: "box8",
      desktopDisplayOrder: 8,
      title: "box8h",
      type: "menu",
      objectiveText: "objectif box 8h",
      boxList: [
        {
          id: "subBoxH-1",
          title: "sub H 1",
          desktopDisplayOrder: 0,
          type: "content",
          background: "#acab",
          estimatedTime: 0,
          activityList: [
            {
              id: "activity_subBoxH-1",
              type: "image",
              image: {
                type: "image",
                url: "https://dummyimage.com/1280x720/000/fff",
                mobileUrl: asset("/assets/content/boxH/subBoxH-1/"),
              },
            },
          ],
        },
        {
          id: "subBoxH-2",
          title: "sub H 2",
          desktopDisplayOrder: 0,
          type: "content",
          background: "#acab",
          estimatedTime: 0,
          activityList: [
            {
              id: "activity_subBoxH-2",
              type: "image",
              image: {
                type: "image",
                url: "https://dummyimage.com/1280x720/000/fff",
                mobileUrl: asset("/assets/content/boxH/subBoxH-2/"),
              },
            },
          ],
        },
        {
          id: "subBoxH-3",
          title: "sub H 3",
          desktopDisplayOrder: 0,
          type: "content",
          background: "#acab",
          estimatedTime: 0,
          activityList: [
            {
              id: "activity_subBoxH-3",
              type: "image",
              image: {
                type: "image",
                url: "https://dummyimage.com/1280x720/000/fff",
                mobileUrl: asset("/assets/content/boxH/subBoxH-3/"),
              },
            },
          ],
        },
        {
          id: "subBoxH-4",
          title: "sub H 4",
          desktopDisplayOrder: 0,
          type: "content",
          background: "#acab",
          estimatedTime: 0,
          activityList: [
            {
              id: "activity_subBoxH-4",
              type: "image",
              image: {
                type: "image",
                url: "https://dummyimage.com/1280x720/000/fff",
                mobileUrl: asset("/assets/content/boxH/subBoxH-4/"),
              },
            },
          ],
        },
        {
          id: "subBoxH-5",
          title: "sub H 5",
          desktopDisplayOrder: 0,
          type: "content",
          background: "#acab",
          estimatedTime: 0,
          activityList: [
            {
              id: "activity_subBoxH-5",
              type: "image",
              image: {
                type: "image",
                url: "https://dummyimage.com/1280x720/000/fff",
                mobileUrl: asset("/assets/content/boxH/subBoxH-5/"),
              },
            },
          ],
        },
        {
          id: "subBoxH-6",
          title: "sub H 6",
          desktopDisplayOrder: 0,
          type: "content",
          background: "#acab",
          estimatedTime: 0,
          activityList: [
            {
              id: "activity_subBoxH-6",
              type: "image",
              image: {
                type: "image",
                url: "https://dummyimage.com/1280x720/000/fff",
                mobileUrl: asset("/assets/content/boxH/subBoxH-6/"),
              },
            },
          ],
        },
      ],
    },
    {
      id: "box9",
      type: "content",
      estimatedTime: 0,
      desktopDisplayOrder: 9,
      title: "box9i",
      activityList: [
        {
          type: "knowledgeMapping",
          relatedActivityId: "skillTester_activity",
          id: "kanowledgeMapping",
          title: "Cartographie",
          background: {
            type: "image",
            url: asset("/assets/content/backgrounds/quiz-background.jpg"),
          },
        },
      ],
    },
  ],
};

export default content;
